import React, { useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FiMapPin, FiCalendar, FiTag } from 'react-icons/fi';
import { BiWallet } from 'react-icons/bi';
import { DateRangePicker, InputGroup, CheckPicker } from 'rsuite';
import { FormInput,
  Button,
  Slider,
} from 'shards-react';
import { MdSearch } from 'react-icons/md';
import { placesTypes } from '../../helpers/constants';

import { setFilters, setTriggeredFilter, setSearchTerm } from '../../features/explore/exploreSlice';

import Filter from './Filter';
import PlacesAutocomplete from '../ExplorePlacesAutocomplete';

const Filters = ({ innerRef }) => {
  const dispatch = useDispatch();
  const { beforeToday } = DateRangePicker;

  const tags = useSelector((state) => state.user.tags);
  const isSticky = useSelector((state) => state.explore.stickyFilters);
  const triggeredFilter = useSelector((state) => state.explore.triggeredFilter);
  const selectedTags = useSelector((state) => state.explore.search.filters.tagIds);
  const selectedLocation = useSelector((state) => state.explore.search.filters.location);
  const minPrice = useSelector((state) => state.explore.search.filters.minPrice);
  const maxPrice = useSelector((state) => state.explore.search.filters.maxPrice);
  const [filtersHeight, setFiltersHeight] = useState(0);

  const [searchInputValue, setSearchInputValue] = useState('');
  
  useEffect(() => {
    if (!isSticky) {
      setFiltersHeight(innerRef?.current?.getBoundingClientRect().height || 0);
    }
  }, [isSticky, innerRef]);

  return (
    <div className="filtersContainer" ref={innerRef}>
      <div className="wrapper">
        <div className="innerWrapper">
          <Filter
            isSticky={isSticky}
            selection={selectedLocation?.label}
            placeholderIcon={<FiMapPin size={20} />}
            placeholderText="Location"
            content={(
              <PlacesAutocomplete 
                initialValue={selectedLocation}
                types={[placesTypes.CITIES]}
                placeholder='search for location...'
                onBlur={() => {
                  dispatch(setTriggeredFilter(''));
                }}
                onChange={(value) => {
                  dispatch(
                    setFilters({
                      filter: 'location',
                      value,
                    }),
                  );
                  // console.log(value);
                  // console.log(selectedLocation);
                  if ((value && selectedLocation && value.value !== selectedLocation.value) || (value && !selectedLocation)) {
                    dispatch(setTriggeredFilter('')); 
                  } 
                }}
                onClear={() => {
                  dispatch(
                    setFilters({
                      filter: 'location',
                      value: null,
                    }),
                  );
                  dispatch(setTriggeredFilter(''));
                }}
              />
            )}
            isTriggered={triggeredFilter === 'location'}
            onTrigger={() => { dispatch(setTriggeredFilter('location')); }}
            onDismiss={() => { dispatch(setTriggeredFilter('')); }}
            isHidden={isSticky && triggeredFilter && triggeredFilter !== 'location'}
          />
          {tags && (
            <Filter
              isSticky={isSticky}
              placeholderIcon={<FiTag size={20} />}
              placeholderText="Interests"
              selection={selectedTags?.map((tagId) => tags[tagId].name).join(', ') || ''}
              content={(
                <CheckPicker
                  virtualized={false}
                  cleanable={false}
                  searchable={true}
                  data={Object.keys(tags).map((tagId) => ({ value: tagId, label: tags[tagId].name }))}
                  style={{ width: '100%' }}
                  placeholder="Select your interests"
                  value={selectedTags}
                  placement="bottom"
                  onExit={() => {
                    dispatch(setTriggeredFilter(''));
                  }}
                  onChange={(value) => {
                    // console.log(value);
                    dispatch(
                      setFilters({
                        filter: 'tagIds',
                        value,
                      }),
                    );
                  }}
                  renderExtraFooter={() => selectedTags.length ? (
                    <Button
                      theme="dark"
                      style={{
                        fontSize: 12,
                        fontWeight: 700,
                        width: 'calc(100% + 32px)',
                        marginLeft: 10,
                        textAlign: 'center'
                      }}
                      onClick={() => {
                        dispatch(
                          setFilters({
                            filter: 'tagIds',
                            value: [],
                          }),
                        );
                      }}
                    >
                      clear selection
                    </Button>
                  ): null}
                />
              )}
              isTriggered={triggeredFilter === 'tags'}
              onTrigger={() => { dispatch(setTriggeredFilter('tags')); }}
              onDismiss={() => { dispatch(setTriggeredFilter('')); }}
              isHidden={isSticky && triggeredFilter && triggeredFilter !== 'tags'}
            />
          )}
          <div className='searchContainer'>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                dispatch(setSearchTerm(searchInputValue));
              }}
              className='search'
            >
              <InputGroup size='sm' seamless>
                <Button type='prepend'>
                  <MdSearch
                    size={20}
                    style={{ marginRight: 5 }}
                  />
                  Search
                </Button>
                <FormInput
                  placeholder='e.g. experience, destination...'
                  value={searchInputValue}
                  onChange={(e) => setSearchInputValue(e.target.value)}
                  onClick={() => { dispatch(setTriggeredFilter('')); }}
                />
              </InputGroup>
            </form>
          </div>
        </div>
      </div>
      <style jsx>
        {`
            .innerWrapper {
              display: flex;
              flex-wrap: ${isSticky ? 'nowrap' : 'wrap'};
              width: ${isSticky ? 'auto' : '100%'};
              justify-content: center;
              align-items: flex-start;
              min-width: min-content;
            }
            .wrapper {
              position: ${isSticky ? 'fixed' : 'relative'};
              top: ${isSticky ? '67px' : '0'};
              transition: ${isSticky ? 'top .3s ease-in-out .2s' : 'none'};
              z-index: ${isSticky ? 3 : 0};
              width: 100%;
              overflow-x: ${triggeredFilter ? 'hidden' : 'scroll'};
              box-shadow: ${isSticky ? `0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.1),
                0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.1),
                0 0.25rem 0.53125rem rgba(90, 97, 105, 0.12),
                0 0.125rem 0.1875rem rgba(90, 97, 105, 0.1)` : 'none'};
              scrollbar-width: none; /* Firefox */
              -ms-overflow-style: none;  /* Internet Explorer 10+ */
            }
            .wrapper::-webkit-scrollbar {
              width: 0;
              height: 0;
            }
            .filtersContainer {
              position: absolute;
              z-index: 9;
              top: 100%;
              height: ${isSticky ? `${filtersHeight}px` : 'auto'};
            }
            @media (min-width: 768px) {
              .innerWrapper {
                flex-direction: row;
              }
            }
        `}
      </style>
    </div>
  );
};

export default Filters;
