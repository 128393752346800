import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMoreExperiences, initCalendar } from './upcomingEffects';
import { getFormattedExperiences } from './upcomingSlice';
import history from '../../utils/history';
import Calendar from './Calendar';
import { Container, Button } from 'shards-react';
import { FiEye, FiPlusCircle } from 'react-icons/fi';

const UpcomingPage = () => {

  const dispatch = useDispatch();  
  const experiences = useSelector(getFormattedExperiences);
  const display = useSelector((state) => state.upcoming.display);
  const isFetching = useSelector((state) => state.upcoming.fetching !== 'idle');
  const isDone = useSelector((state) => state.upcoming.fetching === 'done');
  const isInitialized = useSelector((state) => state.upcoming.initializing === 'done');
  useEffect(() => {
    dispatch(initCalendar());
    // dispatch(fetchExperiences());
  }, []);

  return (
    <div id="upcoming-page">
      <Container className="upcoming-container">
        <div className="title">Upcoming experiences on Bondai</div>
        {isInitialized ? (
          <>
            <Calendar {...display} data={experiences}/>
            <Button
              theme="dark"
              className="loadMore"
              disabled={isFetching}
              onClick={() => { dispatch(fetchMoreExperiences()) }}
            >
              {isFetching ? null : (<FiEye style={{ width: 20, height: 20, marginRight: 5 }}/>)} 
              {isFetching ? 'Fetching more data...' : 'View 8 more weeks'}
            </Button>
          </>
        ) : null}
      </Container>

      {/* {availableDates.map(date => (
        <div>
          <h2>{date.title}</h2>
          {date.data.map((experience) => (
            <>
              <p><a href={`https://bondai.io/explore/${experience.shortId}`}>{experience.title}</a> // available spots {experience.spacesLeft} / {experience.capacity}</p>
            </>
          ))}
        </div>
      ))} */}
    </div>
  );
};

export default UpcomingPage;
