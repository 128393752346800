import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from '../../utils/history';
import Header from '../../Header';

import { Container, Row } from 'shards-react';
import DashboardPage from './DashboardPage';
import HandleActivityPage from './HandleActivityPage';
import ActivitySchedulePage from './ActivitySchedulePage';
import ManageActivityPage from './ManageActivityPage';
import ProfilePage from './ProfilePage';
import AdminPage from '../superadmin/AdminPage';
import AddMultipleSchedulesPage from './AddMultipleSchedulesPage';

const EditActivityPage = (props) => <HandleActivityPage {...props} editing />;

const DashboardLayout = () => (
  <Container style={{ flex: 1, paddingTop: 100, paddingBottom: 60 }}>
    <Router history={history}>
      <Switch>
        <Route
          path='/dashboard/add-experience'
          component={HandleActivityPage}
        />
        <Route path='/dashboard/edit/:id' component={EditActivityPage} />
        <Route
          path='/dashboard/schedule/:id'
          component={ActivitySchedulePage}
        />
        <Route
          path='/dashboard/add-availability/:id'
          component={AddMultipleSchedulesPage}
        />
        <Route path='/dashboard/manage/:id' component={ManageActivityPage} />
        <Route path='/dashboard/admin' component={AdminPage} />
        <Route path='/dashboard/profile' component={ProfilePage} />
        <Route path='/dashboard' component={DashboardPage} />
      </Switch>
    </Router>
  </Container>
);

export default DashboardLayout;
