import React from 'react';
import history from '../../utils/history';
import { FiMapPin, FiMoon, FiSun } from 'react-icons/fi';
import { FaStar } from 'react-icons/fa';

const ActivityCard = ({
  shortId,
  slug,
  id,
  avatar,
  isFeatured,
  title,
  location = null,
  price,
  duration,
  ratingValue,
  ratingCount,
  date = null,
}) => (
  <div
    onClick={() => {
      history.push(slug ? `/experience/${slug}` : `/explore/${shortId}`);
    }}
    className={`activityCard ${isFeatured ? 'activityCard--featured' : ''}`}
  >
    <div className='activityCard__photoWrapper'>
      <div
        className='activityCard__photo'
        style={{
          backgroundImage: `url(${avatar}${isFeatured ? '720' : '480'})`,
        }}
      >
        <div className='activityCard__photoSpacer'></div>
      </div>
      <div className='activityCard__duration'>
        {duration > 1 ? (
          <FiMoon className='activityCard__durationIcon' />
        ) : (
          <FiSun className='activityCard__durationIcon' />
        )}
        {duration > 1 ? 'multi day' : 'day trip'}
      </div>
    </div>
    {ratingCount || date ? (
      <div className='activityCard__featuredDetails'>
        {date ? (
          <div className='activityCard__date'>
            {date}
          </div>
        ) : null}
        {ratingCount ? (
          <div className='activityCard__rating'>
            <FaStar className='activityCard__ratingIcon' />
            {ratingValue} <span>({ratingCount})</span>
          </div>
        ) : null}
      </div>
    ) : null}
    <div className='activityCard__details'>
      {price ? (
        <div className='activityCard__pricing'>
          <span>starting</span> from
          <div className='activityCard__pricingAmount'>
            <span>{price}</span> / adult
          </div>
        </div>
      ) : null}
      <div className='activityCard__title'>{title}</div>
      <div className='activityCard__location'>
        <FiMapPin className='activityCard__locationIcon' />
        {location
          ? `${location.localityName ? `${location.localityName}, ` : ''} ${
              location.regionName || location.countryDestinationName
            }`
          : 'Saudi Arabia'}
      </div>
    </div>
  </div>
);

export default ActivityCard;
