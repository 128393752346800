import React from 'react';
import { Col } from 'shards-react';
import ReactStars from "react-rating-stars-component";
import { FaUserCircle } from 'react-icons/fa';

const ReviewItem = ({ rating, avatarUrl, name, submissionDate, feedback, experienceDate }) => (
  <Col xs={12} sm={6} className='activity__review'>
    {avatarUrl ? (
      <div className='activity__reviewAvatar' style={{backgroundImage: `url(${avatarUrl})`}}/>
    ) : (
      <FaUserCircle className='activity__reviewAvatar'/>
    )}
    <div className='activity__reviewDetails'>
      <div className='activity__reviewRating'>
        <ReactStars
          classNames="reviewRating"
          count={5}
          value={rating}
          edit={false}
          size={20}
          activeColor="#ffca28"
          color="#e5e5e5"
        />
      </div>
      <div className='activity__reviewName'>
        {name}
      </div>
      <div className='activity__reviewDate'>
        {submissionDate}
      </div>
      <div className='activity__reviewExpDate'>
        <span>Date of experience: </span> {experienceDate}
      </div>
      <div className='activity__reviewPublic'>
        {feedback}
      </div>
    </div>
  </Col>
);

export default ReviewItem;