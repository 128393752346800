import axios from 'axios';

const API = axios.create({
  baseURL: process.env.REACT_APP_BLOG_API,
  responseType: 'json',
});

export const fetchArticles = () =>
  API.get('/articles', {
    params: {
      populate: ['categories', 'author', 'slider.images'].join(),
      sort: 'createdAt:desc',
      'pagination[pageSize]': 4,
      locale:'all',
    },
  });
