import React from 'react';
import Loader from 'react-loader-spinner';

const PageLoader = (props) => (
  <div
    style={{
      display: 'flex',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'black',
      fontWeight: 600,
    }}
  >
    <Loader type='Rings' color='#000000' height={60} width={60} />
    <span>{props.label}</span>
  </div>
);

export default PageLoader;
