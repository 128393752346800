import React from 'react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import BookingsAPI from '../../api/BookingsAPI';
import { register, updateDetails } from '../auth/userEffects';
import { notify } from '../notifications/notificationsEffects';
import { showModal } from '../notifications/notificationsSlice';
import history from '../../utils/history';

export const fetchBookings = createAsyncThunk(
  'user/fetchBookingsStatus',
  async () => {
    const { data } = await BookingsAPI.fetchBookings();
    return data.value;
  },
);

export const fetchBookingById = createAsyncThunk(
  'user/fetchBookingStatus',
  async (id) => {
    const { data } = await BookingsAPI.fetchBookingById(id);
    return data.value;
  },
);

export const cancelBookingById = createAsyncThunk(
  'user/cancelBookingStatus',
  async (id, { dispatch }) => {
    try {
      const { data } = await BookingsAPI.cancelBookingById(id);
      dispatch(
        notify({
          message: `Your booking has been successfuly cancelled.`,
          type: 'success',
        }),
      );
      return data.value;
    } catch (err) {
      dispatch(
        notify({
          message: `Something went wrong, the booking could not be cancelled. ${err.response.data.notificationMessage}`,
          type: 'danger',
        }),
      );
      throw err;
    }
  },
);

export const handleCheckout = createAsyncThunk(
  'user/handleCheckoutStatus',
  async (checkoutData, { dispatch }) => {
    try {
      let body;
      if (checkoutData.user.billing.Password) {
        body = {
          email: checkoutData.user.billing.Email,
          password: checkoutData.user.billing.Password,
          firstName: checkoutData.user.billing.FirstName,
          lastName: checkoutData.user.billing.LastName,
          phoneNumber: checkoutData.user.billing.PhoneNumber,
          gender: checkoutData.user.traveler.Gender,
          dateOfBirth: checkoutData.user.traveler.DateOfBirth,
          idType: checkoutData.user.traveler.IdType,
          idNumber: checkoutData.user.traveler.IdNumber,
        };
        await dispatch(register(body));
      } else {
        body = new FormData();
        Object.keys(checkoutData.user.billing).forEach((key) => {
          body.append(key, checkoutData.user.billing[key]);
        });
        if (checkoutData.user.traveler) {
          Object.keys(checkoutData.user.traveler).forEach((key) => {
            body.append(key, checkoutData.user.traveler[key]);
          });
        }
        await dispatch(updateDetails(body));
      }
      const response = await BookingsAPI.bookActivity({
        id: checkoutData.activity,
        scheduleId: checkoutData.schedule,
        body: checkoutData.order,
      });
      history.push(`/payment-gateway/${response.data.value.activityBookingDetailsDto.id}?activityId=${checkoutData.activity}`);
      /* dispatch(
        showModal({
          message: (
            <div>
              {`You have successfully booked your seats for ${response.data.value.bookedActivityDto.activityTitle} by ${response.data.value.bookedActivityDto.tourProvider.tourProviderName}. The experience provider will confirm your booking soon and you will receive a payment link on your email.`}
            </div>
          ),
          dismissLabel: 'Keep exploring',
        }),
      );
      history.push(`/explore`); */
      return response.data.value;
    } catch (err) {
      dispatch(
        notify({
          message: `Something went wrong, the booking could not be submitted. ${err.response.data.notificationMessage}`,
          type: 'danger',
        }),
      );
      throw err;
    }
    // return data.value;
  },
);
