import React from 'react';
import { FormCheckbox } from 'shards-react';

const CheckboxGroup = (props) => {
  return (
    <div className='checkboxGroup'>
      {props.label && <label style={{ display: 'block' }}>{props.label}</label>}
      {props.options
        ? props.options.map((option, index) => (
            <FormCheckbox
              inline
              name={`${props.name}[${index}]`}
              value={option.id}
              innerRef={props.innerRef}
            >
              {option.label}
            </FormCheckbox>
          ))
        : null}
    </div>
  );
};

export default CheckboxGroup;
