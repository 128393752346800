import React from 'react';
import { Controller } from 'react-hook-form';
import ReactStars from "react-rating-stars-component";

const ControlledRate = (props) => (
  <Controller as={<RateComponent/>} {...props} />
);

const RateComponent = (props) => (
  <div className='rateComponent'>
    <ReactStars 
      value={props.defaultValue}
      {...props}
      classNames='reviewRating big'
      count={5}
      size={34}
      activeColor="#ffca28"
      color="#e5e5e5"
    />
    {props.errors ? <span className='error'>{props.errors.message}</span> : null}
  </div>
);

export default ControlledRate;
