import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import * as effects from './blogEffects';

const blogAdapter = createEntityAdapter();

const { reducer } = createSlice({
  name: 'blog',
  initialState: blogAdapter.getInitialState({
    featuredArticles: [],
    loading: {
      featuredArticle: 'idle',
    },
  }),
  extraReducers: {
    [effects.fetchFeaturedArticles.pending]: (state, action) => {
      if (state.loading.featuredArticle === 'idle') {
        state.loading.featuredArticle = 'pending';
      }
    },
    [effects.fetchFeaturedArticles.fulfilled]: (state, action) => {
      if (state.loading.featuredArticle === 'pending') {
        state.featuredArticles = action.payload.data;
        state.loading.featuredArticle = 'idle';
      }
    },
    [effects.fetchFeaturedArticles.rejected]: (state) => {
      state.loading.featuredArticle = 'failed';
    },
  },
});

export default reducer;
