import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Row, Col, Button, Slider, Modal, ModalBody } from 'shards-react';
import { Input, Stepper, Textarea } from '../../components/ui-kit';
import { FiX } from 'react-icons/fi';
import moment from 'moment';

import { emailRegex } from '../../helpers/validations';
import {
  login,
  checkIfAccountExists,
  handleAvailabilityInquiry,
} from '../auth/userEffects';
import { setExistingAccountStatus } from '../auth/userSlice';

import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import { useDispatch, useSelector } from 'react-redux';

import MultipleDatePicker from '../../components/MultipleDatePicker';

const InquiryModal = ({
  isOpen,
  toggleVisibility,
  setVisibility,
  activityId,
  activityTitle,
  tourOperator,
  selectedDates = [],
  onSelectionChanged,
}) => {
  const dispatch = useDispatch();

  const isExistingAccount = useSelector(
    (state) => state.user.isExistingAccount,
  );

  const userDetails = useSelector((state) => state.user.details);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    errors,
    getValues,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      emailAddress: '',
      password: '',
      groupSize: 1,
      preferredDates: [],
      other: '',
    },
  });

  const isLogin = isExistingAccount && !userDetails.role;

  const onSubmit = (data) => {
    if (isExistingAccount && !userDetails.role) {
      dispatch(login({
        email: data.emailAddress,
        password: data.password,
      }));
    } else {
      dispatch(
        handleAvailabilityInquiry({
          ...data,
          preferredDates: data.preferredDates.map((date) =>
            moment.utc(date, 'DD/MM/YYYY').format(),
          ),
          activityId,
        }),
      );
    }
  };

  const isSubmitting = useSelector(
    (state) => state.user.loading.handleAvailabilityInquiry === 'pending',
  );
  
  const isSubmittedSuccessfully = useSelector(
    (state) => state.user.loading.handleAvailabilityInquiry === 'done',
    );
  
  const isLoggingIn = useSelector(
    (state) => state.user.loading.login === 'pending',
  );


  useEffect(() => {
    if (userDetails.role && isOpen) {
      setTimeout(() => {
        setValue([
          { phoneNumber: userDetails.phoneNumber },
          { emailAddress: userDetails.emailAddress },
          { firstName: userDetails.firstName },
          { lastName: userDetails.lastName },
        ]);
      }, 1000);
      dispatch(setExistingAccountStatus(true));
    }
  }, [userDetails, isOpen]);

  useEffect(() => {
    if (isSubmittedSuccessfully) {
      reset();
      setVisibility(false);
      onSelectionChanged([]);
    }
  }, [isSubmittedSuccessfully]);

  useEffect(() => {
    setTimeout(() => {
      setValue([{ preferredDates: selectedDates }]);
    }, 3000);
  }, [selectedDates]);

/*   useEffect(() => {
    if (isLogin) {
      clearErrors();
    }
  }, [isLogin, clearErrors]); */

  return (
    <Modal open={isOpen} toggle={toggleVisibility} className='inquiryModal'>
      <ModalBody className='inquiry-form'>
        <FiX
          className='inquiryModal__close'
          onClick={() => {
            setVisibility(false);
          }}
        />
        <Row>
          <Col xs={12}>
            <span className='inquiryModal__subtitle'>
              Availability inquiry for
            </span>
            <span className='inquiryModal__title'>{activityTitle}</span>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <div className='inquiryModal__description'>
              We will be in touch with you shortly via
              email & whatsapp to help customizing your experience.
              {!isExistingAccount && !userDetails.role ? (
                <div className='inquiryModal__description inquiryModal__account'>
                  Already a Bondai user? Login{' '}
                  <span
                    onClick={() => dispatch(setExistingAccountStatus(true))}
                  >
                    here
                  </span>{' '}
                  first.
                </div>
              ) : !userDetails.role ? (
                <div className='inquiryModal__description inquiryModal__account'>
                  Please login below first. Click{' '}
                  <span
                    onClick={() => dispatch(setExistingAccountStatus(false))}
                  >
                    here
                  </span>{' '}
                  if you want to create a new account.
                </div>
              ) : null}
            </div>
          </Col>
          <Col xs={12} style={{ marginTop: 10 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col xs={12}>
                  <Row form>
                    <Col xs={12} md={6}>
                      <Input
                        label='Email address'
                        autoComplete='new-email'
                        name='emailAddress'
                        placeholder='Email address*'
                        errors={{
                          ...errors.emailAddress,
                          message: errors.emailAddress
                            ? errors.emailAddress?.type === 'required'
                            ? 'This field is required'
                              : 'Invalid email address'
                              : '',
                        }}
                        innerRef={register({
                          required: true,
                          pattern: emailRegex,
                        })}
                        onBlur={(e) => {
                          if (!isLogin && e.target.value) dispatch(checkIfAccountExists(e.target.value));
                        }}
                        readOnly={!!userDetails.role}
                        />
                    </Col>
                    {!userDetails.role ? (
                      <Col xs={12} md={6}>
                        <Input
                          autoComplete='new-password'
                          label='Password'
                          placeholder='****************'
                          name='password'
                          type='password'
                          errors={{
                            ...errors.password,
                            message: errors.password
                              ? errors.password?.type === 'required'
                                ? 'This field is required'
                                : 'Min. length is 6 characters'
                              : '',
                          }}
                          innerRef={register({
                            required: 'This field is required',
                            minLength: 6,
                          })}
                        />
                      </Col>
                    ) : null}
                  {!isLogin ? (<>
                    <Col xs={12} md={userDetails.role ? 6 : 4}>
                      <Input
                        label='Phone number'
                        name='phoneNumber'
                        autoComplete='on'
                        placeholder='Phone number*'
                        errors={errors.phoneNumber}
                        innerRef={register({
                          required: 'This field is required',
                        })}
                      />
                    </Col>
                    <Col xs={12} md={userDetails.role ? 6 : 4}>
                      <Input
                        label='First name'
                        name='firstName'
                        autoComplete='none'
                        placeholder='First name*'
                        errors={errors.firstName}
                        innerRef={register({
                          required: 'This field is required',
                        })}
                        readOnly={!!userDetails.role}
                        />
                    </Col>
                      <Col xs={12} md={userDetails.role ? 6 : 4}>
                        <Input
                          label='Last name'
                          name='lastName'
                          autoComplete='none'
                          placeholder='Last name*'
                          errors={errors.lastName}
                          innerRef={register({
                            required: 'This field is required',
                          })}
                          readOnly={!!userDetails.role}
                        />
                      </Col>
                    </>
                  ) : null }
                  </Row>
                </Col>
                {!isLogin ? (
                  <Col>
                    <Row form>
                      <Col xs={12} md={8}>
                        <MultipleDatePicker
                          name='preferredDates'
                          placeholder='please select your preferred dates...'
                          label='Preferred dates to start your trip'
                          control={control}
                          errors={errors.preferredDates}
                          rules={{
                            validate: () =>
                              getValues('preferredDates').length
                                ? undefined
                                : 'Please select at least one date',
                          }}
                          dateOnly
                        />
                      </Col>
                      <Col xs={12} md={4} className='stepper'>
                        <label>Group size</label>
                        <Stepper
                          name='groupSize'
                          min={1}
                          controls={{ register, setValue, watch }}
                        />
                      </Col>
                    </Row>
                    <Row form>
                      <Col xs={12}>
                        <Textarea
                          name='other'
                          placeholder='e.g. dietary requirements, travelling with children...'
                          label='Any special requests?'
                          innerRef={register}
                        />
                      </Col>
                    </Row>
                </Col>
                ) : null}
              </Row>
              <Row form>
                {!isLogin ? (
                  <Button
                    className='customButton--red inquiryModal__button'
                    disabled={isSubmitting}
                    >
                    {isSubmitting
                      ? 'Sending your inquiry...'
                      : 'Send us your inquiry'}
                  </Button>
                ) : (
                  <Button
                    className='customButton--red inquiryModal__button'
                    disabled={isLoggingIn}
                  >
                    Login
                  </Button>
                )}
              </Row>
            </form>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default InquiryModal;
