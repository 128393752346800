import { supportedLangs } from './constants';
import { lowerCaseFirstLetter } from './functions';
import {
  EditorState,
  convertFromRaw,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from 'draft-js';

export const defaultEditorContent = () =>
  supportedLangs.reduce(
    (acc, lang) => ({
      ...acc,
      [lang.value]: EditorState.createEmpty(),
    }),
    {},
  );

export const editorContentReducer = (content) =>
  JSON.stringify(
    Object.keys(content).reduce(
      (descriptionContent, lang) => ({
        ...descriptionContent,
        [lang]: convertToRaw(content[lang].getCurrentContent()),
      }),
      {},
    ),
  );

export const editorContentMapper = (fields, data) =>
  fields.map((field) => {
    const activityContent = data[lowerCaseFirstLetter(field)];
    let editorContent = defaultEditorContent();

    try {
      const parsedContent = JSON.parse(activityContent);
      editorContent = Object.keys(parsedContent).reduce(
        (acc, lang) => ({
          ...acc,
          [lang]: EditorState.createWithContent(
            convertFromRaw(parsedContent[lang]),
          ),
        }),
        {},
      );
    } catch (err) {
      const blocksFromHTML = convertFromHTML(activityContent);
      editorContent.en = EditorState.createWithContent(
        ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap,
        ),
      );
    } finally {
      return { [field]: editorContent };
    }
  });
