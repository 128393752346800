import React, { useRef } from 'react';
import { RiImageEditFill, RiImageAddFill } from 'react-icons/ri';
import { Controller } from 'react-hook-form';

const ImagePicker = ({ control, name, label }) => {
  const inputRef = useRef(null);
  return (
    <Controller
      name={name}
      control={control}
      as={({ value, onChange }) => (
        <div className='imagePicker'>
          <div className='imagePicker__header'>
            {label && (<label>{label}</label>)}
            {value.preview ?
              <div className='imagePicker__edit' onClick={() => {
                inputRef.current.click()
              }}>
                <RiImageEditFill /> change photo
            </div> : null}
          </div>
          <div className='imagePicker__container'>
            <input
              ref={inputRef}
              type='file'
              accept=".png, .jpg, .jpeg"
              onChange={(e) => {
                if (e.target.files.length) {
                  onChange({
                    preview: URL.createObjectURL(e.target.files[0]),
                    raw: e.target.files[0]
                  });
                }
              }}
            />
            {value.preview ? <img src={value.preview} alt='Provider Profile' /> : (
              <div className='imagePicker__placeholder'>
                <RiImageAddFill />
                Select your profile picture
              </div>
            )}
          </div>
        </div >
      )}
    />
  );
}
export default ImagePicker;

