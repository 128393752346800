import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import moment from "moment";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PageLoader from "../../components/PageLoader";
import SectionLoader from "../../components/SectionLoader";
import {
  fetchActivityById,
  createSchedules,
  updateSchedule,
  fetchBookings,
  deleteSchedule,
} from "./activitiesEffects";
import {
  Row,
  Col,
  Container,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "shards-react";
import { Input, DateTimePicker, CheckboxGroup } from "../../components/ui-kit";
import { FiArrowLeft, FiPlus, FiX } from "react-icons/fi";
import history from "../../utils/history";
import Calendar from "react-calendar";
import BookingsDetailsList from "./BookingsDetailsList";
// import { bookingStatus } from '../../helpers/constants';

const ActivitySchedulePage = ({ match }) => {
  const dispatch = useDispatch();

  const [isScheduleFormVisible, setIsScheduleFormVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  // const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [selectedScheduleId, setSelectedScheduleId] = useState(null);
  const [isEditingSchedule, setIsEditingSchedule] = useState(false);

  const activity = useSelector(
    (state) => state.activities.entities[match.params.id]
  );
  const bookings = useSelector((state) =>
    state.activities.bookings.filter(
      (booking) => booking.bookingStatus.status < 2
    )
  );
  const isFetchingBookings = useSelector(
    (state) => state.activities.loading.bookings === "pending"
  );

  const schedules =
    activity && activity.schedules
      ? activity.schedules.reduce((calendar, schedule) => {
          const scheduleDay = moment
            .parseZone(schedule.startDateTime)
            .local(true)
            .startOf("day")
            .unix();
          return {
            ...calendar,
            [scheduleDay]: [
              ...(calendar[scheduleDay] || []),
              {
                ...schedule,
                startDateTime: moment
                  .parseZone(schedule.startDateTime)
                  .local(true)
                  .format(),
                endDateTime: moment
                  .parseZone(schedule.endDateTime)
                  .local(true)
                  .format(),
              },
            ],
          };
        }, {})
      : {};
  const { register, handleSubmit, control, errors, setValue } = useForm();

  const selectedSchedule = selectedScheduleId
    ? activity.schedules.filter(
        (schedule) => schedule.id === selectedScheduleId
      )[0]
    : null;

  const onSubmit = (data) => {
    if (isEditingSchedule) {
      const adultPrice = parseFloat(data.AdultPrice);
      const childPrice =
        data.ChildPrice && parseFloat(data.ChildPrice) > 0
          ? parseFloat(data.ChildPrice)
          : 0;
      dispatch(
        updateSchedule({
          activityId: activity.id,
          scheduleId: selectedSchedule.id,
          body: {
            availablePaidExtraIds: Object.keys(activity.paidExtras).reduce(
              (ids, id, index) => [
                ...ids,
                ...(data.AvailablePaidExtraIds[index] ? [id] : []),
              ],
              []
            ),
            startDateTime: moment(data.StartDateTime).format(),
            endDateTime: moment(data.EndDateTime).format(),
            capacity: parseInt(data.Capacity) || 0,
            ticketTypeEntities: [
              {
                ticketTypeId: selectedSchedule.ticketTypes[0]?.ticketTypeId,
                priceAmount: adultPrice,
              },
              {
                ticketTypeId: selectedSchedule.ticketTypes[1]?.ticketTypeId,
                priceAmount: childPrice,
              },
            ],
          },
        })
      );
      setIsEditingSchedule(false);
    } else {
      dispatch(
        createSchedules({
          activityId: activity.id,
          schedule: [
            {
              ticketTypeEntities: [
                {
                  ticketTypeId: process.env.REACT_APP_ADULT_TICKET_ID,
                  priceAmount: parseFloat(data.AdultPrice),
                },
                {
                  ticketTypeId: process.env.REACT_APP_CHILD_TICKET_ID,
                  priceAmount:
                    data.ChildPrice && parseFloat(data.ChildPrice) > 0
                      ? parseFloat(data.ChildPrice)
                      : 0,
                },
              ],
              availablePaidExtraIds: Object.keys(activity.paidExtras).reduce(
                (ids, id, index) => [
                  ...ids,
                  ...(data.AvailablePaidExtraIds[index] ? [id] : []),
                ],
                []
              ),
              startDateTime: moment(data.StartDateTime).format(),
              endDateTime: moment(data.EndDateTime).format(),
              capacity: parseInt(data.Capacity) || 0,
            },
          ],
        })
      );
    }
    setIsScheduleFormVisible(false);
  };


  const TileContent = (props) => {
    const allowNewSchedules = moment(props.date).isSameOrAfter(
      moment().startOf("day")
    );
    return props.view !== "month" ? null : (
      <>
        <div style={{ width: "100%", paddingBottom: "100%" }}></div>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {props.existingSchedules ? (
            <div className="tileSchedules">
              {props.existingSchedules.map((schedule) => (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    // setSelectedSchedule(schedule);
                    setSelectedScheduleId(schedule.id);
                  }}
                >
                  {moment.parseZone(schedule.startDateTime).format("H:mm")}
                </button>
              ))}
              {allowNewSchedules ? (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setIsScheduleFormVisible(true);
                  }}
                >
                  add new
                </button>
              ) : null}
            </div>
          ) : allowNewSchedules ? (
            <div
              className="addScheduleBig"
              onClick={() => {
                setIsScheduleFormVisible(true);
              }}
            >
              <FiPlus
                style={{ height: "50%", width: "50%", color: "#ebebeb" }}
              />
            </div>
          ) : (
            <FiX style={{ height: "30%", width: "30%", color: "#ebebeb" }} />
          )}
        </div>
      </>
    );
  };

  useEffect(() => {
    if (isScheduleFormVisible) {
      if (isEditingSchedule && selectedSchedule) {
        setTimeout(() => {
          setValue([
            {
              StartDateTime: moment
                .parseZone(selectedSchedule.startDateTime)
                .local(true)
                .format(),
            },
            {
              EndDateTime: moment
                .parseZone(selectedSchedule.endDateTime)
                .local(true)
                .format(),
            },
            {
              Capacity: selectedSchedule.capacity || 0,
            },
            {
              AdultPrice: selectedSchedule.ticketTypes[0]?.priceAmount,
            },
            {
              ChildPrice: selectedSchedule.ticketTypes[1]?.priceAmount,
            },
            {
              AvailablePaidExtraIds: Object.keys(activity.paidExtras).map(
                (paidExtraId) =>
                  selectedSchedule.availablePaidExtras.includes(paidExtraId)
              ),
            },
          ]);
        }, 1000);
      } else {
        setValue([
          {
            Capacity: 0,
          },
          {
            StartDateTime: moment(selectedDate).startOf("day").format(),
          },
          {
            EndDateTime: moment(selectedDate)
              .add(
                activity.defaultDurationInDays
                  ? activity.defaultDurationInDays - 1
                  : 0,
                "days"
              )
              .endOf("day")
              .format(),
          },
        ]);
      }
    }
  }, [
    isScheduleFormVisible,
    isEditingSchedule,
    selectedDate,
    activity,
    selectedSchedule,
    setValue,
  ]);

  useEffect(() => {
    dispatch(fetchActivityById(match.params.id));
  }, []);

  useEffect(() => {
    if (selectedSchedule) {
      dispatch(
        fetchBookings({
          activityId: activity.id,
          scheduleId: selectedSchedule.id,
        })
      );
    }
  }, [selectedSchedule]);

  const currentPaymentStatusHandler = (status) => {
    if(!status) return 'Unpaid '
    if(status === 0) return 'Unpaid '
    if(status === 1) return 'Payment Failed '
    if(status === 2) return 'Paid '
    if(status === 3) return 'Refunded '
  }

  return !activity ? (
    <PageLoader label="Fetching experience data..." />
  ) : (
    <Container style={{ padding: 30, color: "black" }}>
      <Modal
        open={isScheduleFormVisible}
        toggle={() => {
          setIsScheduleFormVisible(!isScheduleFormVisible);
        }}
        className="scheduleModal"
      >
        <ModalHeader>
          {isEditingSchedule ? "Edit schedule" : "New schedule entry"}
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row form>
              <Col xs={12} md={6}>
                <DateTimePicker
                  placeholder="Pick date & time..."
                  name="StartDateTime"
                  label="Activity starts"
                  errors={errors.StartDateTime}
                  innerRef={register({
                    required: "This field is required",
                  })}
                  control={control}
                />
              </Col>
              <Col xs={12} md={6}>
                <DateTimePicker
                  placeholder="Pick date & time..."
                  name="EndDateTime"
                  label="Activity ends"
                  errors={errors.EndDateTime}
                  innerRef={register({
                    required: "This field is required",
                  })}
                  control={control}
                />
              </Col>
            </Row>
            <Row form>
              <Col xs={12} md={4}>
                <Input
                  name="Capacity"
                  placeholder="Capacity..."
                  label="Capacity"
                  type="number"
                  append={"tickets"}
                  errors={{
                    message: errors.Capacity?.type
                      ? `Capacity should be at least ${
                          selectedSchedule.capacity -
                          selectedSchedule.spacesLeft
                        }`
                      : "",
                  }}
                  innerRef={register({
                    min: selectedSchedule
                      ? selectedSchedule.capacity - selectedSchedule.spacesLeft
                      : 0,
                    type: "number",
                  })}
                />
              </Col>
              <Col xs={12} md={4}>
                <Input
                  name="AdultPrice"
                  label="Adult price"
                  placeholder="Adult ticket price..."
                  type="number"
                  step="0.01"
                  append={activity.currency.id}
                  errors={{
                    message: errors.AdultPrice?.type
                      ? "Adult price is required"
                      : "",
                  }}
                  innerRef={register({
                    min: 1,
                    required: true,
                    type: "number",
                  })}
                />
              </Col>
              <Col xs={12} md={4}>
                <Input
                  name="ChildPrice"
                  label="Child price"
                  placeholder="Child ticket price..."
                  type="number"
                  step="0.01"
                  append={activity.currency.id}
                  errors={errors.ChildPrice}
                  innerRef={register}
                />
              </Col>
            </Row>
            {activity.paidExtras ? (
              <Row form>
                <Col xs={12}>
                  {Object.keys(activity.paidExtras).length ? (
                    <label>Select available extras</label>
                  ) : null}
                  <CheckboxGroup
                    options={Object.keys(activity.paidExtras).map(
                      (extraId) => activity.paidExtras[extraId]
                    )}
                    name="AvailablePaidExtraIds"
                    innerRef={register}
                  />
                </Col>
              </Row>
            ) : null}
            <Row style={{ justifyContent: "center", marginTop: 30 }}>
              <Button>
                {isEditingSchedule ? "Save changes" : "Add schedule entry"}
              </Button>
            </Row>
          </form>
        </ModalBody>
      </Modal>
      <Row>
        <Col>
          <div
            style={{
              display: "block",
              fontWeight: 700,
              cursor: "pointer",
              marginBottom: 20,
            }}
            className="backLink"
            onClick={() => {
              selectedSchedule
                ? setSelectedScheduleId(null)
                : history.push(`/dashboard/manage/${match.params.id}`);
            }}
          >
            <FiArrowLeft style={{ height: 24, width: 24, marginRight: 5 }} />
            {selectedSchedule ? "manage schedule" : "manage this experience"}
          </div>
        </Col>
      </Row>
      <Row style={{ justifyContent: "space-between", alignItems: "flex-end" }}>
        <h1
          style={{
            display: "inline",
            lineHeight: 1,
            verticalAlign: "bottom",
            fontWeight: 900,
            fontSize: 60,
            letterSpacing: 1.25,
            color: "#000",
            marginLeft: 15,
            marginTop: 30,
          }}
        >
          {selectedSchedule ? "Schedule details" : "Manage schedule"}
        </h1>
        {selectedSchedule ? null : (
          <Button
            theme="danger"
            style={{ marginBottom: 10 }}
            onClick={() =>
              history.push(`/dashboard/add-availability/${match.params.id}`)
            }
          >
            Add extended availability
          </Button>
        )}
        {selectedSchedule ? (
          <div style={{ height: 40, marginTop: 30 }}>
            <Button
              style={{ marginRight: 10 }}
              onClick={(e) => {
                e.preventDefault();
                setIsScheduleFormVisible(true);
                setIsEditingSchedule(true);
              }}
            >
              Edit schedule
            </Button>
            <Button
              theme="danger"
              onClick={(e) => {
                e.preventDefault();
                dispatch(
                  deleteSchedule({
                    activityId: activity.id,
                    scheduleId: selectedSchedule.id,
                  })
                );
              }}
            >
              Delete schedule
            </Button>
          </div>
        ) : null}
      </Row>
      {selectedSchedule ? (
        <div>
          {activity.activityTitle && !isFetchingBookings ? (
            <DownloadPDF
              title={activity.activityTitle}
              date={moment
                .parseZone(selectedSchedule.startDateTime)
                .format("MMMM Do YYYY")}
              avatar={activity.avatarUrl}
              bookings={bookings}
            />
          ) : null}
          <h5>Experience description</h5>
          <div>Title: {activity.activityTitle}</div>
          <div>
            Start time:{" "}
            {moment.parseZone(selectedSchedule.startDateTime).format("LLL")}
          </div>
          <div>
            End time:{" "}
            {moment.parseZone(selectedSchedule.endDateTime).format("LLL")}
          </div>
          <div>
            Adult price: {selectedSchedule.ticketTypes[0].priceAmount}{" "}
            {activity.currency.id}
          </div>
          <div>
            Child price:{" "}
            {selectedSchedule.ticketTypes[1]
              ? `${selectedSchedule.ticketTypes[1].priceAmount} ${activity.currency.id}`
              : "N/A"}
          </div>
          {/* <div>
            {`${
              selectedSchedule.capacity - selectedSchedule.spacesLeft
            } out of ${selectedSchedule.capacity} seats booked`}
          </div> */}
          <div>
            Available paid extras:{" "}
            {selectedSchedule.availablePaidExtras
              .map((scheduleId) =>
                activity.paidExtras[scheduleId]
                  ? `${activity.paidExtras[scheduleId].label} (${activity.paidExtras[scheduleId].priceAmount} ${activity.currency.id})`
                  : ""
              )
              .join(", ")}
          </div>
          <div>
            Estimated earnings:{" "}
            {isFetchingBookings
              ? "calculating..."
              : `${bookings.reduce(
                  (total, booking) =>
                    total + booking.bookedEntities.totalAmount,
                  0
                )} ${activity.currency.id}`}
          </div>
          <div>
            Paid:{" "}
            {isFetchingBookings
              ? "calculating..."
              : `${bookings.reduce(
                  (total, booking) =>
                    total +
                    (booking.paymentDetails.paymentStatus === 2
                      ? booking.bookedEntities.totalAmount
                      : 0),
                  0
                )} ${activity.currency.id}`}
          </div>

          <div>Capacity: {" "} {selectedSchedule.capacity}</div>
          <div>Paid bookings:{" "}  {selectedSchedule.paidBookings}</div>
          <div>
            Total bookings:{" "}
            {selectedSchedule.capacity - selectedSchedule.spacesLeft}
          </div>
          <hr />
          {isFetchingBookings ? (
            <SectionLoader label="Fetching bookings..." />
          ) : (
            <div>
              <h5>Bookings</h5>
              {bookings.map((booking) => (
                <div>
                  <div>
                    Booked by:{" "}
                    {`${booking.bookedBy.firstName} ${booking.bookedBy.lastName}`}
                  </div>
                  <div>
                    Contact info:{" "}
                    {`${booking.bookedBy.emailAddress} / ${booking.bookedBy.phoneNumber}`}
                  </div>
                  <div>Booking ref.: {booking.confirmationNumber}</div>
                  <div>
                    Booked on:{" "}
                    {moment.utc(booking.bookedOn).local().format("LLL")}
                  </div>
                  {/* <div>
                    Status: {bookingStatus[booking.bookingStatus.status]}
                  </div> */}
                  <div>
                    <span style={{ display: "block" }}>Tickets</span>
                    <span style={{ fontSize: 16, fontWeight: 600 }}>
                      {booking.bookedEntities.bookedTickets.map((ticket) => (
                        <div>{`${ticket.count} * ${ticket.label}`}</div>
                      ))}
                    </span>

                    {booking.bookedEntities.bookedPaidExtras.length ? (
                      <>
                        <span style={{ display: "block" }}>Extras</span>
                        <span style={{ fontSize: 16, fontWeight: 600 }}>
                          {booking.bookedEntities.bookedPaidExtras.map(
                            (extra) => (
                              <div>{`${extra.count} * ${extra.label}`}</div>
                            )
                          )}
                        </span>
                      </>
                    ) : null}
                  </div>
                  <div>
                    Total earnings:
                    {` ${booking.bookedEntities.totalAmount} ${activity.currency.id}`}
                  </div>
                  <div>Payment status: {currentPaymentStatusHandler(booking.paymentDetails.paymentStatus)}</div>
                  <span style={{ display: 'block' }}>Travelers details</span>
                  <ol>
                    {booking.guests.map((guest) => (
                      <li>
                        <ul>
                          <li>
                            Full name: {`${guest.firstName} ${guest.lastName}`}
                          </li>
                          <li>Gender: {guest.gender || "N/A"}</li>
                          <li>
                            Date of birth:{" "}
                            {guest.dateOfBirth
                              ? moment
                                  .utc(guest.dateOfBirth)
                                  .format("DD/MM/YYYY")
                              : "N/A"}
                          </li>
                          <li>Email address: {guest.emailAddress}</li>
                          <li>Phone number: {guest.phoneNumber}</li>
                          <li>Id Type: {guest.idType || "N/A"}</li>
                          <li>Id Number: {guest.idNumber || "N/A"}</li>
                        </ul>
                      </li>
                    ))}
                  </ol>
                  <hr />
                </div>
              ))}
            </div>
          )}
          {}
        </div>
      ) : (
        <Calendar
          className="scheduler"
          onChange={(date) => {
            setSelectedDate(date);
          }}
          value={selectedDate}
          tileContent={(props) => (
            <TileContent
              {...props}
              existingSchedules={
                schedules[moment(props.date).startOf("day").unix()]
              }
            />
          )}
          tileClassName="schedulerTile"
          schedules={schedules}
        />
      )}
    </Container>
  );
};

const DownloadPDF = (props) =>
  useMemo(
    () => (
      <PDFDownloadLink
        document={<BookingsDetailsList {...props} />}
        fileName={`bookings-${props.title.replace(
          /[# -]/g,
          "_"
        )}-${props.date.replace(/ /g, "_")}.pdf`}
      >
        {({ loading }) => (
          <Button theme="dark">
            {loading ? "Generating PDF..." : "Download Bookings Details PDF"}
          </Button>
        )}
      </PDFDownloadLink>
    ),
    [props]
  );

export default ActivitySchedulePage;
