import React from 'react';
import Datetime from 'react-datetime';
import {
  FormGroup,
  FormInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'shards-react';
import { FiCalendar } from 'react-icons/fi';
import './datetime-picker.css';
import { Controller } from 'react-hook-form';
import moment from 'moment';

const DateTimePicker = (pickerProps) => {
  return (
    <Controller
      as={(props) => (
        <FormGroup style={{ position: 'relative' }}>
          <label htmlFor={props.id}>{props.label}</label>
          <InputGroup>
            <Datetime
              renderInput={(inputProps) => (
                <FormInput
                  autoComplete='off'
                  value={
                    props.value
                      ? moment(props.value).format('DD/MM/YYYY h:mm A')
                      : ''
                  }
                  {...inputProps}
                  placeholder={props.placeholder}
                  invalid={props.errors && props.errors.message}
                  readOnly={props.readOnly}
                  style={{ background: 'white', cursor: 'pointer' }}
                />
              )}
              onChange={props.onChange}
              style={{ marginBottom: 0 }}
              dateFormat={'DD/MM/YYYY'}
              timeFormat={!props.dateOnly}
              defaultValue={
                props.value
                  ? moment(props.value).format(
                    props.dateOnly ? 'DD/MM/YYYY' : 'DD/MM/YYYY h:mm A',
                  )
                  : ''
              }
              utc={true}
            />
            <InputGroupAddon type='append'>
              <InputGroupText>
                <FiCalendar style={{ height: 18, width: 18 }} />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
          {props.errors ? (
            <span style={{ position: 'absolute', right: 0 }}>
              {props.errors.message}
            </span>
          ) : null}
        </FormGroup>
      )}
      {...pickerProps}
    />
  );
};

export default DateTimePicker;
