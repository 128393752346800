import React from 'react';
import {
  Container,
  Row,
  Col,
} from 'shards-react';
import BondaiLogo from '../../assets/images/logo.png';
import InquiryForm from '../explore/InquiryForm';

const InquiryPage = () => {
  return (
    <Container style={{ flex: 1, paddingTop: 100, paddingBottom: 60 }}>
      <Container style={{ maxHeight: '100%', padding: '30px 0' }}>
        <InquiryForm />
      </Container>
    </Container>
  );
};

export default InquiryPage;
