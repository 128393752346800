import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink,
} from "shards-react";
import { MdExitToApp } from "react-icons/md";
import BondaiLogoWhite from "./assets/images/logo_white.png";
import BondaiLogoBlack from "./assets/images/logo.png";
import history from "./utils/history";
import { logout } from "./features/auth/userSlice";
import { fetchDetails } from "./features/auth/userEffects";
import { useDispatch, useSelector } from "react-redux";

const Header = () => {
  const dispatch = useDispatch();
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [collapseOpen, setCollapseOpen] = useState(false);
  const user = useSelector((state) => state.user.details);
  const isExplore = history.location.pathname === "/explore";
  const isTourProvider = user.role === "tourProviderAdmin";
  const shouldDisplayCheckout = useSelector(
    (state) =>
      state.bookings.checkout &&
      state.bookings.checkout.activity &&
      !isTourProvider &&
      history.location.pathname !== "/checkout"
  );
  const isDarkTheme = history.location.pathname.indexOf("dashboard") !== -1;

  const toggleNavbar = () => {
    setCollapseOpen(!collapseOpen);
  };

  return (
    <Navbar type={isDarkTheme ? "dark" : "light"} expand="md" sticky>
      <NavbarBrand
        href="https://bondai.io/"
        style={{ display: "flex", "align-items": "center" }}
      >
        <img
          alt="Bondai"
          width='110px'
          src={isDarkTheme ? BondaiLogoWhite : BondaiLogoBlack}
        />
      </NavbarBrand>
      <NavbarToggler onClick={toggleNavbar} />
      <Collapse open={collapseOpen} navbar className="ml-auto">
        <Nav
          navbar
          className="ml-auto"
          style={{ display: "flex", alignItems: "center" }}
        >
          {shouldDisplayCheckout ? (
            <NavItem style={{ marginRight: isExplore ? 20 : 0 }}>
              <NavLink
                onClick={() => history.push("/checkout")}
                active={history.location.pathname === "/checkout"}
              >
                Continue to checkout
              </NavLink>
            </NavItem>
          ) : null}
          {!isExplore ? (
            <NavItem>
              <NavLink
                onClick={() => history.push("/explore")}
                active={history.location.pathname === "/explore"}
              >
                Explore
              </NavLink>
            </NavItem>
          ) : null}

          {user.role ? (
            <>
              {isTourProvider
                ? [
                    <NavItem>
                      <NavLink
                        onClick={() => history.push("/dashboard")}
                        active={history.location.pathname === "/dashboard"}
                      >
                        Your experiences
                      </NavLink>
                    </NavItem>,
                    <NavItem>
                      <NavLink
                        onClick={() =>
                          history.push("/dashboard/add-experience")
                        }
                        active={
                          history.location.pathname ===
                          "/dashboard/add-experience"
                        }
                      >
                        Add experience
                      </NavLink>
                    </NavItem>,
                    <NavItem>
                      <NavLink
                        onClick={() => history.push("/dashboard/profile")}
                        active={
                          history.location.pathname === "/dashboard/profile"
                        }
                      >
                        Edit profile
                      </NavLink>
                    </NavItem>,
                  ]
                : user.role === "superAdmin"
                ? [
                    <NavItem>
                      <NavLink
                        onClick={() => history.push("/dashboard/admin")}
                        active={
                          history.location.pathname === "/dashboard/admin"
                        }
                      >
                        Admin
                      </NavLink>
                    </NavItem>,
                  ]
                : [
                    <NavItem>
                      <NavLink
                        onClick={() => history.push("/bookings")}
                        active={history.location.pathname === "/bookings"}
                      >
                        Your bookings
                      </NavLink>
                    </NavItem>,
                  ]}
              <Dropdown
                open={isMenuVisible}
                toggle={() => setIsMenuVisible(!isMenuVisible)}
                size="sm"
              >
                <DropdownToggle theme="dark" style={{ height: 40 }} caret>
                  <span style={{ marginRight: 10, fontSize: 14 }}>
                    {user.tourProviderName ||
                      `${user.firstName} ${user.lastName}`}
                  </span>
                </DropdownToggle>
                <DropdownMenu right>
                  {/* <DropdownItem
                    onClick={() => history.push('/dashboard/account')}
                  >
                    <MdAccountCircle
                      size={18}
                      color='black'
                      style={{ marginRight: 5 }}
                    />
                    Account details
                  </DropdownItem> */}
                  <DropdownItem
                    onClick={() => {
                      dispatch(logout());
                      history.push("/explore");
                    }}
                  >
                    <MdExitToApp
                      size={18}
                      color="black"
                      style={{ marginRight: 5 }}
                    />
                    Logout
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </>
          ) : (
            <NavItem style={{ display: "flex", alignItems: "center" }}>
              <NavLink
                onClick={() => history.push("/login")}
                style={{
                  fontWeight: 600,
                  color: isDarkTheme ? "#fff" : "#000",
                }}
                className="loginLink"
              >
                Already a Bondai user?
              </NavLink>
            </NavItem>
          )}
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default Header;
