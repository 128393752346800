import React from 'react';
import { FormTextarea, FormGroup } from 'shards-react';

const Textarea = (props) => {
  return (
    <FormGroup>
      {props.label ? <label htmlFor={props.id}>{props.label}</label> : null}
      <FormTextarea {...props} invalid={props.errors && props.errors.message} />
      {props.errors ? (
        <span style={{ position: 'absolute', right: 0 }} className='error'>
          {props.errors.message}
        </span>
      ) : null}
    </FormGroup>
  );
};

export default Textarea;
