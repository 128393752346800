import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Row, Col, Button, Container } from 'shards-react';
import { FiArrowLeft } from 'react-icons/fi';
import Datetime from 'react-datetime';
import moment from 'moment';
import { Input, DateTimePicker, TimePicker, CheckboxGroup } from '../../components/ui-kit';
import MultipleDatePicker from '../../components/MultipleDatePicker';
import PageLoader from '../../components/PageLoader';
import ControlledYesNo from '../../components/ControlledYesNo';
import history from '../../utils/history';

import {
  fetchActivityById,
  createSchedules,
} from './activitiesEffects';

const AddMultipleSchedulesPage = ({ match }) => {

  const dispatch = useDispatch();
  const [selectedDates, setSelectedDates] = useState([]);
  const [schedulesDates, setSchedulesDates] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const activity = useSelector(
    (state) => state.activities.entities[match.params.id],
  );
  const isSubmitting = useSelector((state) => state.activities.loading.createSchedules === 'pending');

  useEffect(() => {
    dispatch(fetchActivityById(match.params.id));
  }, []);

  useEffect(() => {
    if (activity) {
      if (activity.schedules) {
        setSchedulesDates(activity.schedules.map((schedule) => moment.parseZone(schedule.startDateTime).local(true).format('MM/DD/YYYY')));
      }
      setTimeout(() => {
        setValue([
          { 
            Duration: activity.defaultDurationInDays || 1,
          },
          {
            StartTime: moment().startOf('day'),
          },
          {
            EndTime: moment().endOf('day'),
          },
          {
            SuitableForChildren: true,
          },
        ]);
      }, 1000);
    }  
  }, [activity]);
  
  const { register, handleSubmit, control, errors, setValue, getValues, watch } = useForm();

  const onSubmit = (data) => {
    setIsSubmitted(true);
    if (!selectedDates.length) return;
    dispatch(
      createSchedules({
        activityId: activity.id,
        schedule: selectedDates.map((date) => {
          const startDate = moment(date);
          const endDate = moment(date).add(data.Duration - 1, 'days');
          return {
            ticketTypeEntities: [
              {
                ticketTypeId: process.env.REACT_APP_ADULT_TICKET_ID,
                priceAmount: parseFloat(data.AdultPrice),
              },
              ...data.SuitableForChildren ? [{
                ticketTypeId: process.env.REACT_APP_CHILD_TICKET_ID,
                priceAmount:
                  data.ChildPrice && parseFloat(data.ChildPrice) > 0
                    ? parseFloat(data.ChildPrice)
                    : 0,
              }] : [],
            ],
            availablePaidExtraIds: Object.keys(activity.paidExtras).reduce(
              (ids, id, index) => [
                ...ids,
                ...(data.AvailablePaidExtraIds[index] ? [id] : []),
              ],
              [],
            ),
            startDateTime: moment(data.StartTime).set({
              'year': startDate.get('year'),
              'month': startDate.get('month'),
              'date': startDate.get('date'),
            }).format(),
            endDateTime: moment(data.EndTime).set({
              'year': endDate.get('year'),
              'month': endDate.get('month'),
              'date': endDate.get('date'),
            }).format(),
            capacity: parseInt(data.Capacity) || 0,
          };
        }),
      }),
    );
  }

  return !activity ? (
    <PageLoader label='Fetching experience data...' />
  ) : (
    <Container className='availabilityPage'>
      <Row>
        <Col xs={12} md={{ size: 8, offset: 2 }}>
          <div
            style={{
              display: 'block',
              fontWeight: 700,
              cursor: 'pointer',
              marginBottom: 20,
            }}
            className='backLink'
            onClick={() => {
              history.push(`/dashboard/schedule/${match.params.id}`);
            }}
          >
            <FiArrowLeft style={{ height: 24, width: 24, marginRight: 5 }} />
            manage schedule
          </div>
        </Col>
      </Row>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs={12} md={{ size: 8, offset: 2 }}>
            <h1 className='pageTitle' style={{ textAlign: 'left' }}>
              Add availability
            </h1>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={{ size: 8, offset: 2 }} className='subtitle'>
            <h3>1. Schedule</h3>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={{ size: 5, offset: 2 }}>
            <Row form className="schedule__list" style={{ border: 'none' }}>
              <label>Pick start dates</label>
              <Datetime
                renderInput={() => null}
                onChange={(date) => {
                  const selectedDate = date.format('MM/DD/YYYY');
                  if (selectedDates?.includes(selectedDate)) {
                    setSelectedDates(
                      selectedDates.filter(
                        (dateSelected) => dateSelected !== selectedDate,
                      ),
                    );
                  } else {
                    setSelectedDates([...selectedDates, selectedDate]);
                  }
                }}
                dateFormat={'MM/DD/YYYY'}
                timeFormat={false}
                renderDay={(propsRender, currentDate, value) => {
                  const formattedCurrentDate = currentDate.format('MM/DD/YYYY');
                  if (currentDate.isBefore(moment().startOf('day'))) {
                    return (
                      <td
                        style={{
                          color: '#dedede',
                          backgroundColor: 'white',
                        }}
                      >
                        {currentDate.date()}
                      </td>
                    );
                  }
                  return (
                    <td
                      {...propsRender}
                      style={{
                        color: selectedDates?.includes(formattedCurrentDate)
                          ? 'white'
                          : 'black',
                        borderRadius: 8,
                        border: '2px solid white',
                        backgroundColor: selectedDates?.includes(formattedCurrentDate)
                          ? '#f7414e'
                          : (schedulesDates?.includes(formattedCurrentDate) ? '#f9f2a3' : 'white'),
                        height: 40
                      }}
                    >
                      {currentDate.date()}
                    </td>
                  );
                }}
                open={true}
                closeOnSelect={false}
              />
              {isSubmitted && !selectedDates.length ? (
                <p style={{ color: '#f7414e', fontWeight: 'bold', marginTop: 10 }}>Please select at least one start date</p>
              ) : null}
            </Row>
          </Col>
          <Col xs={12} md={3}>
            <Row form>
              <Col>
                <Input
                  name='Duration'
                  placeholder='Duration...'
                  label='Duration'
                  type='number'
                  step='1'
                  append={'days'}
                  errors={{
                    message: errors.Duration
                      ? `Duration is required`
                      : '',
                  }}
                  step='1'
                  innerRef={register({
                    min: 1,
                    required: true,
                    type: 'number',
                  })}
                />
              </Col>
            </Row>
            <Row form>
              <Col xs={12}>
                <TimePicker
                  placeholder='Pick start time...'
                  name='StartTime'
                  label='Start time'
                  errors={{
                    message: errors.StartTime
                      ? 'This field is required'
                      : '',
                  }}
                  innerRef={register({
                    required: true
                  })}
                  control={control}
                  readOnly
                />
              </Col>
              <Col xs={12}>
                <TimePicker
                  placeholder='Pick end time...'
                  name='EndTime'
                  label={`End time ${(() => {
                    const duration = parseInt(watch('Duration'));
                    if (duration < 2 || isNaN(duration)) return '';
                    if (duration === 2) return '(next day)';
                    return `(+${duration - 1} days)`;
                  })()}`}
                  errors={errors.EndTime}
                  innerRef={register}
                  rules={{
                    validate: () => {
                      const value = watch('EndTime');
                      if (!value) return 'This field is required';
                      if (watch('Duration') < 2 && value.isBefore(watch('StartTime'))) return 'End time should be after start time';
                    }
                  }}
                  dateFormat={false}
                  control={control}
                  readOnly
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={{ size: 8, offset: 2 }} className='subtitle'>
            <h3>2. Capacity & pricing</h3>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={{ size: 8, offset: 2 }}>
            <Row>
              <Col xs={12} md={6}>
                <Input
                  name='Capacity'
                  placeholder='Capacity...'
                  label='Capacity'
                  type='number'
                  step='1'
                  append={'tickets'}
                  errors={{
                    message: errors.Capacity
                      ? `Capacity is required`
                      : '',
                  }}
                  innerRef={register({
                    min: 1,
                    required: true,
                    type: 'number',
                  })}
                />
              </Col>
              <Col xs={12} md={6}>
                <Input
                  name='AdultPrice'
                  label='Adult price'
                  placeholder='Adult ticket price...'
                  type='number'
                  step='0.01'
                  append="SAR"
                  errors={{
                    message: errors.AdultPrice
                      ? 'Adult price is required'
                      : '',
                  }}
                  innerRef={register({
                    min: 1,
                    required: true,
                    type: 'number',
                  })}
                />
              </Col>
            </Row>
            <Row>                
              <Col xs={12} md={6}>
                <label style={{ display: 'block', marginBottom: 0 }}>Is suitable for children?</label>
                <ControlledYesNo
                  name='SuitableForChildren'
                  control={control}
                />
              </Col>
              {watch('SuitableForChildren') ? (
                <Col xs={12} md={6}>
                  <Input
                    name='ChildPrice'
                    label='Child price'
                    placeholder='Child ticket price...'
                    type='number'
                    step='1'
                    append="SAR"
                    errors={{
                      message: errors.ChildPrice
                      ? 'Child price is required'
                      : '',
                    }}
                    innerRef={register({
                      min: 0,
                      required: true,
                      type: 'number',
                    })}
                  />
                </Col>
              ) : null}
            </Row>
          </Col>
        </Row>
        {Object.keys(activity.paidExtras).length ? (
          <Row>
            <Col xs={12} md={{ size: 8, offset: 2 }} className='subtitle'>
              <h3>3. Paid extras</h3>
              <hr />
            </Col>
            <Col xs={12} md={{ size: 8, offset: 2 }}>
              {Object.keys(activity.paidExtras).length ? (
                <label>Select available extras</label>
              ) : null}
              <CheckboxGroup
                options={Object.keys(activity.paidExtras).map(
                  (extraId) => activity.paidExtras[extraId],
                )}
                name='AvailablePaidExtraIds'
                innerRef={register}
              />
            </Col>
          </Row>
        ) : null}
        <Row style={{ justifyContent: 'center', marginTop: 30 }}>
          <Button theme='dark' disabled={isSubmitting}>{isSubmitting ? 'Submitting...' : 'Submit availability'}</Button>
        </Row>
      </form>
    </Container>
  )
};

export default AddMultipleSchedulesPage;