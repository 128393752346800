import React, { useEffect } from 'react';
import { Container, Row, Col, Badge } from 'shards-react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchActivities } from './activitiesEffects';

import { Link } from 'react-router-dom';

const DashboardPage = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchActivities());
  }, []);

  const activities = useSelector((state) =>
    state.activities.ids.map((id) => state.activities.entities[id]),
  );

  return (
    <Container>
      <Row
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 30,
        }}
      >
        <h1
          style={{
            display: 'inline',
            lineHeight: 1,
            verticalAlign: 'bottom',
            fontWeight: 900,
            fontSize: 60,
            letterSpacing: 1.25,
            color: '#000',
            marginLeft: 15,
          }}
        >
          Your experiences
        </h1>
      </Row>
      <Row style={{ minHeight: '100%', paddingBottom: 240 }}>
        {activities.length ? (
          activities.map((activity) => (
            <Col xs='6' md='3' key={activity.id}>
              <Link
                to={`/dashboard/manage/${activity.id}`}
                style={{ cursor: 'pointer', textDecoration: 'none' }}
              >
                <div
                  style={{
                    position: 'relative',
                    width: '100%',
                    backgroundColor: 'rgba(255, 255, 255, 0.75)',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundImage: `url(${activity.avatarUrl})`,
                    borderRadius: 10,
                    marginTop: 30,
                  }}
                >
                  <div
                    style={{ display: 'block', paddingBottom: '100%' }}
                  >
                    <Badge theme={
                      activity.isPrivate ? 'secondary' : (
                        activity.isApproved ? 'success' : 'info'
                      )
                    } style={{position: 'absolute', top: 10, right: 10}}>{
                      activity.isPrivate ? 'private' : (
                        activity.isApproved ? 'public' : 'pending approval'
                      )
                    }</Badge>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: 10,
                    fontWeight: 600,
                    fontSize: 18,
                    color: '#000',
                    letterSpacing: 1.25,
                  }}
                >
                  {activity.activityTitle}
                </div>
              </Link>
            </Col>
          ))
        ) : (
          <div style={{ color: 'black', marginTop: 20 }}>
            {`Nothing to see here just yet! Go ahead and `}
            <div
              style={{ display: 'inline' }}
              onClick={() => props.history.push('/dashboard/add-experience')}
              className='filterLink'
            >
              add your first experience
            </div>
            .
          </div>
        )}
      </Row>
    </Container>
  );
};

const styles = {
  socialIcons: { width: 28, height: 28, margin: 10 },
};

export default DashboardPage;
