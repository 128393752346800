import React, { useState, useEffect, useRef } from 'react';
import usePlacesAutocomplete from 'use-places-autocomplete';
import {
  FormInput,
  Dropdown,
  Button,
  DropdownMenu,
  DropdownItem,
  FormGroup,
} from 'shards-react';
import Loader from 'react-loader-spinner';
import { placesTypes } from '../helpers/constants';
import { FiX, FiSearch, FiMapPin } from 'react-icons/fi';

const PlacesAutocomplete = ({
  onChange,
  onBlur,
  onClear,
  errors,
  initialValue,
  types = [placesTypes.LOCATION],
  title = '',
  placeholder = '',
  multiple = false,
}) => {
  const {
    ready,
    value,
    suggestions: { loading, status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 500,
    requestOptions: {
      types,
    },
  });
  const inputRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(
    initialValue || (multiple ? [] : null),
  );
  const [current, setCurrent] = useState(null);

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = (val) => {
    if (multiple) {
      setSelected([...selected, val]);
      clearInput();
    } else {
      setSelected(val);
      setValue(val.label, false);
    }
  };

  const clearInput = () => {
    clearSuggestions();
    if (!multiple) setSelected('');
    setValue('', false);
  };

  useEffect(() => {
    if (initialValue && !value) {
      setValue(initialValue.label, false);
      setSelected(initialValue);
    }
  }, [initialValue]);

  useEffect(() => {
    if (status === 'OK' && data && data.length) setIsOpen(true);
    else {
      setIsOpen(false);
    }
  }, [status, data]);

  useEffect(() => {
    onChange(selected);
  }, [selected]);

  useEffect(() => {
    if (inputRef?.current) {
      setTimeout(() => inputRef.current.focus(), 300)
    }
  }, [inputRef]);

  return (
    <Dropdown
      open={isOpen}
      toggle={() => {
        setIsOpen(!isOpen);
      }}
    >
      <FormGroup>
        {title ? <label>{title}</label> : null}
        <div className='placesPicker__inputContainer'>
          <FormInput
            autoFocus
            innerRef={inputRef}
            className='placesPicker__input customInput--black'
            value={value}
            placeholder={placeholder}
            onChange={handleInput}
            disabled={!ready}
            invalid={current === null && errors && errors.message}
            onFocus={(e) => {
              setCurrent(e.target.value);
              if (e.target.value) setValue(value);
            }}
            onBlur={(e) => {
              if (e.target.value !== current)
                setValue(current, false);
              setCurrent(null);
              setTimeout(onBlur, 300);
            }}
          />
          {/* {loading && (
            <Loader
              type='Rings'
              className='placesPicker__inputLoader'
              width={40}
              height={40}
              color='black'
            />
          )} */}
        </div>
        {errors && current === null ? (
          <span style={{ position: 'absolute', right: 0 }}>
            {errors.message}
          </span>
        ) : null}
      </FormGroup>

      <DropdownMenu className='placesPicker__suggestionsList'>
        {status === 'OK' &&
          data.map((item) => (
            <DropdownItem
              className='placesPicker__suggestionsItem'
              key={item.place_id}
              onClick={() => {
                handleSelect({
                  label: item.structured_formatting.main_text,
                  value: item.place_id,
                });
              }}
            >
              <span>{item.structured_formatting.main_text}</span>
              {item.structured_formatting.secondary_text}
            </DropdownItem>
          ))}
      </DropdownMenu>
      {selected?.label && (
        <Button
          theme="dark"
          style={{
            fontSize: 12,
            fontWeight: 700,
            width: 'calc(100% + 48px)',
            marginLeft: -28,
            textAlign: 'center',
            marginBottom: 0,
            marginTop: 15
          }}
          onClick={onClear}
        >
          clear selection
        </Button>
      )}
    </Dropdown>
  );
};

export default PlacesAutocomplete;
