import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Button,
  Collapse,
  Modal,
  ModalBody,
} from 'shards-react';
import PageLoader from '../../components/PageLoader';
import {
  FiEye,
  FiEyeOff,
  FiTrash2,
  FiEdit,
  FiCalendar,
  FiMail,
  FiArrowLeftCircle,
  FiCopy,
} from 'react-icons/fi';

import {
  fetchActivityById,
  removeActivity,
  setActivityVisibilityStatus,
  createCustomPrivateTrip,
} from './activitiesEffects';

const ManageActivityPage = ({ history, match }) => {
  const [isLoadingRemove, setisLoadingRemove] = useState(false);
  const [isDeletePopupVisible, setisDeletePopupVisible] = useState(false);

  const dispatch = useDispatch();
  const activity = useSelector(
    (state) => state.activities.entities[match.params.id],
  );

  const isFetching = useSelector(
    (state) => state.activities.loading.fetchById !== 'done',
  );

  useEffect(() => {
    dispatch(fetchActivityById(match.params.id));
  }, []);

  const isDuplicating = useSelector(
    (state) => state.activities.loading.createCustomPrivateTrip === 'pending',
  );

  return isFetching || !activity ? (
    <PageLoader label='Fetching experience data...' />
  ) : (
    <Container fluid>
      <Modal
        size='sm'
        open={isDeletePopupVisible}
        toggle={setisDeletePopupVisible}
      >
        <ModalBody>
          <p style={{ color: '#000' }}>
            Are you sure you want to delete this experience?
          </p>
          <Button
            theme='secondary'
            style={{ marginRight: 10 }}
            onClick={() => {
              setisDeletePopupVisible(false);
              setisLoadingRemove(false);
            }}
          >
            CANCEL
          </Button>
          <Button
            onClick={() => {
              dispatch(removeActivity(activity.id));
            }}
            theme='danger'
            disabled={isLoadingRemove}
          >
            Yes, DELETE
          </Button>
        </ModalBody>
      </Modal>
      <Row
        style={{
          backgroundImage: `url(${activity.avatarUrl})`,
        }}
        className='activityManagementHeader'
      >
        <Col
          xs={12}
          md={{ size: 8, offset: 2 }}
          style={{
            alignItems: 'center',
          }}
        >
          <Row>
            <div
              style={{
                display: 'block',
                fontWeight: 700,
                cursor: 'pointer',
              }}
              className='backLink'
              onClick={() => {
                history.push('/dashboard');
              }}
            >
              <FiArrowLeftCircle
                style={{ height: 24, width: 24, marginRight: 5 }}
              />
              manage other experiences
            </div>
          </Row>
          <Row style={{ width: '100%' }}>
            <Col xs={12} md={8}>
              <span style={{ display: 'block', marginTop: 60 }}>
                MANAGE EXPERIENCE
              </span>
              <span
                style={{
                  fontSize: 36,
                  fontWeight: 700,
                  display: 'block',
                  lineHeight: 1.2,
                  paddingRight: 15,
                }}
              >
                {activity.activityTitle}
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={{ size: 8, offset: 2 }}>
          <Row style={{ marginTop: 20 }}>
            <Col xs={12} md={{ size: 6, offset: 3 }}>
              <Button
                style={{ width: '100%', marginBottom: 10 }}
                onClick={() =>
                  history.push({
                    pathname: `/experience/${activity.slug}`,
                    state: { redirect: 'manage' },
                  })
                }
              >
                <FiEye style={{ height: 18, width: 18, marginRight: 6 }} />
                {activity.isPrivate ? 'Preview' : 'View'} listing
              </Button>
              <Button
                theme='secondary'
                style={{ width: '100%', marginBottom: 10 }}
                onClick={() =>
                  history.push(`/dashboard/edit/${match.params.id}`)
                }
              >
                <FiEdit style={{ height: 18, width: 18, marginRight: 6 }} />
                Edit listing
              </Button>
              <Button
                theme='secondary'
                style={{ width: '100%', marginBottom: 10 }}
                onClick={() =>
                  history.push(`/dashboard/schedule/${match.params.id}`)
                }
              >
                <FiCalendar style={{ height: 18, width: 18, marginRight: 6 }} />
                Manage schedule
              </Button>
              {!activity.parentActivityId ? (
                <>
                  <Button
                    theme='secondary'
                    style={{ width: '100%', marginBottom: 10 }}
                    disabled={isDuplicating}
                    onClick={() => {
                      dispatch(createCustomPrivateTrip(activity.id));
                    }}
                  >
                    <FiCopy style={{ height: 18, width: 18, marginRight: 6 }} />
                    Generate custom experience
                  </Button>
                  <Button
                    theme='warning'
                    style={{ width: '100%', marginBottom: 10 }}
                    onClick={() => {
                      dispatch(
                        setActivityVisibilityStatus({
                          id: activity.id,
                          value: !activity.isPrivate,
                        }),
                      );
                    }}
                  >
                    {activity.isPrivate ? (
                      <>
                        <FiEye
                          style={{ height: 18, width: 18, marginRight: 6 }}
                        />
                        List experience
                      </>
                    ) : (
                      <>
                        <FiEyeOff
                          style={{ height: 18, width: 18, marginRight: 6 }}
                        />
                        Make unlisted
                      </>
                    )}
                  </Button>
                </>
              ) : null}
              <Button
                theme='danger'
                style={{ width: '100%', marginBottom: 10 }}
                onClick={() => {
                  setisDeletePopupVisible(true);
                }}
                disabled={isLoadingRemove}
              >
                <FiTrash2 style={{ height: 18, width: 18, marginRight: 6 }} />
                Remove listing
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default ManageActivityPage;
