import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Container, Row, Col, Button } from 'shards-react';
import { emailRegex } from '../../helpers/validations';

import { Input } from '../../components/ui-kit';

import history from '../../utils/history';
import { register as createAccount } from './userEffects';
import { onRedirect } from './userSlice';

import BondaiLogo from '../../assets/images/logo.png';

const RegisterPage = (props) => {
  const dispatch = useDispatch();

  const isRegistering = useSelector(
    (state) => state.user.loading.register === 'pending',
  );
  const isRegistrationSuccessful = useSelector(
    (state) => state.user.loading.register === 'done',
  );
  const { register, handleSubmit, errors } = useForm();

  useEffect(() => {
    if (isRegistrationSuccessful) {
      history.push('/explore');
      dispatch(onRedirect());
    }
  }, [isRegistrationSuccessful]);

  const onSubmit = (data) => {
    dispatch(createAccount(data));
  };

  return (
    <Container style={{ flex: 1, paddingTop: 100, paddingBottom: 60 }}>
      <div className='pageTitle'>Register</div>
      <Row>
        <Col
          xs='12'
          md={{ size: 6, offset: 3 }}
          style={{
            color: 'black',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <div
              style={{ background: 'white', padding: '10px 30px 30px 30px' }}
            >
              <Row form>
                <Col xs={12} sm={6}>
                  <Input
                    label='Email'
                    placeholder='e.g. andrew@gmail.com'
                    name='email'
                    errors={{
                      ...errors.email,
                      message: errors.email
                        ? errors.email?.type === 'required'
                          ? 'This field is required'
                          : 'Invalid email address'
                        : '',
                    }}
                    innerRef={register({
                      required: true,
                      pattern: emailRegex,
                    })}
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <Input
                    label='Password'
                    placeholder='****************'
                    name='password'
                    type='password'
                    errors={{
                      ...errors.password,
                      message: errors.password
                        ? errors.password?.type === 'required'
                          ? 'This field is required'
                          : 'Min. length is 6 characters'
                        : '',
                    }}
                    innerRef={register({
                      required: 'This field is required',
                      minLength: 6,
                    })}
                  />
                </Col>
              </Row>
              <Row form>
                <Col xs={12} sm={6}>
                  <Input
                    label='First name'
                    placeholder='e.g. Andrew'
                    name='firstName'
                    errors={errors.firstName}
                    innerRef={register({
                      required: 'This field is required',
                    })}
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <Input
                    label='Last name'
                    placeholder='e.g. Hussein'
                    name='lastName'
                    errors={errors.lastName}
                    innerRef={register({
                      required: 'This field is required',
                    })}
                  />
                </Col>
              </Row>
              <div
                className='authLink'
                style={{ marginTop: 20 }}
                onClick={() => {
                  history.push('/login');
                }}
              >
                Already a Bondai user?
              </div>
            </div>
            <Row>
              <Button
                theme='dark'
                style={{
                  fontWeight: 500,
                  borderRadius: 10,
                  width: '100%',
                  margin: '30px auto',
                  height: 50,
                  maxWidth: 300,
                }}
                disabled={isRegistering || isRegistrationSuccessful}
              >
                {isRegistering ? 'REGISTERING...' : 'CREATE YOUR ACCOUNT'}
              </Button>
            </Row>
          </form>
        </Col>
      </Row>
    </Container>
  );
};

export default RegisterPage;
