import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { fetchActivity } from '../explore/exploreEffects';
import { login, checkIfAccountExists } from '../auth/userEffects';
import { handleCheckout } from '../bookings/bookingsEffect';
import { clearCheckout } from '../bookings/bookingsSlice';
import { setExistingAccountStatus } from '../auth/userSlice';
import { showModal } from '../notifications/notificationsSlice';

import history from '../../utils/history';
import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  FormInput,
} from 'shards-react';
import moment from 'moment';

import Header from '../../Header';
import {
  Input,
  Select,
  DateTimePicker,
  Checkbox,
} from '../../components/ui-kit';
import PageLoader from '../../components/PageLoader';
import { emailRegex, phoneRegex } from '../../helpers/validations';

import MadaLogo from '../../assets/images/mada.png';
import VisaLogo from '../../assets/images/visa.jpg';
import MastercardLogo from '../../assets/images/mastercard.png';

const CheckoutPage = () => {
  const dispatch = useDispatch();

  const isExistingAccount = useSelector(
    (state) => state.user.isExistingAccount,
  );
  const checkoutDetails = useSelector((state) => state.bookings.checkout);

  const userDetails = useSelector((state) => state.user.details);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    errors,
    control,
  } = useForm();

  const isUserTraveler = watch('IsTraveler');
  useEffect(() => {
    //TODO check if TP and redirect to explore
    if (userDetails.role) {
      // console.log(userDetails);
      setTimeout(() => {
        setValue([
          { BillingFirstName: userDetails.firstName },
          { BillingLastName: userDetails.lastName },
          { BillingPhoneNumber: userDetails.phoneNumber },
        ]);
      }, 1000);
    }
    dispatch(setExistingAccountStatus(true));
  }, [userDetails]);

  useEffect(() => {
    if (isUserTraveler) {
      setTimeout(() => {
        setValue([
          { UserGender: userDetails.gender },
          {
            UserDateOfBirth: userDetails.dateOfBirth ? moment(userDetails.dateOfBirth) : undefined,
          },
          { UserIdType: userDetails.idType },
          { UserIdNumber: userDetails.idNumber },
        ]);
      }, 1000);
    }
  }, [isUserTraveler]);

  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');

  const onSubmit = (data) => {
    dispatch(
      handleCheckout({
        activity: activityDetails.id,
        schedule: checkoutDetails.schedule,
        user: {
          billing: {
            Email: data.BillingEmail,
            Password: data.BillingPassword,
            FirstName: data.BillingFirstName,
            LastName: data.BillingLastName,
            PhoneNumber: data.BillingPhoneNumber,
          },
          traveler: data.IsTraveler
            ? {
              Gender: data.UserGender,
              DateOfBirth: moment(data.UserDateOfBirth).format(),
              IdType: data.UserIdType,
              IdNumber: data.UserIdNumber,
            }
            : null,
        },
        order: {
          isLoggedInUserAGuest: data.IsTraveler,
          tickets: [
            ...(+checkoutDetails.adultTickets
              ? [
                {
                  id: scheduleDetails.ticketTypes[0].ticketTypeId,
                  count: +checkoutDetails.adultTickets || 0,
                },
              ]
              : []),
            ...(+checkoutDetails.childTickets && scheduleDetails.ticketTypes[1]
              ? [
                {
                  id: scheduleDetails.ticketTypes[1].ticketTypeId,
                  count: +checkoutDetails.childTickets || 0,
                },
              ]
              : []),
          ],
          paidExtras: checkoutDetails.extras,
          guestDetails: [
            ...Array(
              +checkoutDetails.adultTickets - (data.IsTraveler ? 1 : 0),
            ).keys(),
          ].map((travelerIndex) => ({
            firstName: data[`Traveler${travelerIndex}FirstName`],
            lastName: data[`Traveler${travelerIndex}LastName`],
            dateOfBirth: moment(
              data[`Traveler${travelerIndex}DateOfBirth`],
            ).format(),
            email: data[`Traveler${travelerIndex}Email`],
            gender: data[`Traveler${travelerIndex}Gender`],
            idType: data[`Traveler${travelerIndex}IdType`],
            idNumber: data[`Traveler${travelerIndex}IdNumber`],
            phoneNumber: data[`Traveler${travelerIndex}PhoneNumber`],
          })),
        },
      }),
    );
  };

  useEffect(() => {
    if (checkoutDetails.activity)
      dispatch(fetchActivity({ id: checkoutDetails.activity }));
  }, [checkoutDetails]);

  const activityDetails = useSelector(
    (state) => state.explore.entities[checkoutDetails.activity],
  );

  const scheduleDetails =
    activityDetails && activityDetails.schedules
      ? activityDetails.schedules.filter(
        (schedule) => schedule.id === checkoutDetails.schedule,
      )[0]
      : null;

  const ticketsSubtotal = scheduleDetails
    ? scheduleDetails.ticketTypes[0].priceAmount *
    checkoutDetails.adultTickets +
    (scheduleDetails.ticketTypes[1] ? scheduleDetails.ticketTypes[1].priceAmount * checkoutDetails.childTickets : 0)
    : 0;
  const extrasDetails =
    activityDetails && activityDetails.paidExtras
      ? checkoutDetails.extras.map((extra) => ({
        ...activityDetails.paidExtras[extra.id],
        count: extra.count,
      }))
      : [];

  //use selectors - reselect
  const extrasSubtotal = extrasDetails.reduce(
    (subtotal, extra) => subtotal + extra.priceAmount * extra.count,
    0,
  );

  const tripDuration = scheduleDetails
    ? moment(scheduleDetails.endDateTime).diff(
      moment(scheduleDetails.startDateTime),
      'days',
    ) + 1
    : 0;

  const isBookingSubmitting = useSelector(
    (state) => state.bookings.loading.handleCheckout === 'pending',
  );

  const hasActivityFetchingFailed = useSelector(
    (state) => state.explore.loading.activity === 'failed',
  );

  useEffect(() => {
    if (hasActivityFetchingFailed || (activityDetails && activityDetails.schedules && !scheduleDetails)) {
      dispatch(
        showModal({
          message: `The experience you've tried to book or the selected date are not available anymore.`,
          dismissLabel: 'Keep exploring',
        }),
      );
      dispatch(clearCheckout());
      history.push(`/explore`);
    }
  }, [activityDetails, hasActivityFetchingFailed, scheduleDetails])

  return !activityDetails || !scheduleDetails ? (
    <PageLoader label='Fetching experience details...' />
  ) : (
    <Container style={{ flex: 1, paddingTop: 100, paddingBottom: 60, color: 'black' }}>
      <Row
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 30,
        }}
      >
        <Col xs={12} md={{ offset: 2, size: 8 }}>
          <Row>
            <h1
              style={{
                display: 'inline',
                lineHeight: 1,
                verticalAlign: 'bottom',
                fontWeight: 900,
                fontSize: 60,
                letterSpacing: 1.25,
                color: '#000',
              }}
            >
              Checkout
          </h1>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <h6>BOOKING OVERVIEW</h6>
          </Row>
          <Row>
            <Col xs={3}>
              <div
                style={{
                  backgroundImage: `url(${activityDetails.avatarUrl})`,
                  backgroundSize: 'cover',
                  width: '100%',
                  borderRadius: 8,
                }}
              >
                <div style={{ display: 'block', paddingBottom: '100%' }} />
              </div>
            </Col>
            <Col xs={9}>
              <Row>
                <Col xs={12}>
                  <div
                    className='checkoutActivityTitle'
                    onClick={() => {
                      history.push(`/experience/${activityDetails.slug}`);
                    }}
                  >
                    {activityDetails.activityTitle}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={4}>
                  <span style={{ display: 'block' }}>Location</span>
                  <span style={{ fontSize: 16, fontWeight: 600 }}>
                    {activityDetails.destination
                      ? `${activityDetails.destination.localityName
                        ? `${activityDetails.destination.localityName}, `
                        : ''
                      } ${activityDetails.destination.regionName || 'Saudi Arabia'
                      }`
                      : 'Saudi Arabia'}
                  </span>
                </Col>
                <Col xs={12} md={4}>
                  <span style={{ display: 'block' }}>Date</span>
                  <span style={{ fontSize: 16, fontWeight: 600 }}>
                    {scheduleDetails
                      ? moment.parseZone(scheduleDetails.startDateTime).format('LL')
                      : '...'}
                  </span>
                </Col>
                <Col xs={12} md={4}>
                  <span style={{ display: 'block' }}>Duration</span>
                  <span style={{ fontSize: 16, fontWeight: 600 }}>
                    {`${tripDuration} ${tripDuration > 1 ? 'days' : 'day'}`}
                  </span>
                </Col>
              </Row>
              <hr />
              <Row style={{ fontWeight: 600, marginTop: 10 }}>
                <Col xs={9}>Tickets</Col>
                <Col xs={3}>
                  {`${ticketsSubtotal} ${activityDetails.currency.id}`}
                </Col>
              </Row>
              {checkoutDetails.adultTickets ? (
                <Row>
                  <Col
                    xs={9}
                  >{`${checkoutDetails.adultTickets} * adult tickets`}</Col>
                  <Col xs={3}>{`${scheduleDetails.ticketTypes[0].priceAmount *
                    checkoutDetails.adultTickets
                    } ${activityDetails.currency.id}`}</Col>
                </Row>
              ) : null}
              {checkoutDetails.childTickets ? (
                <Row>
                  <Col
                    xs={9}
                  >{`${checkoutDetails.childTickets} * child tickets`}</Col>
                  <Col xs={3}>{`${scheduleDetails.ticketTypes[1].priceAmount *
                    checkoutDetails.childTickets
                    } ${activityDetails.currency.id}`}</Col>
                </Row>
              ) : null}
              {checkoutDetails.extras.length ? (
                <>
                  <Row style={{ fontWeight: 600, marginTop: 10 }}>
                    <Col xs={9}>Extras</Col>
                    <Col
                      xs={3}
                    >{`${extrasSubtotal} ${activityDetails.currency.id}`}</Col>
                  </Row>
                  {extrasDetails.map((extra) => (
                    <Row>
                      <Col xs={9}>{`${extra.count} * ${extra.label}`}</Col>
                      <Col xs={3}>{`${extra.priceAmount * extra.count} ${activityDetails.currency.id
                        }`}</Col>
                    </Row>
                  ))}
                </>
              ) : null}
              <Row style={{ fontWeight: 600, fontSize: 18, marginTop: 10 }}>
                <Col xs={9} style={{ textAlign: 'right' }}>
                  Total
              </Col>
                <Col xs={3}>{`${ticketsSubtotal + extrasSubtotal} ${activityDetails.currency.id
                  }`}</Col>
              </Row>
            </Col>
          </Row>
          {!userDetails.role && isExistingAccount ? (
            <div>
              <Row style={{ marginTop: 30, marginBottom: 10 }}>
                Please login to continue the checkout process.
              <span
                  style={{
                    fontWeight: 700,
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    display: 'block',
                    marginLeft: 10,
                  }}
                  onClick={() => {
                    dispatch(setExistingAccountStatus(false));
                  }}
                >
                  Don't have an account? Sign up.
              </span>
              </Row>
              <Row form style={{ alignItems: 'flex-end' }}>
                <Col xs={12} md={5}>
                  <FormGroup className='loginFormGroup'>
                    <label htmlFor='#email'>Email</label>
                    <FormInput
                      autoComplete='off'
                      type='text'
                      id='#email'
                      placeholder='Email'
                      name='email'
                      value={loginEmail}
                      onChange={(e) => {
                        setLoginEmail(e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={5}>
                  <FormGroup className='loginFormGroup'>
                    <label htmlFor='#password'>Password</label>
                    <FormInput
                      autoComplete='new-password'
                      type='password'
                      id='#password'
                      placeholder='Password'
                      name='password'
                      value={loginPassword}
                      onChange={(e) => {
                        setLoginPassword(e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={2}>
                  <Button
                    style={{ width: '100%', marginBottom: '0.5rem' }}
                    onClick={() => {
                      dispatch(
                        login({ email: loginEmail, password: loginPassword }),
                      );
                    }}
                    disabled={!loginPassword || !loginEmail}
                  >
                    Login
                </Button>
                </Col>
              </Row>
            </div>
          ) : (
              <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <Row style={{ marginTop: 30 }}>
                  <h6>ACCOUNT & BILLING DETAILS</h6>
                </Row>
                {!userDetails.role ? (
                  <Row form>
                    <Col xs={12} md={6}>
                      <Input
                        name='BillingEmail'
                        autoComplete='off'
                        placeholder='Your email...'
                        label='Email'
                        errors={errors.BillingEmail}
                        innerRef={register({
                          pattern: {
                            value: emailRegex,
                            message: 'Invalid email address'
                          },
                          validate: {
                            required: value => !!value || 'This field is required',
                          }
                        })}
                        onBlur={(e) => {
                          if (e.target.value) dispatch(checkIfAccountExists(e.target.value));
                          setLoginEmail(e.target.value);
                        }}
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <Input
                        name='BillingPassword'
                        autoComplete='new-password'
                        placeholder='Your password...'
                        type='password'
                        label='Password'
                        errors={errors.BillingPassword}
                        innerRef={register({
                          required: 'This field is required',
                          minLength: {
                            value: 6,
                            message: 'Your password should be at least 6 characters long',
                          },
                        })}
                      />
                    </Col>
                  </Row>
                ) : null}
                <Row form>
                  <Col xs={12} md={4}>
                    <Input
                      name='BillingFirstName'
                      placeholder='Your first name...'
                      label='First Name'
                      errors={errors.BillingFirstName}
                      innerRef={register({
                        required: 'This field is required',
                      })}
                    />
                  </Col>
                  <Col xs={12} md={4}>
                    <Input
                      name='BillingLastName'
                      placeholder='Your last name...'
                      label='Last Name'
                      errors={errors.BillingLastName}
                      innerRef={register({
                        required: 'This field is required',
                      })}
                    />
                  </Col>
                  <Col xs={12} md={4}>
                    <Input
                      label='Phone'
                      name='BillingPhoneNumber'
                      defaultValue='+(966)'
                      placeholder='+(966) XXX XXX XXXX'
                      errors={errors.BillingPhoneNumber ? {
                        message: 'Invalid phone format: +(966) XXX XXX XXXX'
                      } : null}
                      innerRef={register({
                        required: 'This field is required',
                        pattern: phoneRegex
                      })}
                    />
                  </Col>
                </Row>
                <Row form>
                  <Checkbox
                    name='IsTraveler'
                    label='I am one of the adult travellers'
                    innerRef={register}
                  />
                </Row>
                {isUserTraveler && process.env.REACT_APP_QUICK_CHECKOUT !== 'true' ? (
                  <>
                    <Row form>
                      <Col xs={12} md={6}>
                        <Select
                          name='UserGender'
                          options={[
                            {
                              value: '',
                              label: 'Select a gender...',
                            },
                            {
                              value: 'male',
                              label: 'Male',
                            },
                            {
                              value: 'female',
                              label: 'Female',
                            },
                          ]}
                          label='Your Gender'
                          errors={errors.UserGender}
                          innerRef={register({
                            required: 'This field is required',
                          })}
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <DateTimePicker
                          name='UserDateOfBirth'
                          placeholder='Pick a date...'
                          label='Your Date of Birth'
                          errors={errors.UserDateOfBirth}
                          innerRef={register({
                            required: 'This field is required',
                          })}
                          rules={{
                            validate: () => {
                              const value = watch('UserDateOfBirth');
                              if (!value instanceof moment) {
                                setValue(
                                  'UserDateOfBirth', null
                                );
                                return 'Invalid date';
                              }
                              if (!value) return 'This field is required';
                            }
                          }}
                          control={control}
                          dateOnly
                          readOnly
                        />
                      </Col>
                    </Row>
                    <Row form>
                      <Col xs={12} md={6}>
                        <Select
                          name='UserIdType'
                          options={[
                            { value: '', label: 'Select ID type' },
                            {
                              value: 'national_id_card',
                              label: 'National ID Card',
                            },
                            {
                              value: 'driving_licence',
                              label: 'Driving Licence',
                            },
                            { value: 'passport', label: 'Passport' },
                            { value: 'other', label: 'Other' },
                          ]}
                          label='ID Type'
                          errors={errors.UserIdType}
                          innerRef={register({
                            required: 'This field is required',
                          })}
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <Input
                          name='UserIdNumber'
                          placeholder='Enter ID number...'
                          label='ID Number'
                          errors={errors.UserIdNumber}
                          innerRef={register({
                            required: 'This field is required',
                          })}
                        />
                      </Col>
                    </Row>
                  </>
                ) : null}
                {checkoutDetails.adultTickets - (isUserTraveler ? 1 : 0) ? (
                  <>
                    <Row style={{ marginTop: 30 }}>
                      <h6>{`${isUserTraveler ? 'OTHER' : ''
                        } TRAVELLERS INFORMATION`}</h6>
                    </Row>
                    {[
                      ...Array(
                        checkoutDetails.adultTickets - (isUserTraveler ? 1 : 0),
                      ).keys(),
                    ].map((travelerIndex) => (
                      <>
                        <Row style={{ fontWeight: 600, marginTop: 20 }}>
                          ADULT TRAVELLER {travelerIndex + 1} DETAILS
                        </Row>
                        <Row form>
                          <Col xs={12} md={6}>
                            <Input
                              name={`Traveler${travelerIndex}Email`}
                              placeholder='Email address...'
                              label='Email'
                              errors={errors[`Traveler${travelerIndex}Email`]}
                              innerRef={register({
                                pattern: {
                                  value: emailRegex,
                                  message: 'Invalid email address'
                                },
                                validate: {
                                  required: value => !!value || 'This field is required',
                                  unique: value => {
                                    if (userDetails.emailAddress === value || value === watch('BillingEmail')) return 'Email already used';
                                    return (![
                                    ...Array(
                                      checkoutDetails.adultTickets - (isUserTraveler ? 1 : 0),
                                    ).keys(),
                                  ].filter(index => index !== travelerIndex && watch(`Traveler${index}Email`) === watch(`Traveler${travelerIndex}Email`)).length || 'Email already used');
                                }}
                              })}
                            />
                          </Col>
                          <Col xs={12} md={6}>
                            <Input
                              name={`Traveler${travelerIndex}PhoneNumber`}
                              label='Phone'
                              defaultValue='+(966)'
                              placeholder='+(966) XXX XXX XXXX'
                              errors={errors[`Traveler${travelerIndex}PhoneNumber`] ? {
                                message: 'Invalid phone format: +(966) XXX XXX XXXX'
                              } : null}
                              innerRef={register({
                                required: 'This field is required',
                                pattern: phoneRegex
                              })}
                            />
                          </Col>
                          <Col xs={12} md={6}>
                            <Input
                              name={`Traveler${travelerIndex}FirstName`}
                              placeholder='First name...'
                              label='First Name'
                              errors={
                                errors[`Traveler${travelerIndex}FirstName`]
                              }
                              innerRef={register({
                                required: 'This field is required',
                              })}
                            />
                          </Col>
                          <Col xs={12} md={6}>
                            <Input
                              name={`Traveler${travelerIndex}LastName`}
                              placeholder='Last name...'
                              label='Last Name'
                              errors={
                                errors[`Traveler${travelerIndex}LastName`]
                              }
                              innerRef={register({
                                required: 'This field is required',
                              })}
                            />
                          </Col>
                        </Row>
                        {process.env.REACT_APP_QUICK_CHECKOUT === 'true' ? null : (
                          <>
                            <Row form>
                              <Col xs={12} md={6}>
                                <Select
                                  name={`Traveler${travelerIndex}Gender`}
                                  options={[
                                    {
                                      value: '',
                                      label: 'Select a gender...',
                                    },
                                    {
                                      value: 'male',
                                      label: 'Male',
                                    },
                                    {
                                      value: 'female',
                                      label: 'Female',
                                    },
                                  ]}
                                  label='Gender'
                                  errors={errors[`Traveler${travelerIndex}Gender`]}
                                  innerRef={register({
                                    required: 'This field is required',
                                  })}
                                />
                              </Col>
                              <Col xs={12} md={6}>
                                <DateTimePicker
                                  name={`Traveler${travelerIndex}DateOfBirth`}
                                  placeholder='Pick a date...'
                                  label='Date of Birth'
                                  errors={
                                    errors[`Traveler${travelerIndex}DateOfBirth`]
                                  }
                                  innerRef={register({
                                    required: 'This field is required',
                                  })}
                                  rules={{
                                    validate: () => {
                                      const value = watch(`Traveler${travelerIndex}DateOfBirth`);
                                      if (!value instanceof moment) {
                                        setValue(
                                          `Traveler${travelerIndex}DateOfBirth`, null
                                        );
                                        return 'Invalid date';
                                      }
                                      if (!value) return 'This field is required';
                                    }
                                  }}
                                  control={control}
                                  dateOnly
                                  readOnly
                                />
                              </Col>
                            </Row>
                            <Row form>
                              <Col xs={12} md={6}>
                                <Select
                                  name={`Traveler${travelerIndex}IdType`}
                                  options={[
                                    { value: '', label: 'Select ID type' },
                                    {
                                      value: 'national_id_card',
                                      label: 'National ID Card',
                                    },
                                    {
                                      value: 'driving_licence',
                                      label: 'Driving Licence',
                                    },
                                    { value: 'passport', label: 'Passport' },
                                    { value: 'other', label: 'Other' },
                                  ]}
                                  label='ID Type'
                                  errors={errors[`Traveler${travelerIndex}IdType`]}
                                  innerRef={register({
                                    required: 'This field is required',
                                  })}
                                />
                              </Col>
                              <Col xs={12} md={6}>
                                <Input
                                  name={`Traveler${travelerIndex}IdNumber`}
                                  placeholder='Enter ID number...'
                                  label='ID Number'
                                  errors={
                                    errors[`Traveler${travelerIndex}IdNumber`]
                                  }
                                  innerRef={register({
                                    required: 'This field is required',
                                  })}
                                />
                              </Col>
                            </Row>
                          </>
                        )}
                      </>
                    ))}
                  </>
                ) : null}
                <Row style={{ marginTop: 20 }} form>
                  <Checkbox
                    name='legal'
                    errors={errors.legal}
                    label={<div>I read and agree to the <a href='https://bondai.io/privacy-policy' target='_blank' rel='noreferrer'>Privacy Policy</a>.</div>}
                    innerRef={register({
                      required: 'You should read and agree with the privacy policy before booking.'
                    })}
                  />
                </Row>
                <Button
                  type='submit'
                  style={{ marginTop: 30, marginBottom: 60 }}
                  disabled={isBookingSubmitting}
                >
                  {isBookingSubmitting
                    ? 'Submitting booking...'
                    : 'Submit booking'}
                </Button>
              </form>
            )}
        </Col>
      </Row>
    </Container>
    );
};
export default CheckoutPage;
