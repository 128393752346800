import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody
} from 'shards-react';
import { useForm } from 'react-hook-form';
import { FiEyeOff, FiEye } from 'react-icons/fi';
import { Textarea, Input, ImagePicker, MultiSelect } from '../../components/ui-kit';
import ControlledPlacesAutocomplete from '../../components/ControlledPlacesAutocomplete';

import { placesTypes, languages } from '../../helpers/constants';
import { updateProviderDetails } from '../auth/userEffects';

const ProfilePage = ({ history, match }) => {
  const dispatch = useDispatch();
  const details = useSelector((state) => state.user.details);
  const isUpdating = useSelector((state) => state.user.loading.updateProviderDetails === 'pending');

  const {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
  } = useForm({
    defaultValues: {
      ProfilePicture: {
        preview: null,
        raw: null,
      },
      TourProviderName: '',
      OperatingLicence: '',
      Description: '',
      LocationGooglePlaceId: '',
      SpokenLanguages: [],
      // PhoneNumber: '',
    },
  });

  useEffect(() => {
    if (details) {
      setValue([
        {
          ProfilePicture: {
            preview: details.profilePictureUrl,
            raw: null,
          }
        }, {
          TourProviderName: details.tourProviderName,
        }, {
          OperatingLicence: details.operatingLicence || '',
        }, {
          Description: details.description || '',
        }, {
          LocationGooglePlaceId: details.tourProviderLocationDto ? { label: details.tourProviderLocationDto.localityName, value: null } : { label: '', value: null }
        }
        /* {
          PhoneNumber: details.phoneNumber,
        } */
      ]);
      setSpokenLangs(details.spokenLanguages?.map(lang => ({ value: lang, label: languages[lang].name })) || []);
    }
  }, [details]);

  const [spokenLangs, setSpokenLangs] = useState([]);

  const onSubmit = (data, e) => {
    dispatch(updateProviderDetails({ ...data, SpokenLanguages: spokenLangs.map(lang => lang.value) }));
  };

  return (
    <Container className='ProfilePage'>
      <Row>
        <Col xs={12} lg={{ size: 10, offset: 1 }}>
          <Card>
            <CardBody style={{ display: 'flex', alignItems: 'center' }}>
              {details?.description && details?.profilePictureUrl
                ? <><div style={{ height: '100%' }}><FiEye style={{ color: 'green', width: 30, height: 30, marginRight: 20 }} /></div> Your experience provider profile is visible on your experiences' pages.</>
                : <><div style={{ height: '100%' }}><FiEyeOff style={{ color: 'red', width: 30, height: 30, marginRight: 20 }} /> </div> You need to provide at least your logo and a description for your profile to be visible on your experiences' pages.</>
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={{ size: 10, offset: 1 }}>
          <h1 className='title'>Edit profile</h1>
          <form onSubmit={handleSubmit(onSubmit)} className='profileEdit'>
            <div className='profileEdit__logo'>
              <ImagePicker
                name='ProfilePicture'
                control={control}
                label='Your logo'
                errors={errors.ProfilePicture}
              />
            </div>
            <div className='profileEdit__details'>
              <Input
                name='TourProviderName'
                placeholder='Your provider name...'
                label='Provider name'
                errors={errors.TourProviderName}
                innerRef={register({
                  required: 'This field is required',
                })}
              />
              <MultiSelect
                style={{ width: '100%', maxWidth: '100%' }}
                customStyles={{
                  control: {
                    borderRadius: '.375rem',
                    height: 41,
                    width: '100%',
                    borderColor: '#becad6',
                  },
                  container: {
                    maxWidth: '100%',
                  },
                }}
                label='Spoken languages'
                options={Object.keys(languages).map((code) => ({
                  value: code,
                  label: languages[code].name,
                }))}
                value={spokenLangs}
                onChange={(langs) => {
                  setSpokenLangs(langs || []);
                }}
              />
              <ControlledPlacesAutocomplete
                name='LocationGooglePlaceId'
                control={control}
                title='Location'
                initialValue={details.tourProviderLocationDto ? { label: details.tourProviderLocationDto.localityName, value: null } : { label: '', value: null }}
                types={[placesTypes.CITIES]}
                placeholder='search for location...'
                errors={errors.LocationGooglePlaceId}
              />
              <Input
                name='OperatingLicence'
                placeholder='Your operating licence...'
                label='Operating licence'
                errors={errors.OperatingLicence}
                innerRef={register}
              />
              <Textarea
                name='Description'
                placeholder='Your description...'
                label='Public description'
                errors={errors.Description}
                innerRef={register}
              />
            </div>
            <div className='profileEdit__submit'>
              <Button theme='dark' disabled={isUpdating}>{isUpdating ? 'Saving changes...' : 'Save changes'}</Button>
            </div>
          </form>
        </Col>
      </Row>
    </Container>
  );
};

export default ProfilePage;
