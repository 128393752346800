import React, { useEffect, useState, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { search } from './exploreEffects';
import { clearFilters, setFilters } from './exploreSlice';
import Loader from 'react-loader-spinner';
import SaudiMap from '../../assets/saudi-arabia.svg';
import {
  Container,
  Row,
  Col,
  FormInput,
  InputGroup,
  Button,
  Slider,
} from 'shards-react';
import {
  FaInstagram,
  FaYoutube,
  FaLinkedin,
  FaMedium,
  FaTwitter,
  FaFacebookSquare,
} from 'react-icons/fa';
import Header from '../../Header';
import ActivityCard from './ActivityCard';
import { MdSearch } from 'react-icons/md';

import SectionLoader from '../../components/SectionLoader';
import { MultiSelect } from '../../components/ui-kit';
import { fetchActivityTags, fetchDestinations } from '../auth/userEffects';
import history from '../../utils/history';
import InquiryForm from './InquiryForm';
import Filters from '../../components/filters/Filters';

const locations = {
  'umluj': {
    label: 'Umluj',
    value: 'ChIJtVtk-gqnsBURMZI4mIJksrk',
  },
  'abha': {
    label: 'Abha',
    value: 'ChIJMQlyFWNU4xURtBmJcYxVd6E',
  },
  'wajh': {
    label: 'Al Wajh',
    value: 'ChIJD1pVKvjQsRUR9NvtVX6gGsw',
  },
  'taif': {
    label: 'Taif',
    value: 'ChIJLz-jsMyO6RURp6IfNOHlP_o',
  },
  'al-ula': {
    label: 'Al Ula',
    value: 'ChIJGWgTqZarpRUR1oUYaViumwM',
  },
  'riyadh': {
    label: 'Riyadh',
    value: 'ChIJmZNIDYkDLz4R1Z_nmBxNl7o',
  },
  'tabuk': {
    label: 'Tabuk',
    value: 'ChIJZSMTlAWtqRURz5oTtsbjQG8',
  },
  'jeddah': {
    label: 'Jeddah',
    value: 'ChIJWX4TsR_QwxUR2xixN5dXWeA',
  },
};

const categories = {
  'by-the-sea': [
    '5efc725c49603716249de672',
    '5efc725349603716249de671',
    '5efc72c049603716249de67a',
    '5f4694086ff73639fc30f65c',
    '5efc72b949603716249de679',
    '60b746de3e7fbf148c3ef1f6',
  ],
  'through-the-mountains': [
    '5f4694ff6ff73639fc30f666',
    '5f46954a6ff73639fc30f66a',
    '5f46945b6ff73639fc30f65e',
    '60d0e6840788dd548ca265c6',
  ],
  'a-spiritual-journey': [
    '5fe09551afdfc326e431c4cc',
    '5fe0954bafdfc326e431c4cb',
    '5fe0953bafdfc326e431c4ca',
    '5f4695586ff73639fc30f66c',
  ],
  'discover-the-colors': [
    '5f4695506ff73639fc30f66b',
    '5f46952c6ff73639fc30f669',
    '5f4694886ff73639fc30f662',
    '5f4694d46ff73639fc30f665',
  ],
};

const ExplorePage = () => {
  const footerRef = useRef(null);
  const gridRef = useRef(null);
  const filtersRef = useRef(null);

  const dispatch = useDispatch();

  const activities = useSelector((state) =>
    state.explore.ids.map((id) => ({
      ...state.explore.entities[id],
    })),
  );
  const searchTerm = useSelector((state) => state.explore.search.term);

  const searchMeta = useSelector(
    (state) => state.explore.search.meta,
    shallowEqual,
  );
  const filters = useSelector(
    (state) => state.explore.search.filters,
    shallowEqual,
  );
  const isFiltered =
    filters.regionIds?.length ||
    filters.location?.value ||
    filters.tagIds?.length ||
    filters.minPrice !== 0 ||
    filters.maxPrice !== 10000;
  const isSearching = useSelector(
    (state) => state.explore.loading.search === 'pending',
  );

  const pendingSearch = useSelector(
    (state) => state.explore.loading.search === 'idle',
  );  

  const userRole = useSelector((state) => state.user.details.role);

  
  const scrollHandler = () => {
    if (!footerRef.current) return;
    const footerRect = footerRef.current.getBoundingClientRect();
    if (
      footerRect.top >= 0 &&
      Math.floor(footerRect.bottom) <=
      (window.innerHeight || document.documentElement.clientHeight) &&
      !isSearching &&
      searchMeta.remaining
    ) {
      dispatch(search({ searchTerm, filters, page: searchMeta.nextPage, scheduleFiltering: searchMeta.scheduleFiltering }));
    } else {
      if (!searchMeta.remaining && searchMeta.scheduleFiltering === 1 && !isSearching && !pendingSearch) {
        dispatch(search({ searchTerm, filters, page: 0, scheduleFiltering: 2 }));
      }
    }
  };

  const urlSearchParams = new URLSearchParams(window.location.search);
  const { location, category } = Object.fromEntries(urlSearchParams.entries());

  useEffect(() => {
    if ((location || category) && !isFiltered && pendingSearch) return;
    dispatch(search({ searchTerm, filters, scheduleFiltering: 1 }));
  }, [searchTerm, filters, isFiltered]);

  useEffect(() => {
    if (footerRef && !isSearching) scrollHandler();
  }, [footerRef, isSearching]);

  useEffect(() => {
    dispatch(fetchActivityTags());

    if (location) {
      dispatch(
        setFilters({
          filter: 'location',
          value: locations[location],
        }),
      );
    }
    
    if (category) {
      dispatch(
        setFilters({
          filter: 'tagIds',
          value: categories[category],
        }),
      );
    }
    return () => {
      dispatch(clearFilters());
    }

  }, []);

  useEffect(() => {
    if (!isSearching) {
      window.addEventListener('scroll', scrollHandler);
    }
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [searchMeta, isSearching]);

  return (
    <>
      <Container className='explorePage'>
        <Row
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 50
          }}
        >
          <div style={{ flex: 1, textAlign: 'center' }}>
            <div className='header'>
              <img src={SaudiMap} alt="Saudi Arabia" />
              <h1>Explore the Kingdom</h1>
              <Filters innerRef={filtersRef} />
            </div>
            {searchTerm ? (
              isSearching ? (
                <h5>
                  <Loader
                    type='Rings'
                    color='#000000'
                    height={50}
                    width={50}
                    style={{ display: 'inline' }}
                  />
                  {`Searching experiences for `}
                  <em>{searchTerm}</em>
                </h5>
              ) : (
                  <h5>
                    {`${searchMeta.total} experiences found searching for `}
                    <em>{searchTerm}</em>
                  </h5>
                )
            ) : null}
          </div>
        </Row>
        {/* <Row className='filters'>
          <Col xs={12}>
            <MultiSelect
              label='Regions'
              options={availableRegions.map((region) => ({
                value: region.id,
                label: region.name,
              }))}
              onChange={(regions) => {
                dispatch(
                  setFilters({
                    filter: 'regionIds',
                    value: regions ? regions.map((region) => region.value) : [],
                  }),
                );
              }}
            />
            <MultiSelect
              label='Interests'
              options={activityTags.map((tag) => ({
                value: tag.id,
                label: tag.name,
              }))}
              onChange={(tags) => {
                dispatch(
                  setFilters({
                    filter: 'tagIds',
                    value: tags ? tags.map((tag) => tag.value) : [],
                  }),
                );
              }}
            />
            <div className='slider'>
              <label>
                Budget: {priceRangeValues[0]} SAR - {priceRangeValues[1]} SAR
              </label>
              <Slider
                connect
                step={100}
                onSlide={(values) => {
                  setPriceRangeValues(values.map((value) => parseInt(value)));
                }}
                onChange={(values) => {
                  if (parseInt(values[0]) !== filters.minPrice) {
                    dispatch(
                      setFilters({
                        filter: 'minPrice',
                        value: parseInt(values[0]),
                      }),
                    );
                  } else {
                    dispatch(
                      setFilters({
                        filter: 'maxPrice',
                        value: parseInt(values[1]),
                      }),
                    );
                  }
                }}
                start={priceRangeValues}
                range={{ min: 0, max: 10000 }}
                className='multislider'
              />
            </div>
          </Col>
        </Row> */}

        {activities && activities.length ? (
          <Row>
            <Col sm={12} style={{ padding: 0, marginTop: 30 }}>
              <div ref={gridRef} className='grid-layout'>
                {searchTerm || isFiltered
                  ? activities.map((activity, index) => (
                    <div
                      className={`grid-item grid-item-3`}
                      key={activity.shortId}
                    >
                      <ActivityCard
                        location={activity.destination}
                        price={
                          activity.defaultPrices[0]?.priceAmount || activity.startingFromPrices[0]?.priceAmount
                            ? `${activity.defaultPrices[0]?.priceAmount || activity.startingFromPrices[0].priceAmount} SAR`
                            : null
                        }
                        shortId={activity.shortId}
                        slug={activity.slug}
                        id={activity.id}
                        title={activity.activityTitle}
                        avatar={activity.avatarUrl}
                        duration={activity.defaultDurationInDays}
                        ratingValue={parseFloat(activity.globalRating.toFixed(2))}
                        ratingCount={activity.ratingCount < 2 && activity.globalRating < 3 ? 0 : activity.ratingCount}
                      />
                    </div>
                  ))
                  : activities.map((activity, index) => (
                    <div
                      className={`grid-item grid-item-3 ${!index ? 'featured' : ''
                        }`}
                      key={activity.shortId}
                    >
                      <ActivityCard
                        location={activity.destination}
                        price={
                          activity.defaultPrices[0]?.priceAmount || activity.startingFromPrices[0]?.priceAmount
                            ? `${activity.defaultPrices[0]?.priceAmount || activity.startingFromPrices[0].priceAmount} SAR`
                            : null
                        }
                        isFeatured={!index}
                        shortId={activity.shortId}
                        slug={activity.slug}
                        id={activity.id}
                        title={activity.activityTitle}
                        avatar={activity.avatarUrl}
                        userRole={userRole || null}
                        duration={activity.defaultDurationInDays}
                        ratingValue={parseFloat(activity.globalRating.toFixed(2))}
                        ratingCount={activity.ratingCount < 2 && activity.globalRating < 3 ? 0 : activity.ratingCount}
                      />
                    </div>
                  ))}
              </div>
            </Col>
          </Row>
        ) : isSearching ? (
          <SectionLoader label='Fetching available experiences...' />
        ) : (
          <h5>No results found matching your filters...</h5>
        )}
      </Container>
      <div ref={footerRef} style={{ minHeight: 60 }}>
        {!searchMeta.remaining ? null : (
          <Loader
            type='Rings'
            color='#000000'
            height={80}
            width={80}
            style={{ textAlign: 'center' }}
          />
        )}
      </div>
    </>
  );
};

const styles = {
  socialIcons: { width: 28, height: 28, margin: 10 },
};

export default ExplorePage;
