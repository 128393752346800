import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Container, Row, Button } from "shards-react";
import history from "../../utils/history";
import PageLoader from "../../components/PageLoader";
import Header from "../../Header";
import { paymentStatus, paymentStatusLabel } from "../../helpers/constants";
import BookingsAPI from "../../api/BookingsAPI";
import moment from "moment";

const PaymentStatusPage = (props) => {
  const [bookingDetails, setBookingDetails] = useState(null);
  const userRole = useSelector((state) => state.user.details.role);

  useEffect(() => {
    (async () => {
      if (!bookingDetails) {
        try {
          const bookingDetailsData =
            await BookingsAPI.fetchPaymentDetailsByBookingId(
              props.match.params.id
            );
          setBookingDetails(bookingDetailsData.data.value);
        } catch (err) {
          history.push(`/payment-status/${props.match.params.id}`);
        }
      }
    })();
  }, [bookingDetails]);

  return !bookingDetails ? (
    <PageLoader label="Fetching your payment status..." />
  ) : (
    <Container
      style={{ flex: 1, paddingTop: 100, paddingBottom: 60, color: "black" }}
    >
      <Row
        style={{
          marginTop: 30,
        }}
      >
        <h1
          style={{
            display: "inline",
            lineHeight: 1,
            verticalAlign: "bottom",
            fontWeight: 900,
            fontSize: 60,
            letterSpacing: 1.25,
            color: "black",
          }}
        >
          {paymentStatus[bookingDetails.paymentDetails.paymentStatus] ||
            paymentStatusLabel.FAILED}
        </h1>
      </Row>
      <Row>
        {!paymentStatus[bookingDetails.paymentDetails.paymentStatus] ? (
          <p
            style={{ color: "gray" }}
          >{`Status: ${bookingDetails.paymentDetails.paymentStatus}`}</p>
        ) : null}
        <p>
          {paymentStatus[bookingDetails.paymentDetails.paymentStatus] ===
          paymentStatusLabel.PAID
            ? "Your payment was successfully registered. Thank you!"
            : null}
          {paymentStatus[bookingDetails.paymentDetails.paymentStatus] ===
          paymentStatusLabel.REFUNDED
            ? "Your payment was successfully refunded."
            : null}
          {paymentStatus[bookingDetails.paymentDetails.paymentStatus] ===
          paymentStatusLabel.FAILED
            ? `
            Something went wrong and your payment could not be processed. We suggest you try again later. If the problem persists, please email us at hi@bondai.io quoting your confirmation number.`
            : null}
        </p>
      </Row>
      <Row>
        {userRole &&
        [paymentStatusLabel.UNPAID, paymentStatusLabel.FAILED].includes(
          paymentStatus[bookingDetails.paymentDetails.paymentStatus]
        ) ? (
          <Button
            theme="danger"
            onClick={() => {
              history.push(`/payment-gateway/${props.match.params.id}`);
            }}
          >
            {paymentStatus[bookingDetails.paymentDetails.paymentStatus] ===
            paymentStatusLabel.UNPAID
              ? "Proceed to payment"
              : "Retry payment"}
          </Button>
        ) : null}
      </Row>
      {paymentStatus[bookingDetails.paymentDetails.paymentStatus] ===
      paymentStatusLabel.UNPAID ? null : (
        <Row>
          <div className="transactionDetails">
            <div className="title">Transaction details</div>
            <div className="info">
              Confirmation number: <em>{bookingDetails.confirmationNumber}</em>
            </div>{" "}
            <div className="info">
              Amount: <em>{bookingDetails.paymentDetails.payFortAmount} SAR</em>
            </div>
            <div className="info">
              Status:{" "}
              <em>{bookingDetails.paymentDetails.payFortTransactionStatus}</em>
            </div>
            <div className="info">
              Date & Time:{" "}
              <em>
                {moment(
                  bookingDetails.paymentDetails.transactionDateTime
                ).format("LLL")}
              </em>
            </div>
          </div>
        </Row>
      )}
    </Container>
  );
};
export default PaymentStatusPage;
