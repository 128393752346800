import React, { useRef, useEffect, useState } from "react";
import PaymentsAPI from "../../api/PaymentsAPI";
import PageLoader from "../../components/PageLoader";
import history from "../../utils/history";
import { capitalize } from "../../helpers/functions";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
const PaymentGatewayPage = (props) => {
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const { search } = useLocation();
  const values = queryString.parse(search);

  useEffect(() => {
    (async () => {
      if (props.match.params.token) {
        try {
          const paymentRequestData =
            props.match.params.token.length > 24
              ? await PaymentsAPI.fetchEmailPaymentRequestPayfort(
                  props.match.params.token
                )
              : await PaymentsAPI.fetchPaymentRequestPayfort(
                  props.match.params.token
                );
          setPaymentRequest(paymentRequestData.data.value);
        } catch (err) {
          history.push(`/payment-status/${props.match.params.token}}`);
        }
      }
    })();
  }, [props.match.params.token]);

  const paymentFormRef = useRef(null);

  useEffect(() => {
    if (paymentRequest && !isRedirecting) {
      setIsRedirecting(true);
      paymentFormRef.current.submit();
    }
  }, [paymentRequest, isRedirecting]);

  return (
    <div>
      {paymentRequest || isRedirecting ? (
        <div>
          <form
            ref={paymentFormRef}
            method="post"
            action={paymentRequest.redirectUrl}
            id="form1"
            name="form1"
          >
            <input
              type="hidden"
              name="signature"
              value={paymentRequest.data["signature"]}
            />
            <input
              type="hidden"
              name="currency"
              value={paymentRequest.data["currency"]}
            />
            <input
              type="hidden"
              name="command"
              value={paymentRequest.data["command"]}
            />
            <input
              type="hidden"
              name="merchant_reference"
              value={paymentRequest.data["merchant_reference"]}
            />
            <input
              type="hidden"
              name="amount"
              value={paymentRequest.data["amount"]}
            />
            <input
              type="hidden"
              name="access_code"
              value={paymentRequest.data["access_code"]}
            />
            <input
              type="hidden"
              name="merchant_identifier"
              value={paymentRequest.data["merchant_identifier"]}
            />
            <input
              type="hidden"
              name="language"
              value={paymentRequest.data["language"]}
            />
            <input
              type="hidden"
              name="customer_email"
              value={paymentRequest.data["customer_email"]}
            />
            <input
              type="hidden"
              name="customer_name"
              value={paymentRequest.data["customer_name"]}
            />
            <input
              type="hidden"
              name="return_url"
              value={paymentRequest.data["return_url"]}
            />
          </form>
          <PageLoader label="Processing your payment request..." />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
export default PaymentGatewayPage;
