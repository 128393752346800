import React from 'react';
import Select from 'react-select';

const MultiSelect = ({
  options,
  label,
  innerRef,
  style,
  onChange,
  className,
  value,
  customStyles = {},
}) => {
  return (
    <div className={className} style={style}>
      {!label ? null : (
        <label>{label}</label>
      )}
      <Select
        className='multiselect'
        options={options}
        value={value}
        ref={innerRef}
        styles={{
          multiValue: (styles, {}) => ({
            ...styles,
            backgroundColor: '#f7414e',
            color: '#fff',
            borderRadius: 16,
          }),
          container: (styles, {}) => ({
            ...styles,
            ...customStyles.container,
          }),
          multiValueLabel: (styles, {}) => ({
            ...styles,
            color: '#fff',
          }),
          multiValueRemove: (styles, { isHovered }) => ({
            ...styles,
            borderRadius: 16,
          }),
          menu: (styles, {}) => ({
            ...styles,
            zIndex: 999,
          }),
          control: (styles, { isFocused, isHovered }) => ({
            ...styles,
            backgroundColor: 'white',
            border: `1px solid ${isFocused ? '#f7414e' : '#dfdede'}`,
            borderRadius: 16,
            borderColor:
              isHovered || isFocused ? '#f7414e !important' : '#dfdede',
            boxShadow: `0 0 1px ${isFocused ? '#f7414e' : '#dfdede'}`,
            minHeight: 46,
            outlineColor: '#f7414e',
            ...customStyles.control,
          }),
          option: (styles, { isHovered, isSelected }) => {
            return {
              ...styles,
              //   backgroundColor: isHovered ? '#FFBDAD' : 'white',
              /*color: '#FFF',
              cursor: isDisabled ? 'not-allowed' : 'default', */
            };
          },
        }}
        onChange={onChange}
        isMulti
      />
    </div>
  );
};

export default MultiSelect;
