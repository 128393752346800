import React, { useState } from 'react';
import { Editor, RichUtils } from 'draft-js';
import { Button, ButtonGroup, ButtonToolbar } from 'shards-react';
import {
  MdFormatBold,
  MdFormatItalic,
  MdFormatUnderlined,
  MdFormatStrikethrough,
  MdFormatListBulleted,
  MdFormatListNumbered,
  MdFormatClear,
} from 'react-icons/md';
import 'draft-js/dist/Draft.css';
import './TextEditor.css';
import { supportedLangs as langs } from '../helpers/constants';

const TextEditor = ({
  placeholder,
  content,
  title,
  onChange,
  readOnly,
  defaultLang = null,
  supportedLangs = langs,
}) => {
  const [selectedLang, setSelectedLang] = useState(
    defaultLang || (supportedLangs.length ? supportedLangs[0].value : null),
  );
  const toggleInlineStyle = (e, style) => {
    e.preventDefault();
    onChange(
      selectedLang,
      RichUtils.toggleInlineStyle(content[selectedLang], style),
    );
  };

  const toggleBlockType = (e, blockType) => {
    e.preventDefault();
    onChange(
      selectedLang,
      RichUtils.toggleBlockType(content[selectedLang], blockType),
    );
  };

  const removeBlockStyle = (e) => {
    e.preventDefault();
    try {
      const newState = RichUtils.tryToRemoveBlockStyle(content[selectedLang]);
    } catch (err) {
      console.log(err);
    }
    // onChange();
  };

  const handleKeyCommand = (command, content) => {
    const newState = RichUtils.handleKeyCommand(content[selectedLang], command);

    if (newState) {
      onChange(selectedLang, newState);
    }
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
        }}
      >
        {title ? <label>{title}</label> : null}
        {readOnly && !selectedLang ? null : (
          <ButtonToolbar className='lang-toolbar'>
            <ButtonGroup size='sm'>
              {supportedLangs.map((lang) => (
                <Button
                  theme='light'
                  active={selectedLang === lang.value}
                  onClick={() => {
                    setSelectedLang(lang.value);
                  }}
                >
                  {lang.label}
                </Button>
              ))}
            </ButtonGroup>
          </ButtonToolbar>
        )}
      </div>
      <Editor
        editorState={content[selectedLang]}
        // handleKeyCommand={handleKeyCommand}
        onChange={(changedContent) => {
          onChange(selectedLang, changedContent);
        }}
        placeholder={placeholder}
        customStyleMap={{}}
        readOnly={readOnly}
        blockStyleFn={(contentBlock) => contentBlock.getType()}
      />
      {readOnly ? null : (
        <ButtonToolbar className='editor-toolbar'>
          <ButtonGroup size='sm'>
            <Button
              theme='light'
              onClick={(e) => toggleBlockType(e, 'header-three')}
            >
              Title
            </Button>
            <Button
              theme='light'
              onClick={(e) => toggleBlockType(e, 'header-four')}
            >
              Subtitle
            </Button>
            <Button
              theme='light'
              onClick={(e) => toggleBlockType(e, 'paragraph')}
            >
              Paragraph
            </Button>
            <Button theme='light' onClick={(e) => toggleInlineStyle(e, 'BOLD')}>
              <MdFormatBold style={styles.toolbarItem} />
            </Button>
            <Button
              theme='light'
              onClick={(e) => toggleInlineStyle(e, 'ITALIC')}
            >
              <MdFormatItalic style={styles.toolbarItem} />
            </Button>
            <Button
              theme='light'
              onClick={(e) => toggleInlineStyle(e, 'UNDERLINE')}
            >
              <MdFormatUnderlined style={styles.toolbarItem} />
            </Button>
            <Button
              theme='light'
              onClick={(e) => toggleInlineStyle(e, 'STRIKETHROUGH')}
            >
              <MdFormatStrikethrough style={styles.toolbarItem} />
            </Button>
            <Button
              theme='light'
              onClick={(e) => toggleBlockType(e, 'unordered-list-item')}
            >
              <MdFormatListBulleted style={styles.toolbarItem} />
            </Button>
            <Button
              theme='light'
              onClick={(e) => toggleBlockType(e, 'ordered-list-item')}
            >
              <MdFormatListNumbered style={styles.toolbarItem} />
            </Button>
            {/* <Button theme='light' onClick={removeBlockStyle}>
            <MdFormatClear style={styles.toolbarItem} />
          </Button> */}
          </ButtonGroup>
        </ButtonToolbar>
      )}
    </div>
  );
};

const styles = {
  toolbarItem: {
    width: 20,
    height: 20,
  },
};

export default TextEditor;
