import React, { useLayoutEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Row, Col, Button } from 'shards-react';
import { HiOutlineEmojiSad } from 'react-icons/hi';
import Loader from 'react-loader-spinner';
import axios from 'axios';

import ReviewsAPI from '../../api/ReviewsAPI';
import history from '../../utils/history';

import PageLoader from '../../components/PageLoader';
import { Textarea } from '../../components/ui-kit';
import ControlledRate from '../../components/ControlledRate';
import ControlledYesNo from '../../components/ControlledYesNo';

import BondaiLogoWhite from '../../assets/images/logo_white.png';
import BondaiLogo from '../../assets/images/logo.png';

const ReviewPage = (props) => {
  const [details, setDetails] = useState('loading');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState(null);

  const {
    register,
    handleSubmit,
    errors,
    control,
    getValues,
  } = useForm({
    defaultValues: {
      publicFeedback: '',
      privateFeedback: '',
      recommended: null,
    },
  });

  const submitRating = async (token) => {
    try {
      const response = await ReviewsAPI.validateReviewRequest(token.slice(0, -1), parseInt(token.slice(-1)));
      setDetails(response?.data?.value || null);
    } catch(err) {
      setDetails(null);
      if (axios.isCancel(err)) {
        return;
      }
      if (![400, 404].includes(err.response.data.statusCode)) {
        setError(`Oups, something went wrong: ${err.response.data.statusCode} ${err.response.data.notificationMessage}.`)
      }
    }
  }

  useLayoutEffect(() => {
    const token = props.match.params.token;
    const reviewStorageToken = localStorage.getItem('review');
    window.addEventListener("storage", (storage) => {
      if (storage.key === 'review' && storage.newValue === token.slice(0, -1)) {
        setError(`A review form has been opened for you in another tab.
        There you can adjust your selected rating, leave your public review or a private feedback.`);
        ReviewsAPI.cancelValidateRequest();
        return;
      }
    });
    if (reviewStorageToken && reviewStorageToken === token.slice(0, -1)) {
      setDetails(null);
      return;
    }
    localStorage.setItem('review', token.slice(0, -1));
    setTimeout(() => { submitRating(token); }, 1000);
  }, []);

  const onSubmit = async (data) => {
    if (isSubmitting) return;
    try {
      setIsSubmitting(true);
      setError(null);
      await ReviewsAPI.updateReview(details.reviewId, data);
      setIsSubmitted(true);
    } catch({ response }) {
      setError(`Oups, something went wrong: ${response.data.statusCode} ${response.data.notificationMessage}.`)
    } finally {
      setIsSubmitting(false);
    }
  };

  return details === 'loading' ? (
    <PageLoader label='Fetching experience details...' />
    ) : ( details ? (
      <div className='reviewPage'>
        <div className='photo' style={{backgroundImage: `url('${details.activityAvatarUrl}')`}}>
        </div>
        {isSubmitted ? (
          <div className='content'>
            <span className='title'>Thank you, {details.userFirstName}!</span>
            <span className='rating'>Your review has been successfully submitted.</span>
            <Button className='exploreBtn' theme='dark' onClick={() => {
              history.push('/explore');
            }}>Explore other experiences</Button>
          </div>
        ) : (
        <div className='content'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={`container`}>
                <div className='rating'>How would your rate your experience <br/>with <strong>{details.tourProviderName}</strong>?</div>
                <Row form>
                  <Col>
                    <ControlledRate
                      name='rating'
                      control={control}
                      errors={errors.rating}
                      defaultValue={details.rating}
                      rules={{
                        validate: () =>
                          getValues('rating')
                            ? undefined
                            : 'Please select a rating',
                      }}
                      />
                  </Col>
                </Row>
              </div>
              <div className={`container recommended`}>
                <div className='question'>Would your recommend <strong>{details.tourProviderName}</strong> to others?</div>
                <Row form>
                  <ControlledYesNo
                    name='recommended'
                    control={control}
                  />
                </Row>
              </div>
              <div className={`container`}>
                <div className='question'>Write a public feedback others can read
                </div>
                <Row form>
                  <Textarea
                    name='publicFeedback'
                    placeholder='Add your public review'
                    innerRef={register}
                  />
                </Row>
              </div>
              <div className='container'>
                <div className='question'>Write a private feedback only <strong>{details.tourProviderName}</strong> can read
                </div>
                <Row form>
                <Textarea
                  name='privateFeedback'
                  placeholder='Add a private feedback'
                  innerRef={register}
                />
                </Row>
              </div>
              <Row form>
                {error ? <span className='error'><HiOutlineEmojiSad /> {error}</span> : null}
              </Row>
              <Button theme='dark'>{isSubmitting ? <div style={{display: 'flex', alignItems: 'center'}}>
                <Loader
                  type='TailSpin'
                  width={20}
                  height={20}
                  color='white'
                  style={{ marginRight: 15 }}
                /> Submitting your review...
              </div> : 'Submit your review'}</Button>
            </form>
          </div>
        )}
      </div>
    ) : (
      <div className='errorPage'>
        <span>{error || 'Sorry, this link has expired...'} {!error ? <HiOutlineEmojiSad /> : null}</span>
      </div>
    )
  );
};

export default ReviewPage;