import API, { authenticate } from '../utils/API';

const fetchPaymentRequestPayfort = (bookingId) =>
    API.post(`/payments/payfort/set-up-redirect-payment/${bookingId}`, {}, { ...authenticate() });


const fetchEmailPaymentRequestPayfort = (bookingId) =>
  API.post(`/payments/payfort/email-set-up-redirect-payment/${bookingId}`, {});

export default {
  fetchPaymentRequestPayfort,
  fetchEmailPaymentRequestPayfort
};
