import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Row, Col, Button, Slider } from 'shards-react';
import { Input, Stepper, Textarea } from '../../components/ui-kit';
import moment from 'moment';
import { handleActivityInquiry } from '../auth/userEffects';
import { emailRegex, phoneRegex } from '../../helpers/validations';
import history from '../../utils/history';

import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import { useDispatch, useSelector } from 'react-redux';

import MultipleDatePicker from '../../components/MultipleDatePicker';
import ControlledPlacesAutocomplete from '../../components/ControlledPlacesAutocomplete';

const InquiryForm = (props) => {
  const dispatch = useDispatch();
  const [budgetRange, setBudgetRange] = useState([100, 8500]);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    errors,
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      emailAddress: '',
      phoneNumber: '+(966)',
      groupSize: 1,
      description: '',
      preferredDates: [],
      googlePlaceIds: [],
      other: '',
    },
  });
  const onSubmit = (data) => {
    dispatch(
      handleActivityInquiry({
        ...data,
        googlePlaceIds: data.googlePlaceIds.map((location) => location.value),
        preferredDates: data.preferredDates.map((date) =>
          moment.utc(date, 'DD/MM/YYYY').format(),
        ),
        minPrice: budgetRange[0],
        maxPrice: budgetRange[1],
      }),
    );
  };

  const isSubmitting = useSelector(
    (state) => state.user.loading.handleActivityInquiry === 'pending',
  );

  const isSubmittedSuccessfully = useSelector(
    (state) => state.user.loading.handleActivityInquiry === 'done',
  );

  useEffect(() => {
    if (isSubmittedSuccessfully) {
      setBudgetRange([100, 8500]);
      reset();
      if (history.location.pathname === '/inquiry') {
        history.push('/explore');
      }
    }
  }, [isSubmittedSuccessfully]);

  return (
    <div className='inquiry-form'>
      <Row>
        <Col xs={12}>
          <h2>Create Your Adventure</h2>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12}>
          <div className='description'>
            Fill in the information below and we'll put together the perfect
            itinerary for yourself and your group. Our Bondai experience providers
            will be in touch with you shortly via email & whatsapp so you can
            customize your experience.
          </div>
        </Col>
        <Col xs={12} style={{ marginTop: 10 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col xs={12} lg={6} style={{ padding: '0 20px' }}>
                <Row form>
                  <Col xs={12} md={6}>
                    <Input
                      label='First name'
                      name='firstName'
                      placeholder='First name*'
                      errors={errors.firstName}
                      innerRef={register({
                        required: 'This field is required',
                      })}
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <Input
                      label='Last name'
                      name='lastName'
                      placeholder='Last name*'
                      errors={errors.lastName}
                      innerRef={register({
                        required: 'This field is required',
                      })}
                    />
                  </Col>
                </Row>
                <Row form>
                  <Col xs={12} md={6}>
                    <Input
                      label='Email address'
                      name='emailAddress'
                      placeholder='Email address*'
                      errors={errors.emailAddress ? {
                        message: 'Please add a valid email address'
                      } : null}
                      innerRef={register({
                        required: true,
                        pattern: emailRegex,
                      })}
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <Input
                      label='Phone number'
                      name='phoneNumber'
                      defaultValue='+(966)'
                      placeholder='+(966) XXX XXX XXXX'
                      errors={errors.phoneNumber ? {
                        message: 'Please add a valid phone number'
                      } : null}
                      innerRef={register({
                        pattern: phoneRegex
                      })}
                    />
                  </Col>
                </Row>
                <Row form>
                  <Col xs={12} md={8}>
                    <label>
                      Budget per person: {budgetRange[0]} SAR - {budgetRange[1]}{' '}
                      SAR
                    </label>
                    <Slider
                      connect
                      step={100}
                      onSlide={(values) => {
                        setBudgetRange(values.map((value) => parseInt(value)));
                      }}
                      start={budgetRange}
                      range={{ min: 100, max: 8500 }}
                      className='multislider'
                    />
                  </Col>
                  <Col xs={12} md={4} className='stepper'>
                    <label>Group size</label>
                    <Stepper
                      name='groupSize'
                      min={1}
                      controls={{ register, setValue, watch }}
                    />
                  </Col>
                </Row>
                <Row form>
                  <Col xs={12}>
                    <MultipleDatePicker
                      name='preferredDates'
                      placeholder='please select your preferred dates...'
                      label='Preferred dates to start your trip'
                      control={control}
                      errors={errors.preferredDates}
                      rules={{
                        validate: () =>
                          getValues('preferredDates').length
                            ? undefined
                            : 'Please select at least one date',
                      }}
                      dateOnly
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={12} lg={6} style={{ padding: '0 20px' }}>
                <Row form>
                  <Col xs={12}>
                    <ControlledPlacesAutocomplete
                      name='googlePlaceIds'
                      control={control}
                      rules={{
                        validate: () =>
                          getValues('googlePlaceIds').length
                            ? undefined
                            : 'Please select at least one location',
                      }}
                      multiple
                      title='Preferred locations'
                      placeholder='search for locations...'
                      errors={errors.googlePlaceIds}
                    />
                  </Col>
                </Row>
                <Row form>
                  <Col xs={12}>
                    <Textarea
                      name='description'
                      placeholder='e.g. hiking, swimming...'
                      label='What sort of experience are you interested in?'
                      errors={errors.description}
                      innerRef={register({
                        required: 'This field is required',
                      })}
                    />
                  </Col>
                </Row>
                <Row form>
                  <Col xs={12}>
                    <Textarea
                      name='other'
                      placeholder='e.g. dietary requirements, travelling with children...'
                      label='Any other special requests?'
                      innerRef={register}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row form className='bondai-custom'>
              <Button
                theme='primary'
                style={{ margin: '30px auto 0' }}
                disabled={isSubmitting}
              >
                {isSubmitting
                  ? 'Sending your inquiry...'
                  : 'Send us your inquiry'}
              </Button>
            </Row>
          </form>
        </Col>
      </Row>
    </div>
  );
};

export default InquiryForm;
