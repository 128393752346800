import React from 'react';
import {
  Container,
  Row,
  Col,
} from 'shards-react';
const FaqsPage = () => (
  <Container style={{ flex: 1, paddingTop: 100, paddingBottom: 60, color: 'black' }}>
    <Row>
      <Col>
        <h1
          style={{
            display: 'inline',
            lineHeight: 1,
            verticalAlign: 'bottom',
            fontWeight: 900,
            fontSize: 60,
            letterSpacing: 1.25,
            color: '#000',
          }}
        >
          FAQs
        </h1>
      </Col>
    </Row>
    <Row>
      <Col>
        <h3
          style={{
            fontSize: 32,
            letterSpacing: 1.25,
            color: '#f7414e',
          }}
        >
          Your questions shape our future
        </h3>
      </Col>
    </Row>
    <Row style={{ marginTop: 30 }}>
      <Col>
        <h4>What is Bondai?</h4>
      </Col>
    </Row>
    <Row>
      <Col>
        <p style={{ fontSize: 20 }}>Bondai is a collaborative and fun app to help you plan & book trips with friends and family. Bondai will help troubleshoot planning issues users face when having to book a group trip.
It’s faster and less stressful than flipping between numerous apps to plan one trip. It combines the best features of WhatsApp, Expedia and Splitwise where groups can collaborate to build their itinerary, share recommendations and book accommodations & flights while also keeping track of their expenses.</p>
      </Col>
    </Row>
    <Row style={{ marginTop: 30 }}>
      <Col>
        <h4>What does Bondai even mean?</h4>
      </Col>
    </Row>
    <Row>
      <Col>
        <p style={{ fontSize: 20 }}>Bondai is the unspoken bond you create with traveling with friends. Our favorite part of group trips is having breakfast together and laughing about what happened the night before… we can’t wait to hear what your favorite moments are!</p>
      </Col>
    </Row>
    <Row style={{ marginTop: 30 }}>
      <Col>
        <h4>Is it free to use?</h4>
      </Col>
    </Row>
    <Row>
      <Col>
        <p style={{ fontSize: 20 }}>Yes. Infact, using Bondai to book your group trip is cheaper than any other way! We have partnered with the best companies to ensure competitive prices for you and your crew!</p>
      </Col>
    </Row>
  </Container>
);

export default FaqsPage;

