import { addDays, addWeeks, eachDayOfInterval, eachWeekOfInterval, format, fromUnixTime, getDay } from 'date-fns';
import React, { useState, useEffect } from 'react';
import { FiMapPin, FiChevronRight } from 'react-icons/fi';
import history from '../../utils/history';

const Calendar = ({ startDate, endDate, currentDate, data }) => {
  return (
    <div className="calendar">
      <div className="header">
        <div className="weekDays">
          {eachDayOfInterval({
            start: fromUnixTime(startDate),
            end: addDays(fromUnixTime(startDate), 6),
          }).map((day) => (
            <div className="name">{format(day, 'EEEE')}</div>
          ))}
        </div>
      </div>
      {eachWeekOfInterval({
        start: fromUnixTime(startDate),
        end: fromUnixTime(endDate),
      }, {
        weekStartsOn: getDay(fromUnixTime(startDate)),
      }).map((startOfWeek, index) => (
        <div className={`weekRow ${!index ? 'isFirst' : ''}`}>
          {eachDayOfInterval({
            start: startOfWeek,
            end: addDays(startOfWeek, 6),
          }).map((day, index, days) => {
            const timestamp = format(day, 't');
            const experiences = data[timestamp]?.experiences;
            const locations = data[timestamp]?.locations.join(', ');
            return (
              <div className={`dayCell ${experiences ? '' : 'isEmpty'}`} onClick={() => {
                history.push(`/calendar/${format(day, 'yyyy-MM-dd')}`);
              }}>
                <div
                  className={`dayWrapper ${!index ? 'isFirst' : ''} ${index === days.length - 1 ? 'isLast' : ''}`}
                  // style={{ ...experiences ? { backgroundImage: `url(${experiences[Math.floor(Math.random() * experiences.length)].avatarUrl})` } : {}}}
                >
                  <div className="dateLabel">
                    {format(day, 'd')}
                    <span>{format(day, 'MMM')}</span>
                  </div>
                  {/* <div className="monthLabel"></div> */}
                  <div className="details">
                    <div className="count">
                      {experiences ? `${experiences.length} experience${experiences.length > 1 ? 's' : ''}` : 'No experiences available'}
                    </div>
                    {experiences ? (
                      <div className="locations">
                        <FiMapPin className="icon" />
                        <span>{locations}</span>
                      </div>
                    ) : null}
                  </div>
                  {experiences ? (
                    <div className="chevronRight">
                      <FiChevronRight  style={{ width: '100%', height: '100%' }} />
                    </div>
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
}

export default Calendar;