import React from 'react';
import history from '../../utils/history';
import { FiMapPin, FiUsers } from 'react-icons/fi';

const ActivityCard = ({
  shortId,
  slug,
  avatar,
  title,
  location = null,
  prices,
  availability,
  date,
}) => (
  <div
    onClick={() => {
      history.push(slug ? `/experience/${slug}?date=${date}` : `/explore/${shortId}?date=${date}`);
    }}
    className={`activityCard`}
  >
    <div className='activityCard__photoWrapper'>
      <div
        className='activityCard__photo'
        style={{
          backgroundImage: `url(${avatar}480)`,
        }}
      >
        <div className='activityCard__photoSpacer'></div>
      </div>
      <div className='activityCard__duration'>
        <FiUsers className='activityCard__durationIcon' />
        {`${availability} space${availability > 1 ? 's' : ''} left`}
      </div>
    </div>
    <div className='activityCard__details'>
      <div className='activityCard__pricing'>
        starting from
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className='activityCard__pricingAmount'>
            <span style={{ marginLeft: 0 }}>{prices[0].priceAmount} SAR</span> / adult
            {/* {prices[1] ? (
              <div><span>{prices[1].priceAmount} SAR</span> / child</div>
            ) : null} */}
          </div>
        </div>
      </div>
      <div className='activityCard__title'>{title}</div>
      <div className='activityCard__location'>
        <FiMapPin className='activityCard__locationIcon' />
        {location
          ? `${location.localityName ? `${location.localityName}, ` : ''} ${
              location.regionName || location.countryDestinationName
            }`
          : 'Saudi Arabia'}
      </div>
    </div>
  </div>
);

export default ActivityCard;
