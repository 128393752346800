import React from 'react';
import { Editor } from 'draft-js';
import { EditorState, convertFromRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';
import './TextEditor.css';

const EditorRenderer = ({ content }) => {
  const editorState = EditorState.createWithContent(convertFromRaw(content));
  return editorState.getCurrentContent().hasText() ?
    (
      <Editor
        editorState={editorState}
        readOnly={true}
        blockStyleFn={(contentBlock) => contentBlock.getType()}
      />
    ) : null;
}

export default EditorRenderer;
