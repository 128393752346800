import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { debounce } from "lodash";
import {
  FiMenu,
  FiSearch,
  FiX,
  FiNavigation,
  FiUser,
  FiMapPin,
  FiBriefcase,
  FiTag,
  FiBookmark,
  FiLogOut,
} from "react-icons/fi";

import { usePosition } from "../helpers/hooks";
import { suggestionType, suggestionIcon } from "../helpers/constants";
import { fetchSearchSuggestions } from "../features/search/searchEffects";
import BondaiLogoBlack from "../assets/images/logo.png";
import BondaiLogoWhite from "../assets/images/logo-white.svg";
import Header from "../Header";
import history from "../utils/history";
import { logout } from "../features/auth/userSlice";

import BondaiLogo from "../assets/images/banner/logo.png";
import WhatsappIcon from "../assets/images/banner/whatsapp-orange.svg";
import BondaiWord from "../assets/images/banner/bondai.png";
import BondaiPortalLogo from "../assets/images/banner/bondaiportal-logo.svg";
import B2bLogo from "../assets/images/banner/b2b-logo.svg";
import "./navbar.scss";
import axios from "axios";
import {
  Collapse,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  Navbar,
  NavbarBrand,
  NavbarToggler,
} from "shards-react";
import { MdContentCopy } from "react-icons/md";
import { BiTrophy } from "react-icons/bi";
import { NavLink } from "react-router-dom";

const Navbar1 = ({
  isSticky = true,
  isSearchVisible = false,
  isNavbarSearchTriggered = false,
  onNavbarSearchDismiss,
}) => {
  const searchInputRef = useRef();
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [isNavigating, setIsNavigating] = useState(false);
  const [showBanner, setShowBanner] = useState(false);

  const [isUserMenuVisible, setIsUserMenuVisible] = useState(false);
  const [userMenus] = useState([
    {
      title: "My Profile",
      icon: <FiUser size={22} />,
      link: "https://travel.bondai.io/profile/dashboard.xhtml",
    },
    {
      title: "Bookings",
      icon: <FiBookmark size={22} />,
      link: "https://travel.bondai.io/profile/dashboard.xhtml?selectedSection=BOOKINGS",
    },
    {
      title: "My Idea",
      icon: <MdContentCopy size={22} />,
      link: "https://travel.bondai.io/profile/dashboard.xhtml?selectedSection=IDEAS",
    },
    {
      title: "My Purchase",
      icon: <MdContentCopy size={22} />,
      link: "https://travel.bondai.io/profile/dashboard.xhtml?selectedSection=TEMPORAL_IDEAS",
    },
    {
      title: "My Holidays",
      icon: <MdContentCopy size={22} />,
      link: "https://travel.bondai.io/profile/dashboard.xhtml?selectedSection=HOLIDAY_PACKAGES",
    },
    {
      title: "My Data",
      icon: <MdContentCopy size={22} />,
      link: "https://travel.bondai.io/profile/dashboard.xhtml?selectedSection=DATA",
    },
    {
      title: "Rewards",
      icon: <BiTrophy size={22} />,
      link: "https://travel.bondai.io/profile/dashboard.xhtml?selectedSection=REWARDS_STATEMENT",
    },
  ]);

  /* const { latitude, longitude, error } = usePosition();
  console.log(latitude);
  console.log(longitude);
  console.log(error); */

  useEffect(() => {
    if (isNavbarSearchTriggered) {
      setIsSearching(true);
      searchInputRef.current.focus();
    }
  }, [isNavbarSearchTriggered]);

  useEffect(async () => {
    if (searchValue && isSearching)
      dispatch(fetchSearchSuggestions(searchValue));
    await fetchSettings();
  }, [searchValue]);

  const suggestions = useSelector((state) => state.search.suggestions);

  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [collapseOpen, setCollapseOpen] = useState(false);

  const toggleNavbar = () => {
    setCollapseOpen(!collapseOpen);
  };

  const fetchSettings = async () => {
    axios.defaults.baseURL = "https://bondai-api-prod.azurewebsites.net/api";
    const { data } = await axios.get("/utilities/banner");
    if (data.value) {
      setShowBanner(true);
    } else {
      setShowBanner(false);
    }
  };

  const whatsappHandlar = () => {
    let isMobileFunc = function () {
      let check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    };
    let isMobile = isMobileFunc();
    if (isMobile) {
      window.open("https://wa.me/+966555074410");
    } else {
      window.open("https://web.whatsapp.com/send?phone=+966555074410");
    }
  };

  const user = useSelector((state) => state.user.details);
  const isLanding = history.location.pathname === "/";
  const isTourProvider = user.role === "tourProviderAdmin";
  const shouldDisplayCheckout = useSelector(
    (state) =>
      state.bookings.checkout &&
      state.bookings.checkout.activity &&
      !isTourProvider &&
      history.location.pathname !== "/checkout"
  );

  return (
    <>
      <div className="new-banner__whatsapp" onClick={() => whatsappHandlar()}>
        <img src={WhatsappIcon} alt='whatsapp'/>
      </div>
      <Navbar type="dark" expand="md">
        <div className="navbar__content">
          <div className="navbar__header">
            <NavbarBrand href="https://bondai.io/">
              <div
                className="navbar__logo"
                style={{
                  display: "flex",
                  "align-items": "center",
                  cursor: "pointer",
                }}
              >
                <img
                  alt="Bondai"
                  style={{ width: "90px" }}
                  src={BondaiLogoWhite}
                />
              </div>
            </NavbarBrand>
            <NavbarToggler onClick={() => toggleNavbar()} />
          </div>

          <Collapse open={collapseOpen} navbar>
            <Nav navbar>
              <Dropdown
                open={isMenuVisible}
                toggle={() => setIsMenuVisible(!isMenuVisible)}
              >
                <DropdownToggle nav caret>
                  <div className="navbar__user">Bondai Holding</div>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() => {
                      window.open("https://b2bondai.com/", "_self");
                    }}
                  >
                    <div className="navbar__links__dropdown-item">
                      <img width="30px" src={B2bLogo} />
                      <div>
                        <span>B2Bondai.com</span>
                        <span>Travel Business Solutions</span>
                      </div>
                    </div>
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      window.open("https://bondaiportal.com/", "_self");
                    }}
                  >
                    <div className="navbar__links__dropdown-item">
                      <img width="30px" src={BondaiPortalLogo} />
                      <div>
                        <span>BondaiPortal</span>
                        <span>Easy Travel System Solutions</span>
                      </div>
                    </div>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              <NavItem>
                <span
                  onClick={() =>
                    window.open("https://bondai.io/create-your-trip", "_self")
                  }
                  className="navbar__links__link"
                >
                  Create Trip
                </span>
              </NavItem>
              <NavItem>
                <span
                  onClick={() =>
                    window.open("https://bondai.io/explore", "_self")
                  }
                  className="navbar__links__link"
                >
                  Explore
                </span>
              </NavItem>
              <NavItem>
                <span
                  onClick={() => history.push("/calendar")}
                  className="navbar__links__link"
                >
                  Group Trip Calendar
                </span>
              </NavItem>

              {user.role ? (
                <Dropdown
                  open={isUserMenuVisible}
                  toggle={() => setIsUserMenuVisible(!isUserMenuVisible)}
                >
                  <DropdownToggle nav caret>
                    <div className="navbar__user">
                      Hi{" "}
                      {user.tourProviderName ||
                        `${user.firstName} ${user.lastName}`}
                      !
                    </div>
                  </DropdownToggle>
                  <DropdownMenu>
                    {userMenus.map((link) => {
                      return (
                        <DropdownItem
                          onClick={() => {
                            window.open(link.link, "_self");
                          }}
                        >
                          <div className="navbar__links__dropdown-item">
                            <div style={{ marginRight: "12px" }}>
                              {link.icon}
                            </div>
                            <span>{link.title}</span>
                          </div>
                        </DropdownItem>
                      );
                    })}
                    <DropdownItem
                      onClick={() => {
                        dispatch(logout());
                        history.push("/");
                        setIsNavigating(false);
                      }}
                    >
                      <div className="navbar__links__dropdown-item">
                        <div style={{ marginRight: "12px" }}>
                          <FiLogOut size={24} />
                        </div>

                        <span style={{ fontSize: "16px", fontWeight: "500" }}>
                          Logout
                        </span>
                      </div>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              ) : (
                <span
                  className="navbar__links__link navbar__links__btn"
                  onClick={() => history.push("/login")}
                >
                  Login
                </span>
              )}
            </Nav>
          </Collapse>
        </div>
      </Navbar>
    </>
  );
};

export default Navbar1;
