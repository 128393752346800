import React, { useState } from 'react';
import {
  Button,
  InputGroup,
  InputGroupAddon,
  FormInput,
  FormGroup,
} from 'shards-react';
import { FiPlus, FiMinus } from 'react-icons/fi';

const Stepper = (props) => {
  return (
    <FormGroup>
      {props.label && <label>{props.label}</label>}
      <InputGroup size='sm'>
        <InputGroupAddon type='prepend'>
          <Button
            theme='light'
            style={{ height: 35 }}
            disabled={+props.controls.watch(props.name) - 1 < props.min}
            onClick={() => {
              props.controls.setValue(
                props.name,
                +props.controls.watch(props.name) - 1,
              );
            }}
          >
            <FiMinus style={{ height: 18, width: 18 }} />
          </Button>
        </InputGroupAddon>
        <FormInput
          style={{
            height: 35,
            borderColor: '#e9ecef',
            width: 'auto',
            textAlign: 'center',
          }}
          defaultValue={0}
          name={props.name}
          innerRef={props.controls.register}
        />
        <InputGroupAddon type='append' style={{ height: 35 }}>
          <Button
            theme='light'
            style={{ height: 35 }}
            disabled={+props.controls.watch(props.name) + 1 > props.max}
            onClick={() => {
              props.controls.setValue(
                props.name,
                +props.controls.watch(props.name) + 1,
              );
            }}
          >
            <FiPlus style={{ height: 18, width: 18 }} />
          </Button>
        </InputGroupAddon>
      </InputGroup>
    </FormGroup>
  );
};

export default Stepper;
