import React from 'react';
import { Controller } from 'react-hook-form';
import {FormRadio} from 'shards-react';

const ControlledYesNo = (props) => (
  <Controller as={<YesNo/>} {...props} />
);

const YesNo = (props) => (
  <>
    <FormRadio
      inline
      name="YesNo"
      checked={props.checked === true}
      value={true}
      onChange={() => {props.onChange(true);}}
      >
      Yes
    </FormRadio>
    <FormRadio
      inline
      name="YesNo"
      checked={props.checked === false}
      value={false}
      onChange={() => {props.onChange(false);}}
    >
      No
    </FormRadio>
    {props.errors ? <span className='error'>{props.errors.message}</span> : null}
  </>
);

export default ControlledYesNo;
