import React, { useState } from 'react';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import { Collapse } from 'shards-react';

const ActivityDetailsSection = ({ collapsed = false, children, title }) => {
  const [isCollapsed, setIsCollapsed] = useState(collapsed);
  return (
    <div className='activity__detailsSection'>
      <Collapse open={!isCollapsed}>{children}</Collapse>
      <div
        className='activity__detailsHeader'
        onClick={() => {
          setIsCollapsed(!isCollapsed);
        }}
      >
        <h3 className='activity__detailsTitle'>{title}</h3>
        <div className='activity__detailsHeaderIcon'>
          {isCollapsed ? <FiChevronDown /> : <FiChevronUp />}
        </div>
      </div>
    </div>
  );
};

export default ActivityDetailsSection;
