import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Badge,
  Button,
} from 'shards-react';
import history from '../../utils/history';
import Header from '../../Header';
import moment from 'moment';
import { fetchBookings, cancelBookingById } from './bookingsEffect';
import { showModal } from '../notifications/notificationsSlice';
import SectionLoader from '../../components/SectionLoader';
import { paymentStatus, paymentStatusLabel, bookingStatus } from '../../helpers/constants';

const BookingsPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchBookings());
  }, []);

  const upcomingBookings = useSelector((state) =>
    state.bookings.ids.reduce(
      (bookings, bookingId) => [
        ...bookings,
        ...(moment(
          state.bookings.entities[bookingId].bookedActivityDto.schedule
            .startDateTime,
        ).isSameOrAfter(moment()) &&
          state.bookings.entities[bookingId].bookingStatus.status < 2
          ? [state.bookings.entities[bookingId]]
          : []),
      ],
      [],
    ),
  );

  const pastBookings = useSelector((state) =>
    state.bookings.ids.reduce(
      (bookings, bookingId) => [
        ...bookings,
        ...(moment(
          state.bookings.entities[bookingId].bookedActivityDto.schedule
            .startDateTime,
        ).isBefore(moment()) ||
          state.bookings.entities[bookingId].bookingStatus.status > 1
          ? [state.bookings.entities[bookingId]]
          : []),
      ],
      [],
    ),
  );

  const isFetchingBookings = useSelector(
    (state) => state.bookings.loading.fetchBookings === 'pending',
  );

  return (
    <Container style={{ flex: 1, paddingTop: 100, paddingBottom: 60, color: 'black' }}>
      <Row
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 30,
        }}
      >
        <h1
          style={{
            display: 'inline',
            lineHeight: 1,
            verticalAlign: 'bottom',
            fontWeight: 900,
            fontSize: 60,
            letterSpacing: 1.25,
            color: '#000',
            marginLeft: 15,
          }}
        >
          Your bookings
        </h1>
      </Row>
      {isFetchingBookings ? (
        <SectionLoader label='Fetching your bookings...' />
      ) : !upcomingBookings?.length && !pastBookings?.length ? (
        <div>
          <p>You haven't done any booking just yet.</p>
          <p>
            Keep exploring{' '}
            <div
              className='filterLink'
              style={{ display: 'inline' }}
              onClick={() => {
                history.push('/explore');
              }}
            >
              here
            </div>{' '}
            what the Kingdom has to offer and book your first experience with
            Bondai!
          </p>
        </div>
      ) : null}
      {upcomingBookings && upcomingBookings.length ? (
        <>
          <Row style={{ marginTop: 20 }}>
            <h6>UPCOMING BOOKINGS ({upcomingBookings.length})</h6>
          </Row>
          {upcomingBookings.map((booking) => (
            <Row>
              <Col xs={12}>
                <Card style={{ marginBottom: 20 }}>
                  <CardBody style={{ padding: 0 }}>
                    <Row noGutters>
                      <Col xs={12} md={2}>
                        <div
                          style={{
                            backgroundImage: `url(${booking.bookedActivityDto.avatarUrl})`,
                            backgroundSize: 'cover',
                            width: '100%',
                            height: '100%',
                            borderRadius: '.625rem 0 0 .625rem',
                          }}
                        />
                      </Col>
                      <Col xs={12} md={10} style={{ padding: '20px 40px' }}>
                        <Row
                          style={{
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <div
                            className='checkoutActivityTitle'
                            onClick={() => {
                              history.push(
                                `/experience/${booking.bookedActivityDto.slug}`,
                              );
                            }}
                          >
                            {booking.bookedActivityDto.activityTitle}
                          </div>
                          <div>
                            <Badge
                              onClick={() => {
                                history.push(`/payment-status/${booking.id}`);
                              }}
                              theme={
                                [paymentStatusLabel.UNPAID, paymentStatusLabel.REFUNDED].includes(paymentStatus[booking.paymentDetails.paymentStatus])
                                  ? 'warning'
                                  : paymentStatus[booking.paymentDetails.paymentStatus] === paymentStatusLabel.PAID
                                    ? 'success'
                                    : 'danger'
                              }
                              style={{ textTransform: 'uppercase' }}
                            >
                              {paymentStatus[booking.paymentDetails.paymentStatus] || paymentStatusLabel.FAILED}
                            </Badge>
                          </div>
                        </Row>
                        <Row>
                          <Col xs={12} sm={6} md={3}>
                            <span style={{ display: 'block' }}>Date</span>
                            <span style={{ fontSize: 16, fontWeight: 600 }}>
                              {moment
                                .parseZone(
                                  booking.bookedActivityDto.schedule
                                    .startDateTime,
                                )
                                .format('LL')}
                            </span>
                          </Col>
                          <Col xs={12} sm={6} md={3}>
                            <span style={{ display: 'block' }}>Tickets</span>
                            <span style={{ fontSize: 16, fontWeight: 600 }}>
                              {booking.bookedEntities.bookedTickets.map(
                                (ticket) => (
                                  <div>{`${ticket.count} * ${ticket.label}`}</div>
                                ),
                              )}
                            </span>
                          </Col>
                          {booking.bookedEntities.bookedPaidExtras.length ? (
                            <Col xs={12} sm={6} md={3}>
                              <span style={{ display: 'block' }}>Extras</span>
                              <span style={{ fontSize: 16, fontWeight: 600 }}>
                                {booking.bookedEntities.bookedPaidExtras.map(
                                  (extra) => (
                                    <div>{`${extra.count} * ${extra.label}`}</div>
                                  ),
                                )}
                              </span>
                            </Col>
                          ) : null}
                          <Col
                            xs={12}
                            sm={6}
                            n
                            md={
                              booking.bookedEntities.bookedPaidExtras.length
                                ? 3
                                : 6
                            }
                            style={{ textAlign: 'right', paddingRight: 0 }}
                          >
                            <span style={{ display: 'block' }}>
                              Total price
                            </span>
                            <span style={{ fontSize: 16, fontWeight: 600 }}>
                              {`${booking.bookedEntities.totalAmount} ${booking.bookedEntities.currency.id}`}
                            </span>
                          </Col>
                        </Row>
                        <Row style={{ marginTop: 10 }}>
                          <Col xs={12} sm={4}>
                            <span style={{ display: 'block' }}>
                              Booking ref.
                            </span>
                            <span style={{ fontSize: 16, fontWeight: 600 }}>
                              {booking.confirmationNumber}
                            </span>
                          </Col>
                          <Col
                            xs={12}
                            sm={8}
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              alignItems: 'center',
                              padding: 0,
                            }}
                          >
                            <Button
                              theme='light'
                              style={{ marginBottom: 0 }} //TODO disable button while cancelling
                              onClick={() => {
                                if (paymentStatus[booking.paymentDetails.paymentStatus] === paymentStatusLabel.PAID) {
                                  dispatch(
                                    showModal({
                                      message: `If you want to cancel this booking please reach us by email at hi@bondai.io and make sure to include the following reference number to ease the process: ${booking.confirmationNumber}.`,
                                      dismissLabel: 'Close',
                                    }),
                                  );
                                } else {
                                  dispatch(cancelBookingById(booking.id));
                                }
                              }}
                            >
                              Cancel booking
                            </Button>
                            {!booking.bookingStatus.status || [paymentStatusLabel.PAID, paymentStatusLabel.REFUNDED].includes(paymentStatus[booking.paymentDetails.paymentStatus]) ? null : (
                              <Button
                                style={{ marginLeft: 10, marginBottom: 0 }}
                                theme='danger'
                                onClick={() => {
                                  history.push(
                                    `/payment-gateway/${booking.id}`,
                                  );
                                }}
                              >
                                {paymentStatus[booking.paymentDetails.paymentStatus] === paymentStatusLabel.UNPAID
                                  ? 'Proceed to payment'
                                  : 'Retry payment'}
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ))}
        </>
      ) : null}

      {pastBookings && pastBookings.length ? (
        <>
          <Row style={{ marginTop: 20 }}>
            <h6>PAST BOOKINGS ({pastBookings.length})</h6>
          </Row>
          {pastBookings.map((booking) => (
            <Row>
              <Col xs={12}>
                <Card style={{ marginBottom: 20 }}>
                  <CardBody style={{ padding: 0 }}>
                    <Row noGutters>
                      <Col xs={12} md={2}>
                        <div
                          style={{
                            backgroundImage: `url(${booking.bookedActivityDto.avatarUrl})`,
                            backgroundSize: 'cover',
                            width: '100%',
                            height: '100%',
                            borderRadius: '.625rem 0 0 .625rem',
                          }}
                        />
                      </Col>
                      <Col xs={12} md={10} style={{ padding: '20px 40px' }}>
                        <Row
                          style={{
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <div
                            className='checkoutActivityTitle'
                            onClick={() => {
                              history.push(
                                `/explore/${booking.bookedActivityDto.shortId}`,
                              );
                            }}
                          >
                            {booking.bookedActivityDto.activityTitle}
                          </div>
                        </Row>
                        <Row>
                          <Col xs={12} md={4}>
                            <span style={{ display: 'block' }}>Date</span>
                            <span style={{ fontSize: 16, fontWeight: 600 }}>
                              {moment
                                .parseZone(
                                  booking.bookedActivityDto.schedule
                                    .startDateTime,
                                )
                                .format('LL')}
                            </span>
                          </Col>
                          <Col xs={12} sm={4}>
                            <span style={{ display: 'block' }}>
                              Booking ref.
                            </span>
                            <span style={{ fontSize: 16, fontWeight: 600 }}>
                              {booking.confirmationNumber}
                            </span>
                          </Col>
                          <Col xs={12} sm={4}>
                            <span style={{ display: 'block' }}>Status</span>
                            <span style={{ fontSize: 16, fontWeight: 600 }}>
                              {bookingStatus[booking.bookingStatus.status]}
                            </span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ))}
        </>
      ) : null}
    </Container>
  );
};
export default BookingsPage;
