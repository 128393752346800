import React, { useMemo } from 'react';
import ActivityPhoto from './ActivityPhoto';
import { Row, Progress } from 'shards-react';
import Dropzone from 'react-dropzone-uploader';
import { MdAddAPhoto } from 'react-icons/md';

const Layout = ({
  input,
  previews,
  submitButton,
  dropzoneProps,
  files,
  invalid,
  cover = '',
  onChangeCover,
  onRemove,
  photos = [],
}) => {
  let dropzonesCount = 0;
  if (photos.length < 16)
    dropzonesCount = photos.length > 2 ? 1 : 3 - photos.length;

  return (
    <div>
      {photos.map((photo, index) => (
        <ExistingPreview
          cover={cover}
          index={index}
          previewUrlSource={photo.path}
          onChangeCover={onChangeCover}
          onRemove={onRemove}
        />
      ))}
      {/* {previews} */}
      {[...Array(dropzonesCount).keys()].map((index) => (
        <div
          style={{
            width: '25%',
            position: 'relative',
            float: 'left',
            padding: 5,
          }}
        >
          <div
            style={{
              display: 'block',
              paddingBottom: '100%',
              position: 'relative',
              borderRadius: '.625rem',
              overflow: 'hidden',
              backgroundColor: 'white',
              border: '1px solid',
              borderColor: invalid ? '#c4183c' : '#becad6',
              boxShadow: invalid ? '0 5px 11.5px rgba(196,24,60,.1)' : 'none',
            }}
          >
            <MdAddAPhoto
              style={{
                height: 28,
                width: 28,
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: -14,
                marginLeft: -14,
              }}
              color={invalid ? '#c4183c' : '#becad6'}
            />

            <div
              {...dropzoneProps}
              style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
              }}
            >
              {files.length < 16 && input}
            </div>
          </div>
        </div>
      ))}
      {[...Array(16 - dropzonesCount - photos.length).keys()].map((index) => (
        <div
          style={{
            width: '25%',
            position: 'relative',
            float: 'left',
            padding: 5,
          }}
        >
          <div
            style={{
              display: 'block',
              paddingBottom: '100%',
              position: 'relative',
              borderRadius: '.625rem',
              overflow: 'hidden',
              background: 'white',
              border: '1px dashed #becad6',
            }}
          />
        </div>
      ))}
    </div>
  );
};

const ExistingPreview = ({
  previewUrlSource,
  cover = null,
  onChangeCover,
  onRemove,
  ...props
}) => {
  return (
    <div
      style={{
        width: '25%',
        position: 'relative',
        float: 'left',
        padding: 5,
      }}
    >
      <div
        style={{
          display: 'block',
          paddingBottom: '100%',
          position: 'relative',
          borderRadius: '.625rem',
          overflow: 'hidden',
          border:
            previewUrlSource === cover
              ? '1px solid #f7414e'
              : '1px solid #f5f6f6',
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          backgroundImage: 'url(' + previewUrlSource + ')',
        }}
        className='photoPreview'
      >
        {previewUrlSource !== cover ? (
          <div className='photoControls' style={{ position: 'absolute' }}>
            <div
              onClick={() => onRemove(previewUrlSource)}
              className='photoAction'
            >
              remove
            </div>

            <div
              onClick={() => {
                console.log('onChangeCover');
                onChangeCover(previewUrlSource);
              }}
              className='photoAction'
            >
              set as cover
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
const Preview = (props) => {
  const { percent, status, previewUrl } = props.meta;
  return (
    <div
      style={{
        width: '25%',
        position: 'relative',
        float: 'left',
        padding: 5,
      }}
    >
      <div
        style={{
          display: 'block',
          paddingBottom: '100%',
          position: 'relative',
          borderRadius: '.625rem',
          overflow: 'hidden',
          border:
            previewUrl === props.cover
              ? '1px solid #f7414e'
              : '1px solid #f5f6f6',
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          backgroundImage:
            status === 'done' ? 'url(' + previewUrl + ')' : 'none',
        }}
        className='photoPreview'
      >
        {status === 'done' && previewUrl !== props.cover ? (
          <div className='photoControls' style={{ position: 'absolute' }}>
            <div
              onClick={() => {
                props.fileWithMeta.remove();
              }}
              className='photoAction'
            >
              remove
            </div>

            <div
              onClick={() => props.onChangeCover(previewUrl)}
              className='photoAction'
            >
              set as cover
            </div>
          </div>
        ) : null}

        <Progress
          theme='light'
          value={percent}
          style={{
            position: 'absolute',
            bottom: '10%',
            left: '10%',
            zIndex: 2,
            width: '80%',
            opacity: status === 'done' ? 0 : 1,
            transition: 'opacity 0.3s linear 0.1s',
          }}
        />
      </div>
    </div>
  );
};

const ActivityPhotosHandler = ({
  onSelect,
  onRemove,
  onChangeCover,
  cover,
  invalid,
  photos = [],
}) => {
  const CustomLayout = (props) => (
    <Layout
      {...props}
      photos={photos}
      onChangeCover={onChangeCover}
      onRemove={onRemove}
      cover={cover}
      invalid={invalid}
    />
  );

  return (
    <Dropzone
      LayoutComponent={CustomLayout}
      accept=".png, .jpg, .jpeg"
      inputContent=''
      inputWithFilesContent=''
      canRemove
      onChangeStatus={(photo) => {
        if (photo.meta.status === 'done' && photos.length < 16) {
          onSelect({
            file: photo.file,
            path: photo.meta.previewUrl,
          });
        }
        if (photo.meta.status === 'removed') {
          onRemove(photo.meta.previewUrl);
        }
      }}
    />
  );
};

export default ActivityPhotosHandler;
