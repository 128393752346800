import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchExperiencesByTagIds } from './exploreEffects';
import {
  Container,
  Row,
  Col,
} from 'shards-react';
import ActivityCard from './ActivityCard';

import SectionLoader from '../../components/SectionLoader';

const JeddahPage = () => {
  const gridRef = useRef(null);

  const dispatch = useDispatch();

  const activities = useSelector((state) =>
    state.explore.ids.map((id) => ({
      ...state.explore.entities[id],
    })),
  );
  
  const isFetching = useSelector(
    (state) => state.explore.loading.search !== 'idle',
  );

  useEffect(() => {
    dispatch(fetchExperiencesByTagIds([
      process.env.REACT_APP_JEDDAH,
    ]));
  }, []);

  return (
    <Container className='explorePage' style={{
      paddingBottom: 280,
    }}>
      <Row
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: 15,
        }}
      >
          <h1>Explore Jeddah boats & yachts experiences</h1>
      </Row>
      {activities && activities.length ? (
        <Row>
          <Col sm={12} style={{ padding: 0, marginTop: 30 }}>
            <div ref={gridRef} className='grid-layout'>
              {activities.map((activity, index) => (
                <div
                  className={`grid-item grid-item-3`}
                  key={activity.shortId}
                >
                  <ActivityCard
                    location={activity.destination}
                    price={
                      activity.defaultPrices[0]?.priceAmount || activity.startingFromPrices[0]?.priceAmount
                        ? `${activity.defaultPrices[0]?.priceAmount || activity.startingFromPrices[0].priceAmount} SAR`
                        : null
                    }
                    shortId={activity.shortId}
                    slug={activity.slug}
                    id={activity.id}
                    title={activity.activityTitle}
                    avatar={activity.avatarUrl}
                    duration={activity.defaultDurationInDays}
                    ratingValue={parseFloat(activity.globalRating.toFixed(2))}
                    ratingCount={activity.ratingCount < 2 && activity.globalRating < 3 ? 0 : activity.ratingCount}
                  />
                </div>
              ))}
            </div>
          </Col>
        </Row>
      ) : isFetching ? (
        <SectionLoader label='Fetching available experiences...' color='white' />
      ) : (
        <h5>Nothing to see here just yet!</h5>
      )
    }
    </Container>
  );
};

export default JeddahPage;
