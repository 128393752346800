import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFeaturedArticles } from '../features/blog/blogEffects';
import SectionLoader from './SectionLoader';
import moment from 'moment';
import { BsCalendar2, BsPerson } from 'react-icons/bs';
import { FiArrowRight } from 'react-icons/fi';
import { Remark } from 'react-remark';
import strip from 'strip-markdown';

const blog_url = process.env.REACT_APP_BLOG_URL;

const BlogSection = () => {
  const dispatch = useDispatch();
  const featuredArticles = useSelector((state) => state.blog.featuredArticles);
  const isFetching = useSelector(
    (state) => state.blog.loading.article === 'pending'
  );
  const isFailed = useSelector(
    (state) => state.blog.loading.article === 'failed'
  );
  useEffect(() => {
    dispatch(fetchFeaturedArticles());
  }, []);
  const main = featuredArticles?.length
    ? featuredArticles[0]?.attributes
    : undefined;
  const articles = featuredArticles?.slice(1);
  let image;
  if (main && main.slider) {
    image = main.slider.images.data[0].attributes;
    image = image.formats.large ?? image;
  }
    
  const author = main?.author.data?.attributes;

  const InnerSection = ({ data }) => {
    let image;
    if (data && data.slider) {
      image = data.slider.images.data[0].attributes;
      image = image.formats.small ?? image;
    }
    
    const title_map = data.title;
    const content_map = data.content;
    const author_name = data.author.data?.attributes.name;
    const design = (
      <>
        <div className="side-article-container">
          <a href={blog_url + 'article/' + data.slug}>
            <div className="d-flex w-100">
              <div
                className={
                  'd-flex p-3 w-100' +
                  (data.locale === 'ar' ? ' arabic-side' : '')
                }
              >
                <div>
                  <img
                    src={image?.url}
                    alt={image?.alt}
                    className="side-article-image"
                  />
                </div>
                <div className="ml-3 w-100">
                  <h5>{title_map}</h5>
                  <div className="side-article-details">
                    <div className="author-container">
                      <BsPerson />
                      <span className="main-author">
                        {author_name ?? 'Bondai'}
                      </span>
                    </div>
                    <div className="article-date-contatiner">
                      <BsCalendar2 />
                      <span className="main-date">
                        {moment(data.publishedAt).format('DD MMMM YYYY')}
                      </span>
                    </div>
                  </div>
                  <p className="side-article-description">
                    <Remark remarkPlugins={[strip]}>
                      {content_map.substr(0, 500)}
                    </Remark>
                  </p>
                </div>
              </div>
              <div
                className="side-article-arrow"
                style={{ background: 'black' }}
              >
                <FiArrowRight />
              </div>
            </div>
          </a>
        </div>
      </>
    );
    return design;
  };

  return (
    <div>
      {isFetching ? (
        <div>
          <SectionLoader label="Fetching articles..." />
        </div>
      ) : !isFailed && main && articles?.length ? (
        <div className="blog-section-container">
          <div className="blog-heading-container">
            <div className="sectionTitle">Bondai Stories</div>
            <a href={blog_url} className='btn btn-pill btn-black see-more-stories'>See More Stories <FiArrowRight/></a>
          </div>
          <div className="row">
            <div className="col-lg-5">
              <div
                className={
                  'main-article' + (main.locale === 'ar' ? ' arabic-main' : '')
                }
              >
                <a href={blog_url + 'article/' + main.slug}>
                  <img className="blog-image" src={image?.url} alt={image?.alt} />
                  <div className="article-details-custom">
                    <div className="author-container">
                      <BsPerson />
                      <span className="main-author">
                        {author?.name ?? 'Bondai'}
                      </span>
                    </div>
                    <div className="article-date-contatiner">
                      <BsCalendar2 />
                      <span className="main-date">
                        {moment(main.publishedAt).format('DD MMMM YYYY')}
                      </span>
                    </div>
                  </div>
                  <div className="main-description-container">
                    <h4>{main.title}</h4>
                    <p className="main-description">
                      <Remark remarkPlugins={[strip]}>
                        {main.content.substr(0, 1000)}
                      </Remark>
                    </p>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-7 side-articles">
              {articles.map((item, index) => {
                return <InnerSection key={index} data={item?.attributes} />;
              })}
            </div>
          </div>
          <div>
            <a className="btn btn-dark all-articles" href={blog_url}>
              See More Stories
            </a>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default BlogSection;
