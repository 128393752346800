import React from 'react';
import { Container, Row } from 'shards-react';

const PaymentGatewayErrorPage = () => (
  <Container style={{ flex: 1, paddingTop: 100, paddingBottom: 60, color: 'black' }}>
    <Row
      style={{
        marginTop: 30,
      }}
    >
      <h1
        style={{
          display: 'inline',
          lineHeight: 1,
          verticalAlign: 'bottom',
          fontWeight: 900,
          fontSize: 60,
          letterSpacing: 1.25,
          color: 'black',
          marginLeft: 15,
        }}
      >
        Payment failed
      </h1>
    </Row>
    <Row>
      <p>
        Something went wrong and your payment could not be processed. We suggest you to try again later. If the problem persists, please email us at hi@bondai.io.
      </p>
    </Row>
  </Container>
);

export default PaymentGatewayErrorPage;
