import React from 'react';
import {
  FormInput,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'shards-react';

const Input = (props) => {
  return (
    <FormGroup>
      {props.label ? <label htmlFor={props.id}>{props.label}</label> : null}
      {props.append || props.prepend ? (
        <InputGroup>
          {props.prepend && (
            <InputGroupAddon type='prepend'>
              <InputGroupText>{props.prepend}</InputGroupText>
            </InputGroupAddon>
          )}
          <FormInput
            {...props}
            invalid={props.errors && props.errors.message}
          />
          {props.append && (
            <InputGroupAddon type='append'>
              <InputGroupText>{props.append}</InputGroupText>
            </InputGroupAddon>
          )}
        </InputGroup>
      ) : (
        <FormInput {...props} invalid={props.errors && props.errors.message} />
      )}
      {props.errors ? (
        <span style={{ position: 'absolute', right: 0 }}>
          {props.errors.message}
        </span>
      ) : null}
    </FormGroup>
  );
};

export default Input;
