import React, { useEffect, useState, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { fetchExperiencesByProviderId } from './exploreEffects';
import Loader from 'react-loader-spinner';
import {
  Container,
  Row,
  Col,
  FormInput,
  FormGroup,
  Button,
} from 'shards-react';
import {
  FaInstagram,
  FaYoutube,
  FaLinkedin,
  FaMedium,
  FaTwitter,
  FaFacebookSquare,
} from 'react-icons/fa';
import Header from '../../Header';
import ActivityCard from './ActivityCard';

import SectionLoader from '../../components/SectionLoader';
import { Input } from '../../components/ui-kit';
import BackgroundImage from '../../assets/images/muse-bg.jpg';

const ExplorePage = () => {
  const footerRef = useRef(null);
  const gridRef = useRef(null);

  const dispatch = useDispatch();

  const activities = useSelector((state) =>
    state.explore.ids.map((id) => ({
      ...state.explore.entities[id],
    })),
  );
  
  const isFetching = useSelector(
    (state) => state.explore.loading.search !== 'idle',
  );
  
  const [accessCodeInput, setAccessCodeInput] = useState('');
  const [allowedAccess, setAllowedAccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (localStorage.getItem('accessCode') === process.env.REACT_APP_MUSE_ACCESS_CODE) {
      setAllowedAccess(true);
    }
  }, []);

  useEffect(() => {
    if (allowedAccess) {
      dispatch(fetchExperiencesByProviderId(process.env.REACT_APP_MUSE_ID));
    }
  }, [allowedAccess]);

  return (
    <Container
      fluid
      className={`musePage ${allowedAccess ? 'isExploring' : ''}`}
    >
      <Container className='explorePage' style={{
        paddingBottom: 280,
      }}>
        <Row
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
            <h1>MUSE Exclusives</h1>
        </Row>
        {!allowedAccess ? (
          <div>
            <Row>
              <p  
                style={{
                  fontSize: 20,
                  color: 'white',
                  marginBottom: 40
                }}
              >
                As a MUSE member, enjoy exclusive access<br/> to inspiring experiences on Bondai.
              </p>
            </Row>
              <Row>
                <FormInput
                  type='password'
                  placeholder='Enter access code'
                  style={{ width: 200, marginRight: 15, fontSize: 14, border: 'none', borderRadius: 0 }}
                  onChange={(e) => {
                    setAccessCodeInput(e.target.value);
                    if (errorMessage) {
                      setErrorMessage('');
                    }
                  }}
                  invalid={!!errorMessage}
                />
                <Button
                  theme='dark'
                  onClick={() => { 
                    if (accessCodeInput === process.env.REACT_APP_MUSE_ACCESS_CODE) {
                      setAllowedAccess(true);
                      localStorage.setItem('accessCode', accessCodeInput);
                    } else {
                      setErrorMessage('Wrong access code');
                    }
                  }}
                  style={{ borderRadius: 0 }}
                >
                  EXPLORE
                </Button>
                {errorMessage ? (
                  <Row>
                    <p style={{
                      color: 'red',
                      lineHeight: '41px',
                      marginLeft: 15,
                      marginBottom: 0,
                    }}>{errorMessage}</p>
                  </Row>
                ) : null}
              </Row>
          </div>
        ) : (
          activities && activities.length ? (
            <Row>
              <Col sm={12} style={{ padding: 0, marginTop: 30 }}>
                <div ref={gridRef} className='grid-layout'>
                  {activities.map((activity, index) => (
                    <div
                      className={`grid-item grid-item-3`}
                      key={activity.shortId}
                    >
                      <ActivityCard
                        location={activity.destination}
                        price={
                          activity.defaultPrices[0]?.priceAmount || activity.startingFromPrices[0]?.priceAmount
                            ? `${activity.defaultPrices[0]?.priceAmount || activity.startingFromPrices[0].priceAmount} SAR`
                            : null
                        }
                        shortId={activity.shortId}
                        slug={activity.slug}
                        id={activity.id}
                        title={activity.activityTitle}
                        avatar={activity.avatarUrl}
                        duration={activity.defaultDurationInDays}
                        ratingValue={parseFloat(activity.globalRating.toFixed(2))}
                        ratingCount={activity.ratingCount < 2 && activity.globalRating < 3 ? 0 : activity.ratingCount}
                      />
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          ) : isFetching ? (
            <SectionLoader label='Fetching available experiences...' color='white' />
          ) : (
            <h5>Nothing to see here just yet!</h5>
          )
        )}
      </Container>
    </Container>
  );
};

const styles = {
  socialIcons: { width: 28, height: 28, margin: 10 },
};

export default ExplorePage;
