import { createAsyncThunk } from '@reduxjs/toolkit';
import * as BlogAPI from '../../api/BlogAPI';

export const fetchFeaturedArticles = createAsyncThunk(
  'blog/fetchFeaturedArticles',
  async () => {
    const { data } = await BlogAPI.fetchArticles();
    return data;
  }
);
