import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import PageLoader from '../../components/PageLoader';
import { search, indexContent } from './tourProvidersEffects';
import { fetchReport } from '../dashboard/activitiesEffects';
import { loginAs } from '../auth/userEffects';
import { onRedirect } from '../auth/userSlice';
import history from '../../utils/history';
import { indexableContent } from '../../helpers/constants'

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Badge,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Button
} from 'shards-react';

import {
  DateTimePicker,
} from '../../components/ui-kit';

const AdminPage = (props) => {
  const dispatch = useDispatch();
  const [isIndexOpen, setIsIndexOpen] = useState(false);
  const isIndexing = useSelector(state => state.tourProviders.loading.indexing === 'pending');
  const isFetchingReport = useSelector(state => state.activities.loading.report === 'pending');

  const {
    handleSubmit,
    errors,
    control,
    watch,
    trigger,
  } = useForm({
    defaultValues: {
      StartDate: null,
      EndDate: null,
    },
  });

  useEffect(() => {
    dispatch(
      search({
        sortOrder: 0,
        includeDisabled: true,
        searchTerm: '',
        take: 999,
        from: 0,
      }),
    );
  }, []);

  const tourProviders = useSelector((state) =>
    state.tourProviders.ids.map(
      (tourProviderId) => state.tourProviders.entities[tourProviderId],
    ),
  );
  const isFetchingTourProviders = useSelector(
    (state) => state.tourProviders.loading.search === 'pending',
  );

  const isLoggedInAs = useSelector(
    (state) => state.user.loading.loginAs === 'done',
  );

  useEffect(() => {
    if (isLoggedInAs) {
      history.replace('/dashboard');
      dispatch(onRedirect());
      window.location.reload();
    }
  }, [isLoggedInAs]);

  const onSubmit = (data, e) => {
    dispatch(fetchReport({
      startDate: data.StartDate.startOf('day').format(),
      endDate: data.EndDate.endOf('day').format(),
      activityBookingReportType: 1,
    }));
  };

  return isFetchingTourProviders ? (
    <PageLoader label='Fetching tour providers...' />
  ) : (
      <Container>
        <Row style={{ marginTop: 30 }}>
          <Col>
            <Dropdown open={isIndexOpen} toggle={() => setIsIndexOpen(!isIndexOpen)}>
              <DropdownToggle theme='dark' disabled={isIndexing}>
                {isIndexing ? 'Indexing content...' : 'Index content'}
              </DropdownToggle>
              <DropdownMenu>
                {indexableContent.map((label, index) => (
                  <DropdownItem onClick={() => {
                    if (window.confirm(`Your are about to index the following: ${label}. \nAre you sure you want to continue?`)) {
                      dispatch(indexContent(index));
                    }
                  }}>{label}</DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </Col>
        </Row>
        <form onSubmit={handleSubmit(onSubmit)} style={{ color: 'black' }}>
          <Row form>
            <Col style={{
              display: 'flex', flexDirection: 'row', alignItems: 'flex-end', flexWrap: 'wrap',
              gap: 12
            }}>
              <DateTimePicker
                name='StartDate'
                placeholder='Pick a date...'
                label='Start date'
                errors={errors.StartDate}
                rules={{
                  validate: () => {
                    // trigger();
                    const value = watch('StartDate');
                    if (!value) return 'This field is required';
                  }
                }}
                control={control}
                dateOnly
                readOnly
              />
              <DateTimePicker
                name='EndDate'
                placeholder='Pick a date...'
                label='End date'
                errors={errors.EndDate}
                rules={{
                  validate: () => {
                    const value = watch('EndDate');
                    if (!value) return 'This field is required';
                    if (value.isBefore(watch('StartDate'))) return 'End date should be after start date';
                  }
                }}
                control={control}
                dateOnly
                readOnly
              />
              <Button disabled={isFetchingReport}>{isFetchingReport ? 'Generating report...' : 'Download report'}</Button>
            </Col>
          </Row>
        </form>
        <Row>
          {tourProviders.map((tourProvider) => (
            <Col xs={12} sm={6} style={{ marginTop: 30 }}>
              <Card
                style={{ cursor: 'pointer', height: '100%' }}
                onClick={() => {
                  dispatch(loginAs({ email: tourProvider.emailAddress }));
                }}
              >
                <CardBody>
                  <h5>{tourProvider.tourProviderName}</h5>
                  <div>
                    <Badge theme={tourProvider.isDisabled ? 'danger' : 'success'}>
                      {tourProvider.isDisabled ? 'disabled' : 'active'}
                    </Badge>
                    <div
                      style={{
                        marginLeft: 20,
                        display: 'inline',
                        fontWeight: 600,
                        color: 'black',
                      }}
                    >
                      {tourProvider.emailAddress}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </Container >
    );
};

export default AdminPage;
