import React from 'react';
import { FormCheckbox, FormGroup } from 'shards-react';

const Checkbox = (props) => {
  return (
    <FormGroup>
      <FormCheckbox inline name={props.name} innerRef={props.innerRef}>
        {props.label}
      </FormCheckbox>
      {props.errors ? (
        <span style={{color: 'red', display: 'block'}}>
          {props.errors.message}
        </span>
      ) : null}
    </FormGroup>
  );
};

export default Checkbox;
