import React, { useState } from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  FormInput,
  FormGroup,
  Button,
} from 'shards-react';
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword } from './userEffects';
import { isEmail } from '../../helpers/validations';
import { Link } from 'react-router-dom';

const ResetPasswordPage = (props) => {
  const dispatch = useDispatch();

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const isSubmitting = useSelector(
    (state) => state.user.loading.resetPassword === 'pending',
  );

  const token = props.match.params.token;

  const checkPassword = (e) => {
    if (e.target.value.length < 6 && e.target.value.length > 0) {
      setErrorMessage('The password must have at least 6 characters.');
    } else {
      setErrorMessage('');
    }
  };

  const checkConfirmedPassword = (e) => {
    if (e.target.value !== newPassword && newPassword.length > 0) {
      setErrorMessage('The passwords does not match.');
    } else {
      setErrorMessage('');
    }
  };

  return (
    <Container style={{ flex: 1, paddingTop: 100, paddingBottom: 60 }}>
      <div className='pageTitle' style={{ marginBottom: 20 }}>
        Reset your password
      </div>
      <Row>
        <Col
          xs='12'
          md={{ size: 12 }}
          style={{
            color: 'black',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Form
            className='blurSection'
            style={{ width: '100%', maxWidth: 320, paddingTop: 14 }}
          >
            <Row form>
              <FormGroup className='loginFormGroup'>
                <label htmlFor='#newPassword'>New password</label>
                <FormInput
                  type='password'
                  id='#newPassword'
                  placeholder='New Password'
                  name='newPassword'
                  valid={newPassword.length >= 6}
                  value={newPassword}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                  }}
                  onBlur={checkPassword}
                />
                <label htmlFor='#confirmPassword'>Confirm password</label>
                <FormInput
                  type='password'
                  id='#confirmPassword'
                  placeholder='Confirm Password'
                  name='confirmPassword'
                  valid={
                    confirmPassword.length >= 6 &&
                    confirmPassword === newPassword
                  }
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                  onBlur={checkConfirmedPassword}
                />
              </FormGroup>
              {errorMessage && (
                <div
                  style={{
                    color: '#F7414E',
                    maxWidth: 320,
                  }}
                >
                  {errorMessage}
                </div>
              )}
            </Row>
          </Form>
          <Button
            theme='dark'
            style={{
              fontWeight: 500,
              borderRadius: 10,
              width: '100%',
              marginTop: 30,
              height: 50,
              maxWidth: 300,
            }}
            disabled={
              !newPassword ||
              !confirmPassword ||
              newPassword !== confirmPassword ||
              isSubmitting
            }
            onClick={() => {
              dispatch(
                resetPassword({
                  newPassword,
                  token,
                }),
              );
            }}
          >
            RESET YOUR PASSWORD
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default ResetPasswordPage;
