import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Container,
  Row,
  Col,
  Form,
  Button,
} from 'shards-react';
import Axios from 'axios';
import BondaiLogo from '../../assets/images/logo.png';
import { Input, Checkbox } from '../../components/ui-kit';
import { phoneRegex, emailRegex } from '../../helpers/validations';
import { apiUrl } from '../../helpers/constants';

import history from '../../utils/history';
import { useDispatch, useSelector } from 'react-redux';
import { registerProvider } from './userEffects';

const OnboardingPage = () => {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    errors,
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
      name: '',
      phoneNumber: '+(966)',
      legal: false,
    },
  });

  const onSubmit = (data) => {
    dispatch(registerProvider(data));
  };

  const isRegistering = useSelector(
    (state) => state.user.loading.registerProvider === 'pending',
  );
  const isRegistrationSuccessful = useSelector(
    (state) => state.user.loading.registerProvider === 'done',
  );

  useEffect(() => {
    isRegistrationSuccessful && history.push('/dashboard');
  }, [isRegistrationSuccessful]);

  return (
    <Container style={{ flex: 1, paddingTop: 100, paddingBottom: 60 }}>
      <div>
        <div className='pageTitle' style={{padding: 15}}>
          Experience Provider registration
          <br />
          تسجيل منظم الرحلات
        </div>

        <Row>
          <Col
            xs='12'
            md={{ size: 6, offset: 3 }}
            style={{
              color: 'black',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Form className='blurSection' onSubmit={handleSubmit(onSubmit)}>
              <div
                style={{
                  marginTop: 0,
                  marginBottom: 10,
                  paddingLeft: 10,
                  paddingRight: 10,
                  textAlign: 'center',
                  fontSize: 18,
                  fontWeight: 500,
                }}
              >
                Create your experience provider account and start listing your
                experiences on Bondai! <br />
                !قم بإنشاء حسابك وابدأ في تنزيل رحلاتك على بونداي
              </div>
              <Row form>
                <Col xs={12}>
                  <Input
                    label='Email'
                    name='email'
                    placeholder='Email address'
                    errors={errors.email}
                    innerRef={register({
                      required: 'This field is required',
                      pattern: {
                        value: emailRegex,
                        message: 'Invalid email address'
                      },
                      validate: async (value) => {
                        try {
                          await Axios.get(
                            `${apiUrl}accounts/status/${value}`,
                          );
                          return 'An account is already registered with this email';
                        } catch (err) {
                          return null;
                        }
                      },
                    })}
                  />
                </Col>
              </Row>
              <Row form>
                <Col xs={12}>
                  <Input
                    label='Password'
                    name='password'
                    type='password'
                    placeholder='*********'
                    errors={errors.password}
                    innerRef={register({
                      required: 'This field is required',
                      minLength: {
                        value: 6,
                        message: 'Your password should be at least 6 characters long',
                      },
                    })}
                  />
                </Col>
              </Row>
              <Row form>
                <Col xs={12}>
                  <Input
                    label='Name - اسم المنظم (اسم الشركة)'
                    name='name'
                    placeholder='Experience provider name'
                    errors={errors.name}
                    innerRef={register({
                      required: 'This field is required',
                    })}
                  />
                </Col>
              </Row>
              <Row form>
                <Col xs={12}>
                  <Input
                    label='Phone - رقم واتساب'
                    name='phoneNumber'
                    defaultValue='+(966)'
                    placeholder='+(966) XXX XXX XXXX'
                    errors={errors.phoneNumber ? {
                      message: 'Invalid phone format: +(966) XXX XXX XXXX'
                    } : null}
                    innerRef={register({
                      pattern: phoneRegex
                    })}
                  />
                </Col>
              </Row>
              <div style={{ marginTop: 30, marginLeft: 5 }}>
                Before joining Bondai, please make sure you read our{' '}
                <a
                  style={{
                    textDecoration: 'underline',
                    textDecorationStyle: 'dashed',
                    whiteSpace: 'nowrap',
                    color: '#000',
                  }}
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://app.termly.io/document/terms-of-use-for-website/2369c615-d97e-416c-88b9-a264c76c9a07'
                >
                  terms and conditions
                </a>
                {' and '}
                <a
                  style={{
                    textDecoration: 'underline',
                    textDecorationStyle: 'dashed',
                    whiteSpace: 'nowrap',
                    color: '#000',
                  }}
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://app.termly.io/document/privacy-policy/4001754c-91dc-4787-a978-0bd83a98f53f'
                >
                  privacy policy
                </a>
                .
              </div>
              <Row style={{ paddingLeft: 10 }} form>
                <Checkbox
                  name='legal'
                  errors={errors.legal}
                  label='I read and agree to join'
                  innerRef={register({
                    required: 'This field is required.'
                  })}
                />
              </Row>
              <Row form className='bondai-custom'>
              <Button
                theme='dark'
                style={{
                  fontWeight: 500,
                  borderRadius: 10,
                  width: '100%',
                  marginTop: 30,
                  height: 50,
                }}
                disabled={isRegistering}
                type='submit'
              >
                {isRegistering
                  ? 'JOINING BONDAI...'
                  : 'JOIN BONDAI'}
              </Button>
            </Row>
          </Form>
          
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default OnboardingPage;
