import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Container,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal, ModalBody
} from 'shards-react';
import ActivityPhotosHandler from '../../components/ActivityPhotosHandler';
import { useDispatch, useSelector } from 'react-redux';
import {
  addActivity,
  updateActivity,
  fetchActivityById,
  deletePaidExtra,
  addPaidExtra,
} from './activitiesEffects';
import { FiArrowLeft, FiX } from 'react-icons/fi';
import history from '../../utils/history';
import { useForm } from 'react-hook-form';
import { Select, Input, Checkbox, MultiSelect } from '../../components/ui-kit';
import PageLoader from '../../components/PageLoader';
import {
  fetchActivityTags,
} from '../auth/userEffects';
import {
  defaultEditorContent,
  editorContentReducer,
  editorContentMapper,
} from '../../helpers/editor';
import { lowerCaseFirstLetter } from '../../helpers/functions';


import { placesTypes } from '../../helpers/constants';
import ControlledPlacesAutocomplete from '../../components/ControlledPlacesAutocomplete';

import ControlledTextEditor from '../../components/ControlledTextEditor';

const editorFields = [
  'Description',
  'TermsAndConditions',
  'WhatToBring',
  'CancellationPolicy',
];

const HandleActivityPage = ({ editing, match }) => {
  const dispatch = useDispatch();
  const [photos, setPhotos] = useState([]);
  const isSimplified = history.location.search === '?simplified';
  const [cover, setCover] = useState('');
  const [extras, setExtras] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  const activity = useSelector(
    (state) => state.activities.entities[match.params.id],
  );

  const currencies = useSelector((state) => state.user.currencies);

  useEffect(() => {
    dispatch(fetchActivityTags());
    if (editing) {
      dispatch(fetchActivityById(match.params.id));
    }
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    errors,
    control,
    getValues,
    setValue,
    reset
  } = useForm({
    defaultValues: {
      CurrencyId: 'SAR',
      ActivityTitle: '',
      DefaultDurationInDays: 1,
      // GooglePlaceId: { label: '', value: null },
      DefaultCapacity: 1,
      AdultPrice: 0,
      ChildPrice: 0,
      IsPrivate: false,
      ...editorFields.reduce(
        (fields, field) => ({ ...fields, [field]: defaultEditorContent() }),
        {},
      ),
    },
  });

  const [displayPhotoError, setDisplayPhotoError] = useState(false);
  const [displayTagsError, setDisplayTagsError] = useState(false);
  const photosRef = useRef(null);
  const tagsRef = useRef(null);

  const onSubmit = (data, e) => {
    if ((photos.length < 3 && !isSimplified) || (isSimplified && !photos.length)) {
      setDisplayPhotoError(true);
      photosRef.current.scrollIntoView();
      return;
    }
    if (!selectedTags.length) {
      setDisplayTagsError(true);
      tagsRef.current.scrollIntoView();
      return;
    }
    //errors || !photos || photos.length < 3

    const reducedEditorFields = editorFields.reduce(
      (reduced, field) => ({
        ...reduced,
        [field]: editorContentReducer(data[field]),
      }),
      {},
    );
    if (editing)
      return dispatch(
        updateActivity({
          ...data,
          ...(data.GooglePlaceId.value && {
            GooglePlaceId: data.GooglePlaceId.value,
          }),
          TagIds: selectedTags.map((tag) => tag.value),
          DefaultPrices: [{
            TicketTypeId: process.env.REACT_APP_ADULT_TICKET_ID,
            PriceAmount: data.AdultPrice,
          }, {
            TicketTypeId: process.env.REACT_APP_CHILD_TICKET_ID,
            PriceAmount: data.ChildPrice,
          }],
          id: activity.id,
          photos,
          cover,
          ...reducedEditorFields,
        }),
      );
    return dispatch(
      addActivity({
        ...data,
        GooglePlaceId: data.GooglePlaceId.value,
        TagIds: selectedTags.map((tag) => tag.value),
        DefaultPrices: [{
          TicketTypeId: process.env.REACT_APP_ADULT_TICKET_ID,
          PriceAmount: data.AdultPrice,
        }, {
          TicketTypeId: process.env.REACT_APP_CHILD_TICKET_ID,
          PriceAmount: data.ChildPrice,
        }],
        photos,
        cover,
        extras,
        ...reducedEditorFields,
      }),
    );
  };

  const isLoading = useSelector(
    (state) => state.activities.loading.handleActivity === 'pending',
  );
  const activityTags = useSelector((state) => state.user.tags ? Object.keys(state.user.tags).map(key => state.user.tags[key]) : []);

  useEffect(() => {
    if (activity) {
      //pricing mapping logic
      let AdultPrice = 0;
      let ChildPrice = 0;
      if (activity.defaultPrices?.length) {
        AdultPrice = +activity.defaultPrices[0]?.priceAmount || 0;
        ChildPrice = +activity.defaultPrices[1]?.priceAmount || 0;
      } else if (activity.startingFromPrices?.length) {
        AdultPrice = +activity.startingFromPrices[0]?.priceAmount || 0;
        ChildPrice = +activity.startingFromPrices[1]?.priceAmount || 0;
      }
      setTimeout(() => {
        setValue([
          {
            CurrencyId: activity.currency.id,
          },
          {
            ActivityTitle: activity.activityTitle,
          },
          {
            Slug: activity.slug,
          },
          {
            Including: activity.including,
          },
          {
            Excluding: activity.excluding,
          },
          {
            DefaultDurationInDays: activity.defaultDurationInDays,
          },
          { AdultPrice },
          { ChildPrice },
          ...editorContentMapper(editorFields, activity),
        ]);
      }, 1000);
      setPhotos(
        activity.photoUrls
          ? activity.photoUrls.map((url) => ({ path: url }))
          : [],
      );
      setCover(activity.avatarUrl);
      setExtras(
        Object.keys(activity.paidExtras).map(
          (paidExtraId) => activity.paidExtras[paidExtraId],
        ),
      );
    }
  }, [activity]);

  const onSelectPhoto = (payload) => {
    setPhotos([...photos, payload]);
    if (((!isSimplified && photos.length > 1) || (isSimplified && payload)) && displayPhotoError) {
      setDisplayPhotoError(false);
    }
    if (!cover) setCover(payload.path);
  };

  const onRemovePhoto = (payload) => {
    setPhotos(photos.filter((photo) => photo.path !== payload));
  };

  useEffect(() => {
    if (activity && activity.tags) {
      setSelectedTags(
        activity.tags.map((tag) => ({ value: tag.id, label: tag.name })),
      );
    }
  }, [activity]);

  useEffect(() => {
    if (!editing) {
      const interval = setInterval(() => {
        const values = getValues();
        localStorage.setItem('draft', JSON.stringify({
          CurrencyId: values.CurrencyId,
          ActivityTitle: values.ActivityTitle,
          AdultPrice: values.AdultPrice,
          ChildPrice: values.ChildPrice,
          DefaultDurationInDays: values.DefaultDurationInDays,
          Including: values.Including,
          Excluding: values.Excluding,
          IsPrivate: values.IsPrivate,
          GooglePlaceId: values.GooglePlaceId,
          extras,
          selectedTags,
          ...editorFields.reduce(
            (reduced, field) => ({
              ...reduced,
              [lowerCaseFirstLetter(field)]: editorContentReducer(values[field]),
            }),
            {},
          )
        }));
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [extras, photos, cover, selectedTags, getValues, editing]);

  const [isDraftingModal, setIsDraftingModal] = useState(false);

  useEffect(() => {
    if (!editing && currencies?.length) {
      setValue('CurrencyId', 'SAR');
      const draft = localStorage.getItem('draft');
      const parsedDraft = JSON.parse(draft);
      if (draft) {
        setIsDraftingModal(true);
        setValue([...Object.keys(parsedDraft).map(key => ({
          [key]: parsedDraft[key]
        })), ...editorContentMapper(editorFields, parsedDraft)]);
        setLocationBackup(parsedDraft.GooglePlaceId);
        setExtras(parsedDraft.extras);
        setSelectedTags(parsedDraft.selectedTags);
      }
    }
  }, [editing, currencies]);

  const [locationBackup, setLocationBackup] = useState(null);

  return editing && !activity ? (
    <PageLoader label='Fetching experience data...' />
  ) : (
      <Container style={{ padding: 30, color: 'black' }}>
        <Modal
          size='sm'
          open={isDraftingModal}
          toggle={() => { setIsDraftingModal(!isDraftingModal); }}
        >
          <ModalBody>
            <p style={{ color: '#000' }}>
              We noticed you've been previously drafting a new experience. Would you like to continue?
          </p>
            <Button
              theme='secondary'
              style={{ marginRight: 10 }}
              onClick={() => {
                setIsDraftingModal(false);
              }}
            >
              Yes, continue
          </Button>
            <Button
              onClick={() => {
                localStorage.removeItem('draft');
                window.location.reload();
              }}
              theme='danger'
            >
              No, dismiss draft
          </Button>
          </ModalBody>
        </Modal>
        <Row>
          <Col xs={12} md={{ size: 8, offset: 2 }}>
            <Row>
              <Col>
                <div
                  style={{
                    display: 'block',
                    fontWeight: 700,
                    cursor: 'pointer',
                    marginBottom: 20,
                  }}
                  className='backLink'
                  onClick={() =>
                    history.push(
                      editing
                        ? `/dashboard/manage/${match.params.id}`
                        : '/dashboard',
                    )
                  }
                >
                  <FiArrowLeft
                    style={{ height: 24, width: 24, marginRight: 5 }}
                  />
                  {editing ? 'manage this experience' : 'your experiences'}
                </div>
              </Col>
            </Row>
            <h1
              style={{
                display: 'inline',
                lineHeight: 1,
                verticalAlign: 'bottom',
                fontWeight: 900,
                fontSize: 60,
                letterSpacing: 1.25,
                color: '#000',
                marginLeft: 15,
                marginTop: 30,
              }}
            >
              {editing ? 'Edit' : 'Add'} experience
          </h1>
            <Row style={{ marginTop: 30 }}>
              <Col xs={12}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Row form>
                    <Col xs={12} md={7}>
                      <Input
                        name='ActivityTitle'
                        placeholder='Your experience title...'
                        label='Title'
                        errors={errors.ActivityTitle}
                        innerRef={register({
                          required: 'This field is required',
                        })}
                      />
                    </Col>
                    <Col xs={12} md={5}>
                      <ControlledPlacesAutocomplete
                        name='GooglePlaceId'
                        control={control}
                        rules={{
                          validate: () =>
                            getValues('GooglePlaceId')?.value || (!getValues('GooglePlaceId')?.value && activity?.destination.localityName)
                              ? undefined
                              : 'This field is required',
                        }}
                        title='Location'
                        initialValue={
                          activity
                            ? {
                              label: activity.destination.localityName,
                              value: null,
                            }
                            : locationBackup
                        }
                        types={[placesTypes.CITIES]}
                        placeholder='search for location...'
                        errors={errors.GooglePlaceId}
                      />
                    </Col>
                  </Row>
                  {editing ? (
                    <Row>
                      <Col className="slugEditor">
                        <label>Experience URL:</label>
                        <div>
                          <span>bondai.io/experience/</span>
                          <Input
                            name='Slug'
                            errors={errors.Slug ? {
                              message: 'Please use at least 3 of the following characters: a-z, 0-9, -'
                            } : null}
                            innerRef={register({
                              pattern: /^([a-z0-9-]){3,}[a-z0-9-]*$/,
                            })}
                          />
                        </div>
                      </Col>
                    </Row>
                  ) : null}
                  <Row>
                    <Col xs={12}>
                      <div ref={tagsRef}>
                        <label>Tags</label>
                        <em
                          style={{
                            marginLeft: 5,
                            color: 'black',
                          }}
                        >
                          (adding relevant tags will improve the discoverability of your experience)
                        </em>
                      </div>
                      <MultiSelect
                        style={{ width: '100%', maxWidth: '100%' }}
                        customStyles={{
                          control: {
                            borderRadius: '.375rem',
                            height: 41,
                            width: '100%',
                            borderColor: '#becad6',
                          },
                          container: {
                            maxWidth: '100%',
                          },
                        }}
                        options={activityTags ? activityTags.map((tag) => ({
                          value: tag.id,
                          label: tag.name,
                        })) : []}
                        value={selectedTags}
                        onChange={(tags) => {
                          if (tags?.length && displayTagsError) {
                            setDisplayTagsError(false);
                          }
                          setSelectedTags(tags || []);
                        }}
                      />
                      {displayTagsError ? (
                        <p style={{ color: '#c4183c', fontWeight: 'bold' }}>
                          Please select at least one experience tag.
                        </p>
                      ) : null}
                    </Col>
                  </Row>
                  <Row form>
                    <Col xs={12}>
                      <ControlledTextEditor
                        name='Description'
                        control={control}
                        title='Description'
                        placeholder='Your experience description...'
                      />
                    </Col>
                  </Row>
                  <Row form>
                    <Col xs={12}>
                      <ControlledTextEditor
                        name='TermsAndConditions'
                        control={control}
                        title='Terms and Conditions'
                        placeholder='Your experience terms and conditions...'
                      />
                    </Col>
                  </Row>
                  <Row form>
                    <Col xs={12}>
                      <ControlledTextEditor
                        name='WhatToBring'
                        control={control}
                        title='What To Bring'
                        placeholder='Items to bring for the experience...'
                      />
                    </Col>
                  </Row>
                  <Row form>
                    <Col xs={12}>
                      <ControlledTextEditor
                        name='CancellationPolicy'
                        control={control}
                        title='Cancellation Policy'
                        placeholder='Your booking cancellation policy...'
                      />
                    </Col>
                  </Row>
                  <Row form>
                    <Col>
                      <Input
                        name='Including'
                        placeholder='Included services...'
                        label='Including'
                        innerRef={register}
                      />
                    </Col>
                    <Col>
                      <Input
                        name='Excluding'
                        placeholder='Excluded services...'
                        label='Excluding'
                        innerRef={register}
                      />
                    </Col>
                  </Row>
                  <div ref={photosRef}>
                    <label>Experience photos</label>
                    <em
                      style={{
                        marginLeft: 5,
                        color: displayPhotoError ? '#c4183c' : 'black',
                      }}
                    >
                      {`(please add ${isSimplified ? 'at least one photo' : 'between 3 and 16 photos'})`}
                  </em>
                    <MemoizedPhotosHandler
                      onSelect={onSelectPhoto}
                      onRemove={onRemovePhoto}
                      onChangeCover={setCover}
                      cover={cover}
                      photos={photos}
                      invalid={displayPhotoError}
                    />
                  </div>
                  <Row form>
                    <Col xs={12}>
                      <Input
                        name='DefaultDurationInDays'
                        placeholder='Experience duration...'
                        label='Duration'
                        type='number'
                        append={'days'}
                        errors={{
                          message: errors.DefaultDurationInDays?.type
                            ? 'Duration is required'
                            : '',
                        }}
                        innerRef={register({
                          min: 1,
                          required: true,
                          type: 'number',
                        })}
                      />
                    </Col>

                  </Row>
                  <Row form>
                    <Col xs={12} md={editing ? 6 : 4}>
                      <Input
                        name='AdultPrice'
                        label='Adult price'
                        placeholder='Adult ticket price...'
                        type='number'
                        step='0.01'
                        append={activity?.currency?.id || null}
                        errors={{
                          message: errors.AdultPrice?.type
                            ? 'Adult price is required'
                            : '',
                        }}
                        innerRef={register({
                          min: 1,
                          required: true,
                          type: 'number',
                        })}
                      />
                    </Col>
                    <Col xs={12} md={editing ? 6 : 4}>
                      <Input
                        name='ChildPrice'
                        label='Child price'
                        placeholder='Child ticket price...'
                        type='number'
                        step='0.01'
                        append={activity?.currency?.id || null}
                        errors={errors.ChildPrice}
                        innerRef={register}
                      />
                    </Col>
                    {editing ? null : <Col xs={12} md={4}>
                      <Select
                        disabled={editing}
                        name='CurrencyId'
                        label='Currency'
                        options={currencies.map((currency) => ({
                          value: currency.id,
                          label: currency.id,
                        }))}
                        innerRef={register({ required: true })}
                      />
                    </Col>}
                  </Row>
                  <hr />
                  <label>Paid Extras</label>
                  <Row form>
                    <Col xs={12} md={5}>
                      <Input
                        name='ExtraTitle'
                        label='Title'
                        innerRef={register}
                        placeholder='Service title...'
                        onKeyPress={(e) => {
                          e.key === 'Enter' && e.preventDefault();
                        }}
                      />
                    </Col>
                    <Col xs={12} md={5}>
                      <Input
                        name='ExtraPrice'
                        label='Price'
                        placeholder='Service price...'
                        type='number'
                        step='0.01'
                        append={activity?.currency?.id || watch('CurrencyId')}
                        innerRef={register}
                        onKeyPress={(e) => {
                          e.key === 'Enter' && e.preventDefault();
                        }}
                      />
                    </Col>
                    <Col
                      xs={12}
                      md={2}
                      style={{
                        justifyContent: 'flex-end',
                        flexDirection: 'column',
                        display: 'flex',
                      }}
                    >
                      <Button
                        style={{ width: '100%', height: 40, marginBottom: 0 }}
                        onClick={(e) => {
                          e.preventDefault();
                          if (editing) {
                            dispatch(
                              addPaidExtra({
                                activityId: activity.id,
                                body: {
                                  description: '',
                                  label: getValues('ExtraTitle'),
                                  priceAmount: getValues('ExtraPrice'),
                                },
                              }),
                            );
                          } else {
                            setExtras([
                              ...extras,
                              {
                                description: '',
                                label: getValues('ExtraTitle'),
                                priceAmount: getValues('ExtraPrice'),
                              },
                            ]);
                          }
                          setValue([{ ExtraTitle: '' }, { ExtraPrice: '' }]);
                        }}
                        disabled={!watch('ExtraTitle') || !watch('ExtraPrice')}
                      >
                        ADD
                    </Button>
                    </Col>
                  </Row>
                  <Row form>
                    {extras.map((extra, index) => (
                      <InputGroup
                        size='sm'
                        style={{
                          width: 'auto',
                          marginRight: 10,
                          marginTop: 10,
                        }}
                      >
                        <InputGroupAddon type='prepend'>
                          <InputGroupText>{`${extra.label} - ${extra.priceAmount
                            } ${activity?.currency?.id || watch('CurrencyId')}`}</InputGroupText>
                        </InputGroupAddon>
                        <InputGroupAddon type='append'>
                          <Button
                            theme='danger'
                            style={{ marginBottom: 0 }}
                            onClick={(e) => {
                              e.preventDefault();
                              if (editing) {
                                dispatch(
                                  deletePaidExtra({
                                    activityId: activity.id,
                                    paidExtraId: extras[index].ticketTypeId,
                                  }),
                                );
                              } else {
                                setExtras(
                                  extras.filter(
                                    (filterExtra, filterIndex) =>
                                      filterIndex !== index,
                                  ),
                                );
                              }
                            }}
                          >
                            <FiX
                              style={{
                                height: 24,
                                width: 24,
                                marginRight: 5,
                              }}
                            />
                          </Button>
                        </InputGroupAddon>
                      </InputGroup>
                    ))}
                  </Row>
                  {editing ? null : (
                    <>
                      <hr />
                      <Row>
                        <Checkbox
                          label='private experience (unlisted)'
                          name='IsPrivate'
                          innerRef={register}
                        />
                      </Row>
                    </>
                  )}
                  <Row>
                    <Col
                      xs={12}
                      md={{ size: 6, offset: 3 }}
                      style={{ textAlign: 'center' }}
                    >
                      <Button
                        theme='dark'
                        style={{
                          fontWeight: 500,
                          borderRadius: 10,
                          width: '100%',
                          marginTop: 30,
                          height: 50,
                          textTransform: 'uppercase',
                        }}
                        type='submit'
                        disabled={isLoading}
                      >
                        {editing
                          ? isLoading
                            ? 'Saving changes...'
                            : 'Save changes'
                          : isLoading
                            ? 'Creating experience...'
                            : 'Create experience'}
                      </Button>
                      {!editing
                        ? 'and proceed with setting up availability'
                        : null}
                    </Col>
                  </Row>
                </form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
};

const MemoizedPhotosHandler = React.memo(
  ActivityPhotosHandler,
  (prevProps, nextProps) =>
    prevProps.photos.length === nextProps.photos.length &&
    prevProps.cover === nextProps.cover &&
    prevProps.invalid === nextProps.invalid,
);

export default HandleActivityPage;
