import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchActivities } from '../auth/userEffects';
import Loader from 'react-loader-spinner';
import { Container, Row, Col } from 'shards-react';

import Header from '../../Header';
import { Link } from 'react-router-dom';

const BucketListPage = () => {
  const dispatch = useDispatch();

  const activities = useSelector((state) =>
    state.user.ids.map((id) => state.user.entities[id]),
  );

  useEffect(() => {
    dispatch(fetchActivities());
  }, []);

  return (
    <Container
      fluid
      style={{ padding: 0, minHeight: '100%', position: 'absolute' }}
    >
      <Header />
      <Container>
        <Row
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 30,
          }}
        >
          <h1
            style={{
              display: 'inline',
              lineHeight: 1,
              verticalAlign: 'bottom',
              fontWeight: 900,
              fontSize: 60,
              letterSpacing: 1.25,
              color: '#000',
              marginLeft: 15,
            }}
          >
            Your bucket list
          </h1>
        </Row>
        <Row style={{ minHeight: '100%', paddingBottom: 240 }}>
          {activities.map((activity) => (
            <Col xs='6' md='3' key={activity.id}>
              <Link
                to={`/explore/${activity.shortId}`}
                style={{ cursor: 'pointer', textDecoration: 'none' }}
              >
                <div
                  style={{
                    position: 'relative',
                    width: '100%',
                    backgroundColor: 'rgba(255, 255, 255, 0.75)',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundImage: `url(${activity.avatarUrl})`,
                    borderRadius: 10,
                    marginTop: 30,
                  }}
                >
                  <div
                    style={{ display: 'block', paddingBottom: '100%' }}
                  ></div>
                </div>
                <div
                  style={{
                    marginTop: 10,
                    fontWeight: 600,
                    fontSize: 18,
                    color: '#000',
                    letterSpacing: 1.25,
                  }}
                >
                  {activity.activityTitle}
                </div>
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
    </Container>
  );
};
export default BucketListPage;
