import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { setStickyFilters } from "../features/explore/exploreSlice";
import { useScrollPosition } from "../helpers/hooks";
import Navbar from "./Navbar";
import { Container, Row, Col, Slider } from "shards-react";
import {
  FaFacebookSquare,
  FaLinkedin,
  FaInstagramSquare,
  FaTwitterSquare,
  FaMedium,
  FaYoutubeSquare,
  FaTiktok,
  FaWhatsapp,
} from "react-icons/fa";

import logoIconWhite from "../assets/logo-icon-white.svg";
import "./new-footer.scss";

const DefaultLayout = ({
  children,
  searchRef,
  filtersRef,
  isSearchTriggered,
  searchTrigger,
}) => {
  const [stickyNavbar, setStickyNavbar] = useState(false);
  const [navbarSearch, setNavbarSearch] = useState(false);
  const contentRef = useRef();
  const dispatch = useDispatch();
  const stickyFilters = useSelector((state) => state.explore.stickyFilters);

  useScrollPosition(
    ({ currPos }) => {
      if (searchRef && searchRef.current.getBoundingClientRect().top < 70) {
        setNavbarSearch(true);
      } else {
        setNavbarSearch(false);
      }
      if (
        filtersRef?.current &&
        filtersRef.current.getBoundingClientRect().top <
          67 - filtersRef.current.getBoundingClientRect().height
      ) {
        if (!stickyFilters) {
          dispatch(setStickyFilters(true));
        }
      } else {
        if (stickyFilters) {
          dispatch(setStickyFilters(false));
        }
      }
      if (currPos.y < -50) {
        setStickyNavbar(true);
      } else {
        setStickyNavbar(false);
      }
    },
    [stickyFilters],
    contentRef
  );

  return (
    <div className="contentWrapper" ref={contentRef}>
      <Navbar
        isSticky={stickyNavbar}
        isSearchVisible={navbarSearch || isSearchTriggered}
        isNavbarSearchTriggered={isSearchTriggered}
        onNavbarSearchDismiss={() => {
          searchTrigger(false);
        }}
      />
      {children}
      <Container fluid className="new-footer footer">
        <Container style={{ padding: 0 }}>
          <Row className="links">
            <Col xs={12} md={3}>
              <a
                className="new-footer__font-22 new-footer__font-700"
                href="https://bondai.io/about-us"
              >
                The Tale of “Bondai”
              </a>
              <a
                className="new-footer__font-18 new-footer__mt-3"
                href="https://b2bondai.com/"
              >
                Become a partner
              </a>
              <a
                className="new-footer__font-18 new-footer__mt-3"
                href="https://bondai.io/create-your-trip"
              >
                Create your trip with us
              </a>
              <a
                className="new-footer__font-18 new-footer__mt-3"
                href="https://bondai.io/blogs"
              >
                Blogs
              </a>
              <a
                className="new-footer__font-18 new-footer__mt-3"
                href="https://bondai.io/faq"
              >
                FAQ
              </a>
            </Col>
            <Col xs={12} md={3}>
              <a
                className="new-footer__font-22 new-footer__font-700"
                href="https://b2bondai.com/?lang=en"
              >
                Bondai B2B services
              </a>
              <a
                className="new-footer__font-18 new-footer__mt-3"
                href="https://bondaiportal.com/"
              >
                Bondai Portal
              </a>
              <a
                className="new-footer__font-18 new-footer__mt-3"
                href="https://bondai.io/terms-policy"
              >
                Terms & conditions
              </a>
              <a
                className="new-footer__font-18 new-footer__mt-3"
                href="https://bondai.io/privacy-policy"
              >
                Privacy policy
              </a>
              <a
                className="new-footer__font-18 new-footer__mt-3"
                href="https://bondai.io/refund-policy"
              >
                Refund policy
              </a>
            </Col>
            <Col xs={12} md={3}></Col>
            <Col xs={12} md={3}>
              <Row className="suggestion">
                <Col>
                  <span className="new-footer__font-22 new-footer__font-700">Follow us</span>
                  <Row>
                    <Col className="socialIcons">
                      <a href="https://www.facebook.com/Bondai.io/">
                        <FaFacebookSquare />
                      </a>
                      <a href="https://www.linkedin.com/company/bondai-io">
                        <FaLinkedin />
                      </a>
                      <a href="https://www.instagram.com/bondai.io">
                        <FaInstagramSquare />
                      </a>
                      <a href="https://twitter.com/bondai_io">
                        <FaTwitterSquare />
                      </a>
                      <a href="https://www.tiktok.com/@bondai.io">
                        <FaTiktok />
                      </a>
                      <a href="http://web.whatsapp.com/send?phone=+966555074410">
                        <FaWhatsapp />
                      </a>
                    </Col>
                  </Row>
                  <span className="new-footer__d-block new-footer__mt-2 new-footer__font-16">
                    Contact:
                  </span>
                  <span className="new-footer__d-block new-footer__mt-1 new-footer__font-16">
                    +966 55 507 4410:
                  </span>
                  <span className="new-footer__d-block new-footer__mt-1 new-footer__font-16">
                    +966 55 507 4439:
                  </span>
                  <span className="new-footer__d-block new-footer__mt-2 new-footer__font-16">
                    Email:
                  </span>
                  <a className="new-footer__mt-1 new-footer__font-16" href="mailto:hi@bondai.io">
                    hi@bondai.io
                  </a>
                </Col>
              </Row>
            </Col>
            <Col className="new-footer__mt-3" xs={12}>
              <span className="new-footer__d-block new-footer__font-16 new-footer__mt-3">
                VAT Registration Number #: 311391775800003
              </span>
              <span className="new-footer__d-block new-footer__mt-2 new-footer__font-16">
                eCR #: 4030480178
              </span>
              <span className="new-footer__d-block new-footer__mt-2 new-footer__font-16">
                KSA Ministry of Tourism license number: 73104060
              </span>
            </Col>
          </Row>
        </Container>
      </Container>
      <div className="new-copyright ">
        <Row className="new-copyright__max-width" justify="space-between">
          <Col className="copyright">
            <img src={logoIconWhite} alt="bondai" />
            <span className="new-footer__font-16">
              Bondai © 2023 All Rights Reserved
            </span>
          </Col>
        </Row>
      </div>
    </div>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.element,
  searchRef: PropTypes.func,
  filtersRef: PropTypes.func,
  isSearchTriggered: PropTypes.bool,
  searchTrigger: PropTypes.func,
};

DefaultLayout.defaultProps = {
  children: null,
  searchRef: null,
  filtersRef: null,
  isSearchTriggered: false,
  searchTrigger: () => {},
};

export default DefaultLayout;
