import React, { useReducer, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Form,
  FormInput,
  FormGroup,
  Button,
} from 'shards-react';
import queryString from 'query-string';
import history from '../../utils/history';
import { login } from './userEffects';
import BondaiLogo from '../../assets/images/logo.png';
import PageLoader from '../../components/PageLoader';
import { loginAs, fetchDetails } from '../auth/userEffects';

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_FORM_VALUES':
      return {
        ...state,
        formValues: action.payload,
      };
    case 'PASS_VISIBLE':
      return {
        ...state,
        isPasswordVisible: action.payload,
      };
    default:
      return state;
  }
};

const SignInPage = ({ location }) => {
  const storeDispatch = useDispatch();
  const isAuthenticating = useSelector(
    (state) => state.user.loading.login === 'pending',
  );
  const isAuthenticationSuccessful = useSelector(
    (state) => state.user.loading.login === 'done',
  );
  const authenticatedRole = useSelector(
    (state) => state.user.details?.role ?? null,
  );

  const isLoggedInAs = useSelector(
    (state) => state.user.loading.loginAs === 'done',
  );

  useEffect(() => {
    if (isLoggedInAs) {
      const { editing } = queryString.parse(location.search);
      if (editing) {
        history.push(`/dashboard/edit/${editing}`);
      } else {
        history.push('/dashboard');
      }
      storeDispatch(fetchDetails());
    }
  }, [isLoggedInAs]);

  useEffect(() => {
    if (location.search) {
      const { email, token } = queryString.parse(location.search);
      storeDispatch(loginAs({email, token}));
    }
  }, []);  

  useEffect(() => {
    if (!authenticatedRole || !isAuthenticationSuccessful) return;
    if (authenticatedRole === 'superAdmin') {
      window.location.replace('https://admin.bondai.io');
    } else if (authenticatedRole === 'tourProviderAdmin') {
      history.push('/dashboard');
    } else {
      history.push('/explore');
    }
  }, [isAuthenticationSuccessful, authenticatedRole]);

  const initialState = {
    formValues: {
      email: '',
      password: '',
      name: '',
      phoneNumber: '',
    },
    isPasswordVisible: false,
    legalAknowledgement: false,
    isExistingUser: false,
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const { formValues, isPasswordVisible } = state;
  const { email, password } = formValues;
  const [errorMessage, setErrorMessage] = useState('');

  const handleFormChange = (e) => {
    dispatch({
      type: 'SET_FORM_VALUES',
      payload: { ...formValues, [e.target.name]: e.target.value },
    });
    setErrorMessage('');
  };

  return location.search ? (
    <PageLoader label='Redirecting...' />
  ) : (
    <Container style={{ flex: 1, paddingTop: 100, paddingBottom: 60 }}>
      <div className='pageTitle'>Sign in</div>
      <Row>
        <Col
          xs='12'
          md={{ size: 6, offset: 3 }}
          style={{
            color: 'black',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Form
            className='blurSection'
            style={{ width: '100%', maxWidth: 300, paddingTop: 14 }}
          >
            <Row form>
              <FormGroup className='loginFormGroup'>
                <label htmlFor='#email'>Email</label>
                <FormInput
                  id='#email'
                  placeholder='Email address'
                  name='email'
                  // valid={isEmail(email)}
                  value={email}
                  onChange={handleFormChange}
                />
              </FormGroup>
            </Row>
            <Row form>
              <FormGroup className='loginFormGroup'>
                <label htmlFor='#password'>Password</label>
                <FormInput
                  type={isPasswordVisible ? 'text' : 'password'}
                  id='#password'
                  placeholder='Password'
                  valid={password.length > 5}
                  name='password'
                  value={password}
                  onChange={handleFormChange}
                />
              </FormGroup>
            </Row>
            {errorMessage && (
              <div style={{ color: '#F7414E', marginLeft: 5 }}>
                {errorMessage}
              </div>
            )}
            <Row form style={{ justifyContent: 'flex-end', marginTop: 10 }}>
              <div
                className='authLink'
                onClick={() => {
                  history.push('/register');
                }}
              >
                New at Bondai?
              </div>
            </Row>
          </Form>
          <Button
            theme='dark'
            style={{
              fontWeight: 500,
              borderRadius: 10,
              width: '100%',
              marginTop: 30,
              height: 50,
              maxWidth: 300,
              marginBottom: 0,
            }}
            disabled={!email || !password || isAuthenticating}
            onClick={() => {
              storeDispatch(login({ email, password }));
            }}
          >
            {isAuthenticating ? 'SIGNING IN...' : 'SIGN IN TO BONDAI'}
          </Button>
          <Row
            style={{ width: 300, justifyContent: 'center', marginTop: 30 }}
          >
            <div
              className='authLink'
              onClick={() => {
                history.push('/forgot-password');
              }}
            >
              Forgot your password?
            </div>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default SignInPage;
