import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchExperiencesByDate } from './upcomingEffects';
import { getExperiencesByDate } from './upcomingSlice';
import ExperienceCard from './UpcomingCard';
import { Button, Row, Container } from 'shards-react';
import history from '../../utils/history';
import { isToday, isTomorrow, isThisYear, format, isValid } from 'date-fns';
import { FiCalendar } from 'react-icons/fi';

const titleDateFormatter = (date) => {
  if (isToday(date)) return 'today';
  if (isTomorrow(date)) return 'tomorrow';
  if (isThisYear(date)) return `on ${format(date, 'MMMM do')}`;
  return `on ${format(date, 'MMMM do, yyyy')}`;
};

const UpcomingByDatePage = (props) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const dispatch = useDispatch();
  const experiences = useSelector(getExperiencesByDate(props.match.params.date))  
  useEffect(() => {
      const date = new Date(props.match.params.date);
      if (!isValid(date)) {
        return history.push('/calendar');
      }
      setSelectedDate(date);
      dispatch(fetchExperiencesByDate(date));
  }, []);

  return !selectedDate ? null : (
    <div id="upcoming-page">
      <Container className="upcoming-container">
        <Row className="title">
          <Button
              theme='dark'
              style={{
                fontWeight: 500,
                borderRadius: 10,
                height: 50,
                margin: '0px 30px 15px 0px',
              }}
              onClick={() => { history.push('/calendar')}}
            >
              <FiCalendar style={{ width: 20, height: 20, marginRight: 5 }}/> View calendar
            </Button>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 15 }}>
              {`${experiences.length || 'No'} upcoming experience${experiences.length === 1 ? '' : 's'} ${titleDateFormatter(selectedDate)}`}
            </div>
        </Row>
        <div className="grid-layout">
        {experiences.map((experience) => (
          <div className="grid-item grid-item-3">
            <ExperienceCard
              location={experience.destination}
              prices={experience.prices}
              availability={experience.spacesLeft}
              shortId={experience.shortId}
              slug={experience.slug}
              title={experience.title}
              avatar={experience.avatarUrl}
              date={props.match.params.date}
            />
          </div>
        ))}
      </div>
      </Container>
    </div>
  );
};

export default UpcomingByDatePage;
