import React from 'react';
import EditorRenderer from './EditorRenderer';

const EditorMultilangRenderer = ({ content }) => {
  try {
    const parsedContent = JSON.parse(content);
    return Object.keys(parsedContent).map((lang) => (
      <EditorRenderer content={parsedContent[lang]} />
    ));
  } catch (err) {
    return (
      <div
        className='activityDescription'
        dangerouslySetInnerHTML={{
          __html: content ? content.replace(/\n/g, '<br />') : '',
        }}
      />
    );
  }
};

export default EditorMultilangRenderer;
