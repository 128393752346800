import React from 'react';
import { Controller } from 'react-hook-form';
import TextEditor from './TextEditor';

const ControlledTextEditor = ({ control, name, title, placeholder }) => (
  <Controller
    name={name}
    control={control}
    as={({ value, onChange }) => (
      <TextEditor
        placeholder={placeholder}
        title={title}
        content={value}
        onChange={(lang, content) => {
          onChange({
            ...value,
            [lang]: content,
          });
        }}
      />
    )}
  />
);

export default ControlledTextEditor;
