import React from 'react';
import { FormSelect, FormGroup } from 'shards-react';

const Select = (props) => {
  return (
    <FormGroup>
      <label>{props.label}</label>
      <FormSelect {...props} invalid={props.errors && props.errors.message}>
        {props.options &&
          props.options.map((option) => (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
      </FormSelect>
      {props.errors ? (
        <span style={{ position: 'absolute', bottom: -20, right: 0 }}>
          {props.errors.message}
        </span>
      ) : null}
    </FormGroup>
  );
};

export default Select;
