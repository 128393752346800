import React from 'react';
import {
  Container,
  Row,
  Col,
} from 'shards-react';

import DiscoverPhoto from "../../assets/images/about-discover.png";
import GoFarPhoto from "../../assets/images/about-go-far-together.jpg";
import ValuesPhoto from "../../assets/images/about-values.jpg";

const AboutPage = () => (
  <Container className="about">
    <Row>
      <Col sm={12} md={6}>
        <h1>Discover the unknown</h1>
        <p>So much of who we are is where we have been. Bondai was created to awake your travel senses and open a world of possibilities, all in one place. Get ready to enjoy more roadtrip fun with friends - without the typical group planning headaches. It’s time to live the adventures you imagine and share amazing stories with the people you love!</p>
      </Col>
      <Col sm={12} md={6}>
        <div className="photo" style={{ backgroundImage: `url(${DiscoverPhoto})` }}/>
      </Col>
    </Row>
    <Row>
      <Col sm={12} md={6}>
        <div className="photo" style={{ backgroundImage: `url(${GoFarPhoto})` }}/>
      </Col>
      <Col sm={12} md={6}>
        <h1>Go far together</h1>
        <p>They say that friends who travel together stay together. Bondai is designed for travel freedom with friends. Forget about group planning hassles! Explore the first mobile app which helps you and your friends quickly decide on the next holiday adventures, manage the entire trips, get the best deals and stay connected at all times.</p>
      </Col>
    </Row>
    <Row>
      <Col>
        <h1>Bondai values</h1>
      </Col>
    </Row>
    <Row style={{ }}>
      <Col sm={12} md={6}>
        <p>
          <span style={{ display: 'block', color: '#f7414e', fontSize: 24 }}>Collaboration</span>
          <span style={{ display: 'block', color: '#becad6', fontSize: 18 }}>For better relationships</span>
          Our ambition is to empower the wanderlust community by helping people communicate better and reach their group travel goals faster - both app users and hospitality partners.
        </p>
      </Col>
      <Col sm={12} md={6}>
        <p>
          <span style={{ display: 'block', color: '#f7414e', fontSize: 24 }}>Imagination</span>
          <span style={{ display: 'block', color: '#becad6', fontSize: 18 }}>For sharing ideas</span>
          We were born to inspire through diversity and travel stories when it comes to exploring the endless possibilities out there, from amazing destinations to unconventional activities.
        </p>
      </Col>
      <Col sm={12} md={6}>
        <p>
          <span style={{ display: 'block', color: '#f7414e', fontSize: 24 }}>Enthusiasm</span>
          <span style={{ display: 'block', color: '#becad6', fontSize: 18 }}>For happy times</span>
          We’re fueled by gratitude towards discovering the wonders of the world through your eyes, discovering them for the first time. We’re here to ignite travel joy.
        </p>
      </Col>
      <Col sm={12} md={6}>
        <p>
          <span style={{ display: 'block', color: '#f7414e', fontSize: 24 }}>Trust</span>
          <span style={{ display: 'block', color: '#becad6', fontSize: 18 }}>For safe travel</span>
          We believe in simplicity and transparency, this is why we designed a smart and safe digital ecosystem that helps users enjoy the best travel prices and keep track of their friends.
        </p>
      </Col>
    </Row>
    <Row>
      <Col sm={12}>
        <img src={ValuesPhoto} alt="Values" />
      </Col>
    </Row>
  </Container>
);

export default AboutPage;

