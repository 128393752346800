import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import history from "../../utils/history";
import { Container, Row, Col, Button } from "shards-react";
import { FiSearch } from "react-icons/fi";
import {
  fetchPopularExperiences,
  fetchUpcomingExperiences,
} from "./exploreEffects";
import ActivityCard from "./ActivityCard";
import { fetchActivityTags } from "../auth/userEffects";

import InquiryForm from "../../components/explore/InquiryForm";
import SeaPhoto from "../../assets/images/sea.jpeg";
import MountainsPhoto from "../../assets/images/mountains.jpeg";
import ColorsPhoto from "../../assets/images/colors.jpeg";
import SpiritualPhoto from "../../assets/images/spiritual.jpeg";
import TabukPhoto from "../../assets/images/tabuk.jpeg";
import RiyadhPhoto from "../../assets/images/riyadh.jpg";
import AlUlaPhoto from "../../assets/images/al-ula.jpeg";
import JeddahPhoto from "../../assets/images/jeddah.jpeg";
import BuildImage from "../../assets/images/banner/build.png";
import ExperienceLogo from "../../assets/images/banner/ex-logo.png";
import PersonImage from "../../assets/images/banner/person.png";
import TextOneImage from "../../assets/images/banner/text-1.png";
import TextTwomage from "../../assets/images/banner/text-2.png";
import TextThreeDesktopImage from "../../assets/images/banner/text-3-desktop.png";
import TextThreeMobileImage from "../../assets/images/banner/text-3-mobile.png";
import ButtonImage from "../../assets/images/banner/btn-text.png";
import moment from "moment";
import BlogSection from "../../components/BlogSection";
import "../../components/new-banner.scss";

const ExplorePage = () => {
  const dispatch = useDispatch();

  const popularExperiences = useSelector((state) => state.explore.popular);
  const upcomingExperiences = useSelector((state) => state.explore.upcoming);

  useEffect(() => {
    dispatch(fetchPopularExperiences());
    dispatch(fetchUpcomingExperiences());
    dispatch(fetchActivityTags());
  }, []);

  const searchTriggerRef = useRef();
  const [isNavbarSearchTriggered, setIsNavbarSearchTriggered] = useState(false);

  return (
    <>
      <div className="heroSection">
        <Container style={{ padding: 0 }}>
          <h1>
            Explore the
            <span>Kingdom.</span>
          </h1>
          <h2>Less hassle, more travel.</h2>
          <div
            role="link"
            className="searchTrigger"
            ref={searchTriggerRef}
            onClick={() => {
              history.push("/explore");
              setIsNavbarSearchTriggered(true);
            }}
            tabIndex="0"
            onKeyPress={() => {
              setIsNavbarSearchTriggered(true);
            }}
          >
            <div className="searchTrigger__placeholder">
              Search for experiences...
            </div>
            <FiSearch className="searchTrigger__icon" />
          </div>
          <div className="scrollIndicator">
            <div className="scrollIndicator__icon">
              <div className="scrollIndicator__iconLine" />
              <div className="scrollIndicator__iconTriangle" />
              <div className="scrollIndicator__iconCircle" />
            </div>
            <div className="scrollIndicator__label">or explore below</div>
          </div>
        </Container>
      </div>
      <Container className="popularSection">
        <div className="title">Upcoming group trips</div>
        <div className="grid-layout">
          {upcomingExperiences.map((activity, index) => (
            <div className={`grid-item grid-item-3`} key={activity.shortId}>
              <ActivityCard
                location={activity.destination}
                price={
                  activity.defaultPrices[0]?.priceAmount ||
                  activity.startingFromPrices[0]?.priceAmount
                    ? `${
                        activity.defaultPrices[0]?.priceAmount ||
                        activity.startingFromPrices[0].priceAmount
                      } SAR`
                    : null
                }
                shortId={activity.shortId}
                slug={activity.slug}
                id={activity.id}
                title={activity.activityTitle}
                avatar={activity.avatarUrl}
                duration={activity.defaultDurationInDays}
                date={moment(activity.upcomingScheduleStartDateTime).format(
                  "MMM Do"
                )}
                ratingValue={parseFloat(activity.globalRating.toFixed(2))}
                ratingCount={
                  activity.ratingCount < 2 && activity.globalRating < 3
                    ? 0
                    : activity.ratingCount
                }
              />
            </div>
          ))}
        </div>
        <Row
          style={{ marginTop: 45, marginBottom: 30 }}
          className="bondai-custom"
        >
          <Button
            theme="primary"
            style={{
              fontWeight: 500,
              borderRadius: 10,
              width: "100%",
              height: 50,
              maxWidth: 300,
              margin: "0 auto",
            }}
            onClick={() => {
              history.push("/calendar");
            }}
          >
            Check all upcoming experiences
          </Button>
        </Row>
        <div className="title">Popular on Bondai</div>
        <div className="grid-layout">
          {popularExperiences.map((activity, index) => (
            <div className={`grid-item grid-item-3`} key={activity.shortId}>
              <ActivityCard
                location={activity.destination}
                price={
                  activity.defaultPrices[0]?.priceAmount ||
                  activity.startingFromPrices[0]?.priceAmount
                    ? `${
                        activity.defaultPrices[0]?.priceAmount ||
                        activity.startingFromPrices[0].priceAmount
                      } SAR`
                    : null
                }
                shortId={activity.shortId}
                slug={activity.slug}
                id={activity.id}
                title={activity.activityTitle}
                avatar={activity.avatarUrl}
                duration={activity.defaultDurationInDays}
                ratingValue={parseFloat(activity.globalRating.toFixed(2))}
                ratingCount={
                  activity.ratingCount < 2 && activity.globalRating < 3
                    ? 0
                    : activity.ratingCount
                }
              />
            </div>
          ))}
        </div>
        <Row style={{ marginTop: 45, marginBottom: 30 }}>
          <Button
            theme="dark"
            style={{
              fontWeight: 500,
              borderRadius: 10,
              width: "100%",
              marginTop: 30,
              height: 50,
              maxWidth: 300,
              margin: "0 auto",
            }}
            onClick={() => {
              history.push("/explore");
            }}
          >
            Explore all experiences
          </Button>
        </Row>
      </Container>
      <Container fluid className="jeddahSection">
        <Container>
          <Row>
            <Col>
              <div className="title">
                Jeddah Boats & <span>Yachts</span>
              </div>
              <div
                className="cta"
                onClick={() => {
                  history.push("/jeddah-boats-and-yachts");
                }}
              >
                Explore Now
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container
        fluid
        className="categoriesSection featured"
        style={{ background: "white" }}
      >
        <Container>
          <Row>
            <Col>
              <div className="sectionTitle">Summer in Saudi</div>
            </Col>
          </Row>
          <Row>
            <div
              onClick={() => {
                history.push(`/explore-al-ula`);
              }}
              className="card activityCard"
            >
              <div className="photoWrapper">
                <div
                  className="photo"
                  style={{ backgroundImage: `url(${AlUlaPhoto})` }}
                >
                  <div className="photoSpacer location"></div>
                </div>
              </div>
              <div className="title">Al Ula</div>
            </div>
            <div
              onClick={() => {
                history.push(`/explore?location=riyadh`);
              }}
              className="card activityCard"
            >
              <div className="photoWrapper">
                <div
                  className="photo"
                  style={{ backgroundImage: `url(${RiyadhPhoto})` }}
                >
                  <div className="photoSpacer location"></div>
                </div>
              </div>
              <div className="title">Riyadh</div>
            </div>
            <div
              onClick={() => {
                history.push(`/explore?location=tabuk`);
              }}
              className="card activityCard"
            >
              <div className="photoWrapper">
                <div
                  className="photo"
                  style={{ backgroundImage: `url(${TabukPhoto})` }}
                >
                  <div className="photoSpacer location"></div>
                </div>
              </div>
              <div className="title">Tabuk</div>
            </div>
            <div
              onClick={() => {
                history.push(`/explore?location=jeddah`);
              }}
              className="card activityCard"
            >
              <div className="photoWrapper">
                <div
                  className="photo"
                  style={{ backgroundImage: `url(${JeddahPhoto})` }}
                >
                  <div className="photoSpacer location"></div>
                </div>
              </div>
              <div className="title">Jeddah</div>
            </div>
          </Row>
        </Container>
      </Container>
      {/* <div style={{ width: "100%" }} className="experience-banner">
        <img
          className="experience-banner__logo"
          onClick={() => history.push("/")}
          src={ExperienceLogo}
          alt=""
        />
        <img
          className="experience-banner__build"
          onClick={() => history.push("/")}
          src={BuildImage}
          alt=""
        />

        <div className="experience-banner__content">
          <img src={TextOneImage} alt="" />
          <img src={TextTwomage} alt="" />
          <img src={TextThreeDesktopImage} alt="" />
          <img src={TextThreeMobileImage} alt="" />
          <img
            onClick={() => {
              history.push("/founding-day");
            }}
            src={ButtonImage}
            alt=""
          />
        </div>
        <img className="experience-banner__person" src={PersonImage} alt="" />
      </div> */}
      <Container fluid className="categoriesSection">
        <Container>
          <Row>
            <Col>
              <div className="sectionTitle">Endless ways to explore...</div>
            </Col>
          </Row>
          <Row>
            <div
              onClick={() => {
                history.push(`/explore?category=by-the-sea`);
              }}
              className="card activityCard"
            >
              <div className="photoWrapper">
                <div
                  className="photo"
                  style={{ backgroundImage: `url(${SeaPhoto})` }}
                >
                  <div className="photoSpacer"></div>
                </div>
              </div>
              <div className="title">By the sea</div>
            </div>
            <div
              onClick={() => {
                history.push(`/explore?category=through-the-mountains`);
              }}
              className="card activityCard"
            >
              <div className="photoWrapper">
                <div
                  className="photo"
                  style={{ backgroundImage: `url(${MountainsPhoto})` }}
                >
                  <div className="photoSpacer"></div>
                </div>
              </div>
              <div className="title">Through the mountains</div>
            </div>
            <div
              onClick={() => {
                history.push(`/explore?category=a-spiritual-journey`);
              }}
              className="card activityCard"
            >
              <div className="photoWrapper">
                <div
                  className="photo"
                  style={{ backgroundImage: `url(${SpiritualPhoto})` }}
                >
                  <div className="photoSpacer"></div>
                </div>
              </div>
              <div className="title">A spiritual journey</div>
            </div>
            <div
              onClick={() => {
                history.push(`/explore?category=discover-the-colors`);
              }}
              className="card activityCard"
            >
              <div className="photoWrapper">
                <div
                  className="photo"
                  style={{ backgroundImage: `url(${ColorsPhoto})` }}
                >
                  <div className="photoSpacer"></div>
                </div>
              </div>
              <div className="title">Discover the colors</div>
            </div>
          </Row>
        </Container>
      </Container>
      <Container
        fluid
        className="categoriesSection"
        style={{ backgroundColor: "white" }}
      >
        <Container>
          <BlogSection />
        </Container>
      </Container>
      <Container fluid>
        <Container>
          <InquiryForm isLandingPage />
        </Container>
      </Container>
    </>
  );
};

const styles = {
  socialIcons: { width: 28, height: 28, margin: 10 },
};

export default ExplorePage;
