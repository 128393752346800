import React, { useState } from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  FormInput,
  FormGroup,
  Button,
} from 'shards-react';
import { useDispatch, useSelector } from 'react-redux';
import { requestPasswordReset } from './userEffects';
import { isEmail } from '../../helpers/validations';

const ForgotPasswordPage = () => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const isSubmitting = useSelector(
    (state) => state.user.loading.requestPasswordReset === 'pending',
  );

  return (
    <Container style={{ flex: 1, paddingTop: 100, paddingBottom: 60, justifyContent: 'center' }}>
      <div className='pageTitle' style={{ marginBottom: 20 }}>
        Forgot password
      </div>
      <Row>
        <Col
          xs='12'
          md={{ size: 12 }}
          style={{
            color: 'black',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p>Please enter below the email you've used to signup on Bondai.</p>
          <Form
            className='blurSection'
            style={{ width: '100%', maxWidth: 320, paddingTop: 14 }}
          >
            <Row form>
              <FormGroup className='loginFormGroup'>
                <label htmlFor='#email'>Email</label>
                <FormInput
                  id='#email'
                  placeholder='Email address'
                  name='email'
                  valid={isEmail(email)}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </FormGroup>
              <Button
                theme='dark'
                style={{
                  fontWeight: 500,
                  borderRadius: 10,
                  width: '100%',
                  marginTop: 30,
                  height: 50,
                  maxWidth: 300,
                }}
                disabled={!email || !isEmail(email) || isSubmitting}
                onClick={() => {
                  dispatch(requestPasswordReset(email));
                }}
              >
                {isSubmitting ? 'SENDING RESET EMAIL...' : 'RESET MY PASSWORD'}
              </Button>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default ForgotPasswordPage;
